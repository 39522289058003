import { i18n } from '@/i18n'
import { DateTime } from 'luxon'

import { isEmpty, mapRecursive } from './utils'

/**
 * Converts a period of time's full word into shorthand.
 *
 * @example
 * ```js
 * toPeriod('yearly') // => 'yr' if short is true
 * toPeriod('yearly', false) // => 'year' if short is false
 * ```
 *
 * @param {string} period
 * @param {string} format
 * @returns {string}
 */
export const toPeriod = (
  period: string,
  format: 'short' | 'full' = 'full'
): string => {
  const periodMap: Record<string, { short: string; full: string }> = {
    yearly: { short: 'yr', full: 'year' },
    monthly: { short: 'mo', full: 'month' },
    weekly: { short: 'wk', full: 'week' },
  }

  const normalizedPeriod = period.toLowerCase()

  return periodMap[normalizedPeriod]?.[format] ?? period
}

type DateTimeCheckType<K, T> = K extends `${string}_at` ? DateTime : T

type MappedDateTime<T> = {
  [K in keyof T]: DateTimeCheckType<K, MappedDateTime<T[K]>>
}

/**
 * Recursively maps an object's properties that end with '_at' and are non-empty strings to DateTime objects.
 *
 * @template T - The type of the values in the input object.
 * @param {Record<string, T>} obj - The input object to be mapped.
 * @returns {T} - The transformed object with DateTime properties.
 *
 * @example
 * const input = { created_at: '2023-01-01T00:00:00Z', name: 'example' };
 * const result = mapToDateTime(input);
 * // result: { created_at: DateTime, name: 'example' }
 */
export const mapToDateTime = <T extends Record<string, any>>(
  obj: T,
  dateTimeKey: string = '_at'
): MappedDateTime<T> => {
  return mapRecursive(obj, (value: any, key?: string | number) => {
    if (
      typeof value === 'string' &&
      `${key}`.endsWith(dateTimeKey) &&
      !isEmpty(value)
    ) {
      let datetime = null

      try {
        datetime = DateTime.fromSQL(value)

        if (!datetime.isValid) {
          datetime = DateTime.fromISO(value)
        }

        return datetime.isValid ? datetime : value
      } catch (error) {
        console.error(error)
      }
    }

    return value
  }) as MappedDateTime<T>
}

/**
 * Shows relative text ("Today"/"Tomorrow") when appropriate
 * and formats the datetime correctly.
 *
 * @param {DateTime} dateTime
 * @returns {string}
 */
export const formatDateTime = (dateTime: DateTime): string => {
  return `${isToday(dateTime) ? 'Today' : isTomorrow(dateTime) ? 'Tomorrow' : ''} ${i18n.global.d(
    dateTime.toJSDate(),
    isToday(dateTime) || isTomorrow(dateTime) ? 'time' : 'short'
  )}`
}

/**
 * Returns true if a datetime is in the future.
 * Compared to the millisecond.
 *
 * @param {DateTime} dateTime
 * @returns {boolean}
 */
export const isInFuture = (dateTime: DateTime): boolean => {
  return dateTime.diffNow().milliseconds > 0
}

/**
 * Returns true is the datetime is tomorrow.
 * Ie, within the next 24 hours.
 *
 * @param {DateTime} dateTime
 * @returns {boolean}
 */
export const isTomorrow = (dateTime: DateTime): boolean => {
  return dateTime.hasSame(DateTime.now().plus({ day: 1 }), 'day')
}

/**
 * Returns true if the datetime is today.
 *
 * @param {DateTime} dateTime
 * @returns {boolean}
 */
export const isToday = (dateTime: DateTime): boolean => {
  return dateTime.hasSame(DateTime.now(), 'day')
}

/**
 * Returns true if the datetime is in the past.
 *
 * @param {DateTime} dateTime
 * @returns {boolean}
 */
export const isInPast = (dateTime: DateTime): boolean => {
  return dateTime.diffNow().milliseconds < 0
}

/**
 * Returns true if the datetime is within the next 48 hours.
 *
 * @param {DateTime} dateTime
 * @returns {boolean}
 */
export const isWithin48Hours = (dateTime: DateTime): boolean => {
  const diff = dateTime.diffNow('hours')
  return diff.hours >= 0 && diff.hours <= 48
}

/**
 * If greater than 6am but before 6pm => 'afternoon'
 * If greater than 6pm but before 6am => 'evening'
 * Else => 'morning'
 */
export const timeGreeting = (): string => {
  const currentHour = Number(DateTime.now().toFormat('HH'))

  if (currentHour >= 6) {
    return currentHour <= 18 ? 'Good afternoon' : 'Good morning'
  }

  return 'Good evening'
}
