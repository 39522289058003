// External imports first
// Internal imports second
import Fetcher from '@/services/fetcher'
import { toPortalUrl } from '@/utils/url-generation-utils'
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'

// Define types with consistent style and proper TypeScript practices
interface FeatureFlag {
  value: boolean | string | number | Record<string, unknown>
  description?: string
}

type FlagValue =
  | FeatureFlag
  | boolean
  | string
  | number
  | Record<string, unknown>

interface FeatureFlagsResponse {
  flags: Record<string, FlagValue>
  lastUpdated: string
}

interface CachedFlags {
  data: FeatureFlagsResponse
  timestamp: number
}

// Constants should be in UPPER_SNAKE_CASE
const CACHE_TTL_MS = 60 * 60 * 1000 // 1 hour in milliseconds
const STORAGE_KEY = 'featureFlags'
const DEVICE_ID_KEY = 'device_id'

/**
 * Constructs the API endpoint for feature flags
 * @returns The fully qualified endpoint URL
 */
function getEndpointUrl(): string {
  const baseUrl = import.meta.env.GLP_PORTAL_URL || ''

  if (!baseUrl) {
    return '/api/feature-flags'
  }

  return toPortalUrl('feature-flags')
}

// Constants should be defined before their usage
const FEATURE_FLAGS_ENDPOINT = getEndpointUrl()

export const useFeatureFlagsStore = defineStore('featureFlags', {
  state: () => ({
    featureFlags: null as FeatureFlagsResponse | null,
    isLoading: false,
    error: null as Error | null,
    lastFetched: null as number | null,
  }),

  getters: {
    isInitialized: (state) => state.featureFlags !== null,
  },

  actions: {
    /**
     * Retrieves the device ID from localStorage
     * @returns The device ID or null if not found
     */
    getDeviceId(): string | null {
      return localStorage.getItem(DEVICE_ID_KEY)
    },

    /**
     * Retrieves a feature flag value of the specified type
     * @param flagName The name of the flag to retrieve
     * @param defaultValue The default value to return if flag doesn't exist
     * @returns The flag value or default value
     */
    getFlag<T>(flagName: string, defaultValue: T): T {
      if (!this.featureFlags?.flags) {
        return defaultValue
      }

      const flag = this.featureFlags.flags[flagName]

      // If flag doesn't exist, return default value
      if (flag === undefined) {
        return defaultValue
      }

      // Handle structured flag format or direct values
      if (typeof flag === 'object' && 'value' in flag) {
        return flag.value as unknown as T
      }

      // Otherwise return the flag value directly
      return flag as unknown as T
    },

    /**
     * Checks if a feature is enabled
     * @param featureName The name of the feature to check
     * @returns True if the feature is enabled, false otherwise
     */
    isFeatureEnabled(featureName: string): boolean {
      return this.getFlag<boolean>(featureName, false)
    },

    /**
     * Checks if a cache timestamp is still valid
     * @param timestamp The timestamp to check in milliseconds since epoch
     * @returns True if the cache is valid, false otherwise
     */
    isCacheValid(timestamp: number): boolean {
      const now = DateTime.now()
      const timestampDateTime = DateTime.fromMillis(timestamp)
      return now.diff(timestampDateTime).milliseconds < CACHE_TTL_MS
    },

    /**
     * Saves the current feature flags to localStorage
     */
    saveToCache(): void {
      if (!this.featureFlags || !this.lastFetched) {
        return
      }

      try {
        localStorage.setItem(
          STORAGE_KEY,
          JSON.stringify({
            data: this.featureFlags,
            timestamp: this.lastFetched,
          } as CachedFlags)
        )
      } catch (err) {
        console.error('Failed to save feature flags to cache:', err)
      }
    },

    /**
     * Fetches feature flags from the API or returns cached version if valid
     * @param force Whether to force a refresh from the API
     * @returns The feature flags response or null if failed
     */
    async fetchFeatureFlags(
      force = false
    ): Promise<FeatureFlagsResponse | null> {
      const shouldUseCache =
        !force &&
        this.featureFlags &&
        this.lastFetched &&
        this.isCacheValid(this.lastFetched)

      if (shouldUseCache) {
        return this.featureFlags
      }

      this.error = null

      try {
        // Get the device ID for guest user identification
        const deviceId = this.getDeviceId()

        // Construct the API endpoint with device ID as a query parameter if available
        let endpoint = FEATURE_FLAGS_ENDPOINT
        if (deviceId) {
          const separator = endpoint.includes('?') ? '&' : '?'
          endpoint = `${endpoint}${separator}deviceId=${encodeURIComponent(deviceId)}`

          if (import.meta.env.DEV) {
            console.log(
              'Including device ID in feature flag request:',
              deviceId
            )
            console.log('Final endpoint:', endpoint)
          }
        }

        if (import.meta.env.DEV) {
          console.log('Fetching feature flags from:', endpoint)
        }

        const response = await Fetcher.get<FeatureFlagsResponse>(endpoint, true)

        if (response.error.value) {
          throw response.error.value
        }

        this.featureFlags = response.data.value
        this.lastFetched = Date.now()
        this.saveToCache()

        return this.featureFlags
      } catch (err) {
        this.error = err instanceof Error ? err : new Error(String(err))
        console.error('Failed to fetch feature flags:', err)
        return null
      } finally {
        this.isLoading = false
      }
    },

    /**
     * Initializes feature flags from localStorage if available and valid
     * @returns True if successfully initialized from cache, false otherwise
     */
    initializeFromCache(): boolean {
      try {
        const cachedData = localStorage.getItem(STORAGE_KEY)
        if (!cachedData) {
          return false
        }

        const { data, timestamp } = JSON.parse(cachedData) as CachedFlags

        if (this.isCacheValid(timestamp)) {
          this.featureFlags = data
          this.lastFetched = timestamp
          return true
        }

        return false
      } catch (err) {
        console.error('Failed to load feature flags from cache:', err)
        return false
      }
    },

    /**
     * Clears the feature flags cache but preserves the device ID.
     * This should be called on login and logout to ensure users get
     * the appropriate set of feature flags for their authentication state.
     */
    clearFeatureFlagsCache(): void {
      // Clear the in-memory state
      this.featureFlags = null
      this.lastFetched = null

      // Remove from localStorage
      localStorage.removeItem(STORAGE_KEY)

      // Note: We intentionally preserve the device ID (DEVICE_ID_KEY)
      // to maintain continuity for the same device/browser

      if (import.meta.env.DEV) {
        console.log('Feature flags cache cleared')
      }
    },
  },
})
