import { toFullUrl } from '@/utils/url-generation-utils'
import { type EventAttributes, createEvent } from 'ics'
import type { DateTime } from 'luxon'

export type CalendarEvent = {
  filename: string
  title: string
  description: string
  prizeCode: string
  drawDatetime: DateTime
}

export class Calendar {
  static async createEvent({
    filename,
    title,
    description,
    prizeCode,
    drawDatetime,
  }: CalendarEvent) {
    const event: EventAttributes = {
      start: [
        drawDatetime.year,
        drawDatetime.month,
        drawDatetime.day,
        drawDatetime.hour,
        drawDatetime.minute,
      ],
      duration: { minutes: 30 },
      title,
      description,
      url: toFullUrl(`live-draws/${prizeCode}`),
      organizer: {
        name: import.meta.env.GLP_BUSINESS_NAME,
        email: import.meta.env.GLP_BUSINESS_EMAIL,
      },
    }

    const file: File = await new Promise((resolve, reject) => {
      createEvent(event, (error, value) => {
        if (error) {
          reject(error)
        }

        resolve(new File([value], `${filename}.ics`, { type: 'text/calendar' }))
      })
    })
    const url = URL.createObjectURL(file)

    // trying to assign the file URL to a window could cause cross-site
    // issues so this is a workaround using HTML5
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = filename

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)

    URL.revokeObjectURL(url)
  }
}
