<script lang="ts">
import { ValidationException } from '@/validation-exception'
import { type PropType, defineComponent } from 'vue'

/**
 * A general component with common input functionality to extend more specific input types from.
 *
 * @see https://www.w3.org/WAI/tutorials/forms/
 * @see https://www.w3.org/TR/UNDERSTANDING-WCAG20/minimize-error-identified.html
 * @see https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/G89.html
 * @see https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/SCR32
 * @see https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/ARIA19.html
 * @see https://www.w3.org/TR/WCAG20-TECHS/ARIA18 On severe errors displays with a alertdialog
 * @see https://www.w3.org/TR/WCAG20-TECHS/ARIA21
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/alert/examples/alert/
 */
export default defineComponent({
  inheritAttrs: false,
  props: {
    /**
     * Text describing the reason for the input.
     */
    label: {
      type: String,
    },
    /**
     * The name of the input sent to the API endpoint.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Text displayed below the input element.
     */
    hint: {
      type: String,
    },
    /**
     * @model
     */
    modelValue: {
      type: [Number, String, Boolean],
      required: true,
    },
    /**
     * Displays input validation errors returned.
     */
    errors: {
      type: Object as PropType<null | ValidationException>,
    },
    /**
     * Adds classes to the container of an input field.
     */
    containerClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value: Number | String) {
        this.$emit('update:modelValue', value)
      },
    },
    required(): boolean {
      return (this.$attrs.required ?? false) as boolean
    },
    disabled(): boolean {
      return (this.$attrs.disabled ?? false) as boolean
    },
    hasErrors(): boolean {
      return this.inputErrors.length > 0
    },
    inputErrors() {
      return this.errors instanceof ValidationException
        ? this.errors.get(this.name)
        : []
    },
  },
})
</script>

<template>
  <div
    :class="[disabled ? 'opacity-50' : '', containerClass]"
    class="relative mb-3 flex flex-grow flex-col gap-2"
  >
    <label :for="name" class="flex items-center hover:cursor-pointer">
      <input
        :id="name"
        v-bind="$attrs"
        v-model="value"
        type="checkbox"
        :aria-errormessage="name + '-errors'"
        :aria-describedBy="name + '-hint'"
        :aria-invalid="hasErrors"
        :autocomplete="name"
        class="mr-4 h-[24px] w-[24px] rounded-sm border"
        :class="[
          hasErrors
            ? 'border-red-500'
            : 'border-primary checked:bg-primary hover:checked:bg-primary focus:border-transparent',
          disabled ? 'hover:cursor-not-allowed' : '',
        ]"
        :name
      />
      <!-- <span v-if="required" class="ml-2 text-xs">*</span> -->
      <span v-html="label" />
    </label>

    <div
      v-if="hasErrors"
      :id="name + '-errors'"
      :aria-live="hasErrors ? 'assertive' : 'off'"
      aria-atomic="true"
      class="errors"
    >
      <div
        v-for="error in inputErrors"
        :key="error"
        class="text-xs font-bold text-red-500"
      >
        {{ error }}
      </div>
    </div>

    <div v-if="hint && !hasErrors" :id="name + '-hint'" class="text-xs">
      {{ hint }}
    </div>
  </div>
</template>
