<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M13.3314 11.1041H4.53703C4.23036 11.1041 3.96967 10.9968 3.75494 10.782C3.54022 10.5674 3.43286 10.3068 3.43286 9.99995C3.43286 9.69314 3.54022 9.43245 3.75494 9.21786C3.96967 9.00314 4.23036 8.89578 4.53703 8.89578H13.3314L9.70744 5.27161C9.48578 5.05009 9.37647 4.78891 9.37953 4.48807C9.38244 4.18738 9.49474 3.92627 9.7164 3.70474C9.93807 3.49696 10.1993 3.39155 10.4999 3.38849C10.8006 3.38543 11.0618 3.49474 11.2835 3.71641L16.7835 9.21641C16.8903 9.32335 16.9709 9.44502 17.0254 9.58141C17.0797 9.71793 17.1068 9.85745 17.1068 9.99995C17.1068 10.1424 17.0797 10.282 17.0254 10.4185C16.9709 10.5549 16.8903 10.6765 16.7835 10.7835L11.2716 16.2952C11.0561 16.5108 10.7983 16.6187 10.4985 16.6187C10.1988 16.6187 9.93807 16.5108 9.7164 16.2952C9.49474 16.0736 9.3839 15.811 9.3839 15.5072C9.3839 15.2035 9.49474 14.9408 9.7164 14.7191L13.3314 11.1041Z"
      fill="currentColor"
    />
  </svg>
</template>
