<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M19.1001 23C18.6668 23 18.2459 22.9667 17.8376 22.9C17.4293 22.8333 17.0334 22.7333 16.6501 22.6L2.6001 17.475L3.1001 16.05L10.0001 18.575L11.7251 14.125L8.1501 10.4C7.7001 9.93333 7.52093 9.37917 7.6126 8.7375C7.70426 8.09583 8.03343 7.60833 8.6001 7.275L12.0751 5.275C12.3584 5.10833 12.6459 5.0125 12.9376 4.9875C13.2293 4.9625 13.5168 5.00833 13.8001 5.125C14.0834 5.225 14.3293 5.38333 14.5376 5.6C14.7459 5.81667 14.9001 6.075 15.0001 6.375L15.3251 7.45C15.5418 8.16667 15.8959 8.8 16.3876 9.35C16.8793 9.9 17.4668 10.3167 18.1501 10.6L18.6751 9L20.1001 9.45L18.9751 12.9C17.7418 12.7 16.6501 12.2167 15.7001 11.45C14.7501 10.6833 14.0501 9.73333 13.6001 8.6L11.0751 10.05L14.1001 13.5L11.8751 19.25L14.9751 20.375L17.0751 13.95C17.3084 14.0333 17.5418 14.1083 17.7751 14.175C18.0084 14.2417 18.2501 14.3 18.5001 14.35L16.3751 20.9L17.1501 21.175C17.4501 21.275 17.7626 21.3542 18.0876 21.4125C18.4126 21.4708 18.7501 21.5 19.1001 21.5C19.5334 21.5 19.9459 21.4583 20.3376 21.375C20.7293 21.2917 21.1084 21.1667 21.4751 21L22.6001 22.125C22.0668 22.4083 21.5084 22.625 20.9251 22.775C20.3418 22.925 19.7334 23 19.1001 23ZM17.1001 6.5C16.5501 6.5 16.0793 6.30417 15.6876 5.9125C15.2959 5.52083 15.1001 5.05 15.1001 4.5C15.1001 3.95 15.2959 3.47917 15.6876 3.0875C16.0793 2.69583 16.5501 2.5 17.1001 2.5C17.6501 2.5 18.1209 2.69583 18.5126 3.0875C18.9043 3.47917 19.1001 3.95 19.1001 4.5C19.1001 5.05 18.9043 5.52083 18.5126 5.9125C18.1209 6.30417 17.6501 6.5 17.1001 6.5Z"
      fill="currentColor"
    />
  </svg>
</template>
