<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 22C1.95 22 1.47917 21.8042 1.0875 21.4125C0.695833 21.0208 0.5 20.55 0.5 20V9C0.5 8.45 0.695833 7.97917 1.0875 7.5875C1.47917 7.19583 1.95 7 2.5 7H5.5V9H2.5V20H14.5V9H11.5V7H14.5C15.05 7 15.5208 7.19583 15.9125 7.5875C16.3042 7.97917 16.5 8.45 16.5 9V20C16.5 20.55 16.3042 21.0208 15.9125 21.4125C15.5208 21.8042 15.05 22 14.5 22H2.5ZM7.5 15V3.825L5.9 5.425L4.5 4L8.5 0L12.5 4L11.1 5.425L9.5 3.825V15H7.5Z"
      fill="currentColor"
    />
  </svg>
</template>
