<script lang="ts">
import { useRouteMeta } from '@/plugins/useRouteMeta'
import type { Plan } from '@/types'
import { toPeriod } from '@/utils/date-time-utils'
import { type PropType, defineComponent } from 'vue'

import AppUpgradeButton from '../inputs/AppUpgradeButton.vue'

/**
 * A modal to upgrade to a higher plan.
 */
export default defineComponent({
  components: {
    AppUpgradeButton,
  },
  props: {
    /**
     * The currently logged in user's highest plan.
     * If user is on monthly plan, this will be the yearly plan for same tier, if exists.
     */
    userPlan: {
      type: Object as PropType<Plan>,
      required: true,
    },
    /**
     * The plan to upgrade to.
     */
    upgradePlan: {
      type: Object as PropType<Plan>,
      required: true,
    },
    /**
     * Adds classes to the default upgrade button.
     */
    buttonClass: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      routeMeta: useRouteMeta(),
      toPeriod,
    }
  },
  computed: {
    planCost(): number {
      return Math.max(
        this.upgradePlan.discount_cost - (this.userPlan?.discount_cost ?? 0),
        0
      )
    },
  },
})
</script>

<template>
  <AppModal
    id="upgrade-modal"
    close-button-class="right-4 top-2 text-white bg-black"
    class="min-h-[400px] w-[380px]"
  >
    <template #button="{ openModal }">
      <slot :open-modal :upsell-plan="$userStore.upsellToPlan">
        <AppButton
          v-if="!$userStore.user?.subscriptions?.length"
          :route="{ name: 'memberships' }"
          class="button-primary mx-auto w-full"
          title="Free for 7 days"
          :class="buttonClass"
        >
          Free for 7 days
        </AppButton>
        <AppButton
          v-else
          class="button-primary mx-auto w-full"
          :title="`Upgrade to ${$userStore.upsellToPlan?.name}`"
          :class="buttonClass"
          @click="openModal"
        >
          Increase my odds
        </AppButton>
      </slot>
    </template>

    <template #default="{ closeModal }">
      <div
        class="upgrade-modal-content flex h-full w-full flex-col gap-6 bg-black p-5 pb-6 text-pure-white"
      >
        <p class="font-bold">Hi {{ $userStore.user?.first_name }},</p>

        <p class="">
          In order to access this benefit, you have to upgrade to
          <strong>{{ upgradePlan.name }}</strong>
          ({{ upgradePlan.period }}).
          <template v-if="$userStore.isInTrial">Your trial will end.</template>
        </p>

        <div class="flex flex-col gap-4">
          <div
            class="flex flex-col gap-2 rounded-lg bg-pure-white px-5 pb-8 pt-5 text-black"
          >
            <p class="text-xl font-semibold italic">
              {{ upgradePlan.name }}
            </p>

            <p>
              You will be charged
              <strong>{{ $n(planCost, 'currency') }}</strong>
            </p>

            <p>
              Your new {{ upgradePlan.period }} price will be
              <strong>{{ $n(upgradePlan.discount_cost, 'currency') }}</strong>
            </p>

            <AppAccordion
              name="upsell"
              :content="upgradePlan.features"
              class="-mx-2"
            />

            <AppUpgradeButton
              class="!mt-2"
              :user-plan
              :upgrade-plan
              @close-modal="closeModal"
            />
          </div>
        </div>

        <button
          type="button"
          class="mx-auto w-full underline hover:no-underline"
          title="No thanks"
          @click="closeModal"
        >
          No thanks
        </button>
      </div>
    </template>
  </AppModal>
</template>

<style lang="scss" scoped>
.upgrade-modal-content {
  @apply bg-[url('@/assets/images/upgrade-background.svg')] bg-[length:auto_100%] bg-right-top bg-no-repeat;
  min-height: fit-content;
  height: auto;
  // Safari iOS
  -webkit-overflow-scrolling: touch;
}
</style>
