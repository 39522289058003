<script lang="ts">
import { ValidationException } from '@/validation-exception'
import { type PropType, defineComponent } from 'vue'

/**
 * A general component with common select-box functionality.
 *
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
 */
export default defineComponent({
  inheritAttrs: false,
  props: {
    /**
     * Text describing the reason for the input.
     */
    label: {
      type: String,
    },
    /**
     * The name of the input sent to the API endpoint.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Options.
     */
    options: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
    /**
     * Used as a reactive floating text when the input has focus.
     */
    useFloatingPlaceholder: {
      type: Boolean,
      default: true,
    },
    /**
     * Text used when the input is empty.
     */
    placeholder: {
      type: String,
    },
    /**
     * Text displayed below the input element.
     */
    hint: {
      type: String,
    },
    /**
     * Moves the label to the left-hand side of the input.
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * @model
     */
    modelValue: {
      type: [Number, String],
      required: true,
    },
    /**
     * Displays input validation errors.
     */
    errors: {
      type: Object as PropType<ValidationException>,
    },
  },
  emits: {
    /**
     * Emits the input value via an update event for the v-model binding.
     *
     * @event update:modelValue
     * @type {number | string}
     */
    'update:modelValue': (payload: Number | String) => payload,
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value: Number | String) {
        this.$emit('update:modelValue', value)
      },
    },
    required(): boolean {
      return (this.$attrs.required ?? false) as boolean
    },
    disabled(): boolean {
      return (this.$attrs.disabled ?? false) as boolean
    },
    hasErrors(): boolean {
      return this.inputErrors.length > 0
    },
    inputErrors() {
      return this.errors instanceof ValidationException
        ? (this.errors.get(this.name) ?? [])
        : []
    },
  },
})
</script>

<template>
  <div
    :class="[
      disabled ? 'opacity-50' : '',
      inline ? 'flex-row items-center' : 'flex-col',
    ]"
    class="relative mb-3 flex flex-grow gap-2"
  >
    <label
      class="block text-sm"
      :for="name"
      :class="[
        useFloatingPlaceholder
          ? 'absolute left-4 top-1 text-xs text-slate-500'
          : 'font-bold text-black',
      ]"
    >
      {{ placeholder ?? label }}
      <span v-if="required" class="text-xs">*</span>
    </label>

    <select
      :id="name"
      v-bind="$attrs"
      v-model="value"
      :aria-errormessage="name + '-errors'"
      :aria-describedBy="name + '-hint'"
      :aria-invalid="hasErrors"
      :autocomplete="name"
      :class="[
        hasErrors ? 'border-red-500' : 'focus:border-transparent',
        disabled ? 'hover:cursor-not-allowed' : '',
        inline ? 'inline' : 'block w-full',
        useFloatingPlaceholder ? '!pt-6 placeholder-transparent' : '',
      ]"
      :name
      class="form-element form-select"
    >
      <option v-for="(opt, val) in options" :key="val" :value="val">
        {{ opt }}
      </option>
    </select>

    <div
      v-if="hasErrors"
      :id="name + '-errors'"
      :aria-live="hasErrors ? 'assertive' : 'off'"
      aria-atomic="true"
      class="errors"
    >
      <div
        v-for="error in inputErrors"
        :key="error"
        class="error text-xs font-bold text-red-500"
      >
        {{ error }}
      </div>
    </div>

    <div v-if="!hasErrors" :id="name + '-hint'" class="text-xs">
      {{ hint }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-select {
  @apply pb-2;
}
</style>
