<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6669 10.75L9.00023 9L11.2919 10.75L10.4169 7.91667L12.7502 6.08333H9.9169L9.00023 3.25L8.08357 6.08333H5.25023L7.5419 7.91667L6.6669 10.75ZM2.33357 14.0833C1.87523 14.0833 1.48287 13.9201 1.15648 13.5937C0.830095 13.2674 0.666901 12.875 0.666901 12.4167V9.60417C0.666901 9.45139 0.715512 9.31944 0.812734 9.20833C0.909956 9.09722 1.03496 9.02778 1.18773 9C1.52107 8.88889 1.79537 8.6875 2.01065 8.39583C2.22593 8.10417 2.33357 7.77778 2.33357 7.41667C2.33357 7.05555 2.22593 6.72917 2.01065 6.4375C1.79537 6.14583 1.52107 5.94444 1.18773 5.83333C1.03496 5.80555 0.909956 5.73611 0.812734 5.625C0.715512 5.51389 0.666901 5.38194 0.666901 5.22917V2.41667C0.666901 1.95833 0.830095 1.56597 1.15648 1.23958C1.48287 0.913194 1.87523 0.75 2.33357 0.75H15.6669C16.1252 0.75 16.5176 0.913194 16.844 1.23958C17.1704 1.56597 17.3336 1.95833 17.3336 2.41667V5.22917C17.3336 5.38194 17.285 5.51389 17.1877 5.625C17.0905 5.73611 16.9655 5.80555 16.8127 5.83333C16.4794 5.94444 16.2051 6.14583 15.9898 6.4375C15.7745 6.72917 15.6669 7.05555 15.6669 7.41667C15.6669 7.77778 15.7745 8.10417 15.9898 8.39583C16.2051 8.6875 16.4794 8.88889 16.8127 9C16.9655 9.02778 17.0905 9.09722 17.1877 9.20833C17.285 9.31944 17.3336 9.45139 17.3336 9.60417V12.4167C17.3336 12.875 17.1704 13.2674 16.844 13.5937C16.5176 13.9201 16.1252 14.0833 15.6669 14.0833H2.33357ZM2.33357 12.4167H15.6669V10.2917C15.153 9.98611 14.7468 9.57986 14.4481 9.07292C14.1495 8.56597 14.0002 8.01389 14.0002 7.41667C14.0002 6.81944 14.1495 6.26736 14.4481 5.76042C14.7468 5.25347 15.153 4.84722 15.6669 4.54167V2.41667H2.33357V4.54167C2.84746 4.84722 3.25371 5.25347 3.55232 5.76042C3.85093 6.26736 4.00023 6.81944 4.00023 7.41667C4.00023 8.01389 3.85093 8.56597 3.55232 9.07292C3.25371 9.57986 2.84746 9.98611 2.33357 10.2917V12.4167Z"
      fill="currentColor"
    />
  </svg>
</template>
