<template>
  <svg
    width="37"
    height="61"
    viewBox="0 0 37 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3807 48.6548L29.9977 27.6628H20.0019L26.5177 6.29281H6.13832V34.6033H15.3807V48.6548ZM35.9702 28.412L13.9439 60.1214C13.6409 60.5344 13.295 60.7869 12.9061 60.8789C12.5172 60.971 12.1232 60.9545 11.724 60.8294C11.3125 60.6918 10.9705 60.4664 10.6982 60.1532C10.4259 59.8401 10.2897 59.4463 10.2897 58.972V39.9399H5.89399C4.48882 39.9399 3.28591 39.4412 2.28525 38.4438C1.28453 37.4465 0.78418 36.2475 0.78418 34.8468V6.29281C0.78418 4.80728 1.30853 3.54335 2.35723 2.50101C3.40589 1.45867 4.66624 0.9375 6.13832 0.9375H27.9101C29.2853 0.9375 30.433 1.49233 31.3529 2.60198C32.2729 3.71163 32.5056 4.9419 32.0512 6.29281L27.2284 22.3075H32.8934C34.4642 22.3075 35.6075 22.9841 36.3232 24.3372C37.0389 25.6904 36.9212 27.0486 35.9702 28.412Z"
      fill="currentColor"
    />
  </svg>
</template>
