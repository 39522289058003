<script lang="ts">
import { capitalCase } from 'change-case'
import { defineComponent } from 'vue'

/**
 * A genertic container for a modal.
 *
 * Usage notes:
 * - The modal title must have the ID `modal-{id}`
 * - Upon displaying the modal, the first focusable must receive the focus
 * - If the primary action of the modal is destructive, set the initial focus to the least destructive action
 * - Keyboard interactions must be available (tab, shift+tab and esc)
 *
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/
 */
export default defineComponent({
  inheritAttrs: false,
  props: {
    /**
     * An ID for the modal, must be unique.
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Adds classes to the button which opens the modal.
     */
    buttonClass: {
      type: String,
    },
    /**
     * Adds a title to the button which opens the modal.
     */
    buttonTitle: {
      type: String,
    },
    /**
     * Open status of the modal.
     */
    open: {
      type: Boolean,
    },
    /**
     * Adds classes to the close button which closes the modal.
     */
    closeButtonClass: {
      type: String,
      default: 'right-4 top-4',
    },
    /**
     * Displays the close button.
     */
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    /**
     * Closes the modal when a user clicks outside the contents.
     */
    closeOnBlur: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['open', 'close'],
  setup() {
    return {
      capitalCase,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    modal(): HTMLDialogElement | null {
      return (this.$refs.dialogModal as HTMLDialogElement) ?? null
    },
  },
  watch: {
    open: {
      handler(isOpen: boolean) {
        if (isOpen) {
          this.openModal()
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    document.addEventListener('keyup', this.closeOnEsc)
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.closeOnEsc)
  },
  methods: {
    openModal() {
      // Timeout allows modal to be opened on first page load
      setTimeout(() => {
        this.$emit('open')
        this.isOpen = true
        this.modal?.showModal()
      }, 250)
    },
    closeModal() {
      this.$emit('close')
      this.isOpen = false
      this.modal?.close()
    },
    closeOnEsc(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        this.closeModal()
      }
    },
    closeWhenOffTarget() {
      if (this.closeOnBlur) {
        this.closeModal()
      }
    },
  },
})
</script>

<template>
  <slot name="button" :is-open :open-modal />

  <teleport to="#modal-teleport">
    <dialog
      :id
      ref="dialogModal"
      role="alertdialog"
      aria-modal="true"
      :aria-label="capitalCase(id)"
      class="modal m-auto rounded-lg"
      :class="{ flex: isOpen }"
      v-bind="$attrs"
      @click.self="closeWhenOffTarget"
    >
      <a
        v-if="showCloseButton"
        class="absolute flex cursor-pointer items-center justify-center rounded-full p-1"
        :class="closeButtonClass"
        @click.passive="closeModal"
      >
        <AppIcon :icon="Icons.Times" size="sm" />
      </a>

      <slot :is-open :close-modal :open-modal />
    </dialog>
  </teleport>
</template>
