<script lang="ts">
import type { NullableError } from '@/types'
import { defineComponent } from 'vue'

import AppDrawer from '../drawers/AppDrawer.vue'

export default defineComponent({
  components: {
    AppDrawer,
  },
  data() {
    return {
      form: {
        isLoading: false,
        data: {
          recoveryEmail: '',
        },
        errors: null as NullableError,
      },
    }
  },
  methods: {
    async sendPasswordResetRequest(isValid: boolean, closeDrawer: () => void) {
      if (!isValid) {
        return
      }

      this.form.errors = null
      this.form.isLoading = true

      try {
        const success = await this.$userStore.sendPasswordResetRequest(
          this.form.data.recoveryEmail
        )

        if (success) {
          this.$alertStore.showSuccessAlert({
            title: 'Password reset request sent',
            message:
              "If your account exists, we'll send you an email to reset your password.",
          })
          closeDrawer()
        }
      } catch (e: any) {
        this.form.errors = e
      } finally {
        this.form.isLoading = false
      }
    },
  },
})
</script>

<template>
  <AppDrawer
    id="forgot-password-drawer"
    aria-labelledby="drawerTitle"
    aria-describedby="drawerDescription"
    :close-on-blur="false"
  >
    <template #button="{ openDrawer }">
      <slot name="button" :open-drawer />
    </template>

    <template #default="{ closeDrawer }">
      <div
        class="flex h-full max-w-screen-sm flex-col justify-center p-6 md:px-10 md:py-12"
      >
        <p class="heading-2 mb-4 flex items-center gap-4" id="drawerTitle">
          <span @click="closeDrawer"><AppIcon :icon="Icons.ArrowLeft" /></span>
          Forgot password?
        </p>

        <p class="mb-3" id="drawerDescription">
          If you are a customer with us, you can reset your password below; just
          enter the email address associated with your account.
        </p>

        <AppForm
          :data="form.data"
          :errors="form.errors"
          class="flex flex-col justify-center gap-3"
          @submit="
            (isValid: boolean) => sendPasswordResetRequest(isValid, closeDrawer)
          "
        >
          <template #default="{ errors }">
            <fieldset>
              <AppInput
                v-model="form.data.recoveryEmail"
                name="recoveryEmail"
                type="email"
                placeholder="Enter your Email"
                :required="true"
                :errors
              />
            </fieldset>
          </template>

          <template #buttons="{ isSubmitting }">
            <AppButton
              class="button-primary mx-auto w-full"
              title="Reset Password"
              type="submit"
              icon=""
              :is-loading="form.isLoading || isSubmitting"
            >
              Reset Password
            </AppButton>
          </template>
        </AppForm>
      </div>
    </template>
  </AppDrawer>
</template>
