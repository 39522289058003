import tailwindFormsPlugin from '@tailwindcss/forms'
import tailwindTypographyPlugin from '@tailwindcss/typography'

/** @type {import('tailwindcss').Config} */
export default {
  content: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  safelist: [
    'white',
    'pure-white',
    'pastel-blue',
    'text-primary',
    'text-light-primary',
    'text-secondary',
    'text-dark-secondary',
    'text-tertiary',
    'text-light-tertiary',
    'black',
  ],
  // Toggle dark-mode based on .dark class or data-mode="dark"
  darkMode: ['class', '[data-mode="light"]'],
  theme: {
    fontFamily: {
      sans: ['Inter', 'sans-serif'],
    },
    // https://tailwindcss.com/docs/screens
    screens: {
      xs: '376px',
      // => @media (min-width: 640px) { ... }

      sm: '640px',
      // => @media (min-width: 640px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1025px',
      // => @media (min-width: 1024px) { ... }

      xl: '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }
    },
    extend: {
      aspectRatio: {
        '16/9': '16 / 9',
        '9/16': '9 / 16',
        '5/4': '5 / 4',
        '4/5': '4 / 5',
      },
      spacing: {
        4.5: '1.125rem',
      },
      borderRadius: {
        100: '100px',
      },
      fontSize: {
        '2xs': '0.5rem',
      },
      // https://tailwindcss.com/docs/customizing-colors
      // https://uicolors.app/create
      // + TailwindCSS Slate
      colors: {
        white: '#F8FAFC',
        'pure-white': '#FFFFFF',
        primary: '#8C78FF', // purple
        'light-primary': '#CABDFF', // light purple
        secondary: '#58FE70', // green
        'dark-secondary': '#009215', // dark green
        tertiary: '#FF7300', // orange
        'light-tertiary': '#FF9100', // light orange
        black: '#1E293B',
        'light-black': '#566989',
        teal: '#00ECF0',
        'pastel-blue': '#78A7FF',
      },
    },
  },
  plugins: [
    tailwindFormsPlugin,
    tailwindTypographyPlugin,
    function ({ addVariant }) {
      addVariant(
        'peer-placeholder-not-shown',
        ':merge(.peer):not(:placeholder-shown) ~ &'
      ) // Adds support for `peer-placeholder-not-shown:`
    },
    // Add plugin for line height calculation
    function ({ matchComponents, theme }) {
      matchComponents(
        {
          'h-line': (value) => {
            const height =
              value *
              16 *
              theme('fontSize.base[1].lineHeight').replace('rem', '')

            return { height }
          },
        },
        {
          values: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            10: '10',
          },
        }
      )
    },
  ],
}
