<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49996 26.75C7.88885 25.6945 7.43051 24.6111 7.12496 23.5C6.8194 22.3889 6.66663 21.25 6.66663 20.0834C6.66663 16.3611 7.95829 13.1945 10.5416 10.5834C13.125 7.97224 16.2777 6.66669 20 6.66669H20.2916L17.625 4.00002L19.9583 1.66669L26.625 8.33335L19.9583 15L17.625 12.6667L20.2916 10H20C17.2222 10 14.8611 10.9792 12.9166 12.9375C10.9722 14.8959 9.99996 17.2778 9.99996 20.0834C9.99996 20.8056 10.0833 21.5139 10.25 22.2084C10.4166 22.9028 10.6666 23.5834 11 24.25L8.49996 26.75ZM20.0416 38.3334L13.375 31.6667L20.0416 25L22.375 27.3334L19.7083 30H20C22.7777 30 25.1388 29.0209 27.0833 27.0625C29.0277 25.1042 30 22.7222 30 19.9167C30 19.1945 29.9166 18.4861 29.75 17.7917C29.5833 17.0972 29.3333 16.4167 29 15.75L31.5 13.25C32.1111 14.3056 32.5694 15.3889 32.875 16.5C33.1805 17.6111 33.3333 18.75 33.3333 19.9167C33.3333 23.6389 32.0416 26.8056 29.4583 29.4167C26.875 32.0278 23.7222 33.3334 20 33.3334H19.7083L22.375 36L20.0416 38.3334Z"
      fill="currentColor"
    />
  </svg>
</template>
