<script lang="ts">
import { useRouteMeta } from '@/plugins/useRouteMeta'
import { toPeriod } from '@/utils/date-time-utils'
import { Themes } from '@/utils/helper-objects'
import { defineComponent } from 'vue'

/**
 * A card to display a plan.
 *
 */
export default defineComponent({
  setup() {
    return { toPeriod, Themes, routeMeta: useRouteMeta() }
  },
})
</script>

<template>
  <article
    class="plan-card flex flex-grow flex-col justify-center rounded-lg border border-slate-300 bg-pure-white p-10 text-center"
    tabindex="0"
  >
    <img
      src="@/assets/images/postal-icon.svg"
      alt="Postal Icon"
      class="mx-auto"
    />

    <div class="my-3 text-xl font-bold italic">
      {{ $t('section.plans.card.free.heading') }}
    </div>

    <p>You can enter for free online, just click the button below.</p>

    <AppButton
      v-if="routeMeta.featuredCampaign"
      :route="{
        name: 'campaign',
        params: {
          campaignCode: routeMeta.featuredCampaign.code,
        },
      }"
      class="button-primary my-6 capitalize"
      :title="$t('section.plans.card.free.excerpt.via_online')"
    >
      {{ $t('section.plans.card.free.excerpt.via_online') }}
    </AppButton>

    <div class="line-through-sides my-6 font-bold">
      {{ $t('section.plans.card.free.excerpt.via_post') }}
    </div>

    <p>
      Click
      <RouterLink :to="{ name: 'full-terms-and-conditions' }" class="link"
        >here</RouterLink
      >
      to read more about the postal entry route
    </p>
  </article>
</template>
