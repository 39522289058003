import type { RemainingDateTime } from '@/types'
import type { DateTime } from 'luxon'
import { ref } from 'vue'

export function useCountdown(timerEndCallback?: Function) {
  const countdownInterval = ref(0)
  const remainingDateTime = ref<RemainingDateTime>({
    total: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const remainingDateTimeFormatted = ref({
    days: '0',
    hours: '0',
    minutes: '0',
    seconds: '0',
  })
  const showAsDate = ref(false)

  let datetime: DateTime

  function start(newDatetime: DateTime) {
    clearInterval(countdownInterval.value)

    datetime = newDatetime

    window.setTimeout(() => {
      countdown()

      countdownInterval.value = window.setInterval(() => {
        countdown()
      }, 1000)
    }, 500)

    countdown()
  }

  function end() {
    clearInterval(countdownInterval.value)
  }

  function countdown() {
    const dateDifference = datetime.diffNow([
      'days',
      'hours',
      'minutes',
      'seconds',
      'milliseconds',
    ])

    if (dateDifference.milliseconds <= 0) {
      end()
      timerEndCallback?.()

      return
    }

    remainingDateTime.value = {
      total: dateDifference.milliseconds,
      days: dateDifference.days,
      hours: dateDifference.hours,
      minutes: dateDifference.minutes,
      seconds: Math.round(dateDifference.seconds),
    }

    remainingDateTimeFormatted.value = {
      days: `${remainingDateTime.value.days}`.padStart(2, '0'),
      hours: `${remainingDateTime.value.hours}`.padStart(2, '0'),
      minutes: `${remainingDateTime.value.minutes}`.padStart(2, '0'),
      seconds: `${remainingDateTime.value.seconds}`.padStart(2, '0'),
    }

    showAsDate.value = remainingDateTime.value.days > 30
  }

  return {
    start,
    end,
    showAsDate,
    remainingDateTime,
    remainingDateTimeFormatted,
  }
}
