import { useUrlSearchParams } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies.mjs'
import { DateTime } from 'luxon'

/**
 * Sets tracking cookies as defined by the url query parameters.
 */
export default function cookiesGuard() {
  const queryParams = useUrlSearchParams()
  const cookies = useCookies()

  const expires = DateTime.now().plus({ year: 1 }).toJSDate()
  const cookieMap = {
    utm_source: 'gls',
    utm_medium: 'glm',
    utm_campaign: 'glc',
    awc: 'awc',
    affiliate: 'awaff',
    in_id: 'glinid',
    ten_id: 'gltenid',
    ra_cid: 'ra_cid',
    par_id: 'glparid',
  }

  for (const [queryKey, cookieName] of Object.entries(cookieMap)) {
    const queryValue = queryParams[queryKey]

    if (queryValue) {
      cookies.set(cookieName, queryValue, {
        secure: true,
        domain: location.hostname,
        path: '/',
        expires,
      })
    }
  }
}
