<script lang="ts">
import { usePrizesStore } from '@/stores/prizes'
import type { Prize } from '@/types'
import { PrizeCategories } from '@/utils/helper-objects'
import { defineComponent } from 'vue'

import ProductOverviewCard from '../cards/ProductOverviewCard.vue'

/**
 * A product overview section.
 */
export default defineComponent({
  components: {
    ProductOverviewCard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const prizesStore = usePrizesStore()

    return {
      prizesStore,
    }
  },
  computed: {
    prizes(): Array<Prize> {
      return this.prizesStore
        .prizesByCategory(PrizeCategories.Featured)
        .filter((prize: Prize) => prize.drawn_at.diffNow().milliseconds >= 0)
    },
  },
  async mounted() {
    this.prizesStore.fetchPrizes()
  },
})
</script>

<template>
  <AppSection>
    <h2 class="heading-2">
      {{ $t('section.rewards.heading') }}
    </h2>
    <p>
      {{ $t('section.rewards.excerpt') }}
    </p>

    <AppSlider :slider-id="`${id}-slider`" class="full-bleed-mobile">
      <ProductOverviewCard
        v-for="(product, key) in prizesStore.productOverviews.reverse()"
        :key="key"
        :product="product"
      />
    </AppSlider>

    <AppButton
      v-if="$userStore.isLoggedIn"
      :route="{ name: 'benefits' }"
      class="button-secondary button-secondary-light mx-auto mt-4 w-full font-semibold"
      :title="$t('button.see.all_benefits')"
    >
      {{ $t('button.see.all_benefits') }}
    </AppButton>
    <AppMembershipButton v-if="$userStore.isLoggedOut" />
  </AppSection>
</template>
