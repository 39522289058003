<script lang="ts">
import type { TIcon } from '@/types'
import { pascalCase } from 'change-case'
import { type PropType, defineComponent } from 'vue'

import IconAccordionArrow from './IconAccordionArrow.vue'
import IconArrowDown from './IconArrowDown.vue'
import IconArrowDownThin from './IconArrowDownThin.vue'
import IconArrowLeft from './IconArrowLeft.vue'
import IconArrowRight from './IconArrowRight.vue'
import IconCalendar from './IconCalendar.vue'
import IconCash from './IconCash.vue'
import IconCheck from './IconCheck.vue'
import IconClock from './IconClock.vue'
import IconDiamond from './IconDiamond.vue'
import IconDiversity from './IconDiversity.vue'
import IconDreamCars from './IconDreamCars.vue'
import IconEdit from './IconEdit.vue'
import IconEnvelope from './IconEnvelope.vue'
import IconError from './IconError.vue'
import IconExperiences from './IconExperiences.vue'
import IconGetAways from './IconGetAways.vue'
import IconHandHeart from './IconHandHeart.vue'
import IconHome from './IconHome.vue'
import IconInformation from './IconInformation.vue'
import IconLightening from './IconLightening.vue'
import IconLuxuryItem from './IconLuxuryItem.vue'
import IconMember from './IconMember.vue'
import IconMembersZone from './IconMembersZone.vue'
import IconMoneyBag from './IconMoneyBag.vue'
import IconMoneyBox from './IconMoneyBox.vue'
import IconPlay from './IconPlay.vue'
import IconPresent from './IconPresent.vue'
import IconPrize from './IconPrize.vue'
import IconPrizeSack from './IconPrizeSack.vue'
import IconQuestion from './IconQuestion.vue'
import IconReload from './IconReload.vue'
import IconSetting from './IconSetting.vue'
import IconShare from './IconShare.vue'
import IconStar from './IconStar.vue'
import IconStarTick from './IconStarTick.vue'
import IconTechnology from './IconTechnology.vue'
import IconTelephone from './IconTelephone.vue'
import IconTicket from './IconTicket.vue'
import IconTimer from './IconTimer.vue'
import IconTimes from './IconTimes.vue'
import IconTrophy from './IconTrophy.vue'
import IconTv from './IconTv.vue'

/**
 * An general icon component listing all icons available.
 *
 * @see helper-objects::Icons
 */
export default defineComponent({
  components: {
    IconAccordionArrow,
    IconArrowDown,
    IconArrowDownThin,
    IconArrowLeft,
    IconArrowRight,
    IconCalendar,
    IconCash,
    IconCheck,
    IconClock,
    IconDiamond,
    IconDiversity,
    IconDreamCars,
    IconEdit,
    IconEnvelope,
    IconError,
    IconExperiences,
    IconGetAways,
    IconHandHeart,
    IconHome,
    IconInformation,
    IconLightening,
    IconLuxuryItem,
    IconMember,
    IconMembersZone,
    IconMoneyBag,
    IconMoneyBox,
    IconPlay,
    IconPresent,
    IconPrize,
    IconPrizeSack,
    IconQuestion,
    IconReload,
    IconSetting,
    IconShare,
    IconStar,
    IconStarTick,
    IconTechnology,
    IconTelephone,
    IconTicket,
    IconTimer,
    IconTimes,
    IconTrophy,
    IconTv,
  },
  inheritAttrs: false,
  props: {
    /**
     * The name of the icon to display
     */
    icon: {
      type: String as PropType<TIcon>,
      required: true,
    },
    /**
     * Size of the icon.
     *
     * xs: h-4 w-4
     * sm: h-6 w-6
     * md: h-8 w-8
     * lg: h-12 w-12
     */
    size: {
      type: String,
      default: 'sm',
    },
    /**
     * Aria label.
     */
    label: {
      type: String,
    },
  },
  setup() {
    return {
      pascalCase,
    }
  },
  computed: {
    iconName() {
      return 'Icon' + pascalCase(this.icon.replace('Icon', ''))
    },
    iconSize() {
      switch (this.size) {
        case '2xs':
          return 'h-3 w-3'

        case 'xs':
          return 'h-4 w-4'

        default:
        case 'sm':
          return 'h-6 w-6'

        case 'md':
          return 'h-8 w-8'

        case 'lg':
          return 'h-12 w-12'
      }
    },
  },
})
</script>

<template>
  <component
    :is="iconName"
    :class="[$attrs.class, iconSize]"
    class="inline-flex items-center justify-center focus:outline-none"
    role="presentation"
  />
</template>
