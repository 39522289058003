// Starting or ending with one or more /, space or word boundary
const urlTidyRegex = /(^[\s\b/]+)|([/\s\b]+$)/gim

/**
 * Generates a Portal URL to a specific path.
 * This utilty function allows for differences in PORTAL_URL or path lead/trailing spaces/slashes.
 *
 * @param {string} path
 * @returns {string}
 */
export function toPortalUrl(path: string): string {
  const PORTAL_URL = import.meta.env.GLP_PORTAL_URL.replace(urlTidyRegex, '')
  const trimPath = path.replace(urlTidyRegex, '')
  return `${PORTAL_URL}/${trimPath}`
}

/**
 * Generates a GLX URL to a specific path.
 * This utilty function allows for differences in GLX_URL or path lead/trailing spaces/slashes.
 *
 * @param {string} path
 * @returns {string}
 */
export function toGlxUrl(path: string): string {
  const GLX_URL = import.meta.env.GLP_GLX_URL.replace(urlTidyRegex, '')
  const trimPath = path.replace(urlTidyRegex, '')
  return `${GLX_URL}/${trimPath}`
}

/**
 * Generates a Checkout URL to a specific path.
 * This utilty function allows for differences in API_URL or path lead/trailing spaces/slashes.
 *
 * @param {string} path
 * @returns {string}
 */
export function toApiUrl(path: string): string {
  const API_URL = import.meta.env.GLP_API_URL.replace(urlTidyRegex, '')
  const trimPath = path.replace(urlTidyRegex, '')
  return `${API_URL}/${trimPath}`
}

/**
 * Generates a Wordpress URL to a specific path.
 * This utilty function allows for differences in API_URL or path lead/trailing spaces/slashes.
 *
 * @param {string} path
 * @returns {string}
 */
export function toWordpressUrl(path: string): string {
  const API_URL = import.meta.env.GLP_WORDPRESS_URL.replace(urlTidyRegex, '')
  const trimPath = path.replace(urlTidyRegex, '')
  return `${API_URL}/${trimPath}`
}

/**
 * Generates a full URL for this site.
 * This utilty function allows for differences in API_URL or path lead/trailing spaces/slashes.
 *
 * @param {string} path
 * @returns {string}
 */
export function toFullUrl(path: string = ''): string {
  const hostname = location.origin.replace(urlTidyRegex, '')
  const trimPath = path.replace(urlTidyRegex, '')
  return `${hostname}/${trimPath}`
}

/**
 * Generates a GLIDE url to specific path.
 * This utilty function allows for differences in API_URL or path lead/trailing spaces/slashes.
 *
 * @param {string} path
 * @returns {string}
 */
export function toGlideUrl(path: string): string {
  const API_URL = import.meta.env.GLP_GLIDE_URL.replace(urlTidyRegex, '')
  const trimPath = path.replace(urlTidyRegex, '')
  return `${API_URL}/${trimPath}`
}
