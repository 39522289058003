<script lang="ts">
import { type FaqAccordionContent } from '@/types'
import { type PropType, defineComponent } from 'vue'

/**
 * FAQ Section.
 */
export default defineComponent({
  props: {
    displayButton: {
      type: Boolean,
      default: true,
    },
    faqContent: {
      type: Object as PropType<FaqAccordionContent>,
      required: true,
    },
  },
  data() {
    return {}
  },
})
</script>

<template>
  <AppSection>
    <div class="rounded-lg border border-slate-300 bg-pure-white p-6">
      <div class="mx-auto max-w-screen-lg">
        <h2 class="heading-2 text-center">
          {{ faqContent?.title ?? $t('section.faqs.heading') }}
        </h2>

        <AppAccordion
          name="faq"
          :content="faqContent?.content ?? ''"
          :class="[displayButton ? 'my-8' : 'mt-8']"
        />

        <AppButton
          v-if="displayButton"
          title="Explore all FAQs"
          :route="{ name: 'faq' }"
          class="mx-auto mb-2 w-full bg-primary"
        >
          Explore all FAQs
        </AppButton>
      </div>
    </div>
  </AppSection>
</template>
