<script lang="ts">
import { kebabCase } from 'change-case'
import { defineComponent } from 'vue'

/**
 * A container to display a section.
 */
export default defineComponent({
  props: {
    /**
     * Sets the minimum height of the section. Default is 712px.
     */
    minHeight: {
      type: [String, Number],
      default: '90vh',
    },
  },
  mounted() {
    if (!this.$el.hasAttribute('aria-labelledby')) {
      const headings = this.$el.querySelectorAll('h1, h2, h3, h4, h5, h6')

      if (headings.length) {
        const label = headings.item(0)

        if (!label.hasAttribute('id')) {
          label.setAttribute('id', `heading-${kebabCase(label.textContent)}`)
        }

        this.$el.setAttribute('aria-labelledby', label.getAttribute('id'))
      } else {
        console.warn('ARIA Issue: Missing `aria-labelledby` element.', this.$el)
      }
    }
  },
})
</script>

<template>
  <section class="relative gap-y-3 px-5 py-6 lg:py-12">
    <slot />
  </section>
</template>

<style lang="scss">
section:only-of-type {
  @apply lg:pb-12;
}

section.breadcrumb + section:not(.hero-section):not(.max-sm:pt-0) {
  @apply pt-3 !important;
}

section:not(.dark-section, .bg-primary, .sponsorship-hero-section)
  + section:not(.dark-section) {
  @apply lg:pt-6;
}

section:not(.dark-section):has(+ section:not(.dark-section, .bg-primary)) {
  @apply lg:pb-6;
}
</style>
