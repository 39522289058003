<script lang="ts">
import { defineComponent } from 'vue'

/**
 * A container to display an aside.
 */
export default defineComponent({
  props: {
    /**
     * The ID of the element that labels this aside.
     */
    labelledBy: {
      type: String,
      required: true,
    },
  },
})
</script>

<template>
  <aside class="relative p-6" :aria-labelledby="labelledBy">
    <slot />
  </aside>
</template>
