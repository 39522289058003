import fetcher from '@/services/fetcher'
import type { Nullable, Winner } from '@/types'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'

import { usePrizesStore } from './prizes'

type WinnersStoreState = {
  winners: Map<string, Winner>
  fetchPromise: Promise<Array<Winner>> | null
}

export const useWinnersStore = defineStore('winners', {
  state: (): WinnersStoreState => ({
    winners: new Map<string, Winner>(),
    fetchPromise: null,
  }),

  getters: {
    winnersArray(): Array<Winner> {
      const prizesStore = usePrizesStore()

      return Array.from(this.winners.values()).sort(
        (winner1: Winner, winner2: Winner) => {
          const winner1Prize = prizesStore.prizes.get(winner1.prize_code)
          const winner2Prize = prizesStore.prizes.get(winner2.prize_code)

          if (!winner1Prize || !winner2Prize) {
            return Number.MAX_SAFE_INTEGER
          }

          return (
            winner2Prize.close_at.toMillis() - winner1Prize.close_at.toMillis()
          )
        }
      )
    },
    highlightedWinners(): Array<Winner> {
      return this.winnersArray.filter((w: Winner) => w.highlighted)
    },
  },

  actions: {
    async fetchWinners(): Promise<void | Array<Winner>> {
      // return the fetch winners promise to prevent multiple calls
      if (this.fetchPromise) {
        return this.fetchPromise
      }

      if (this.winners.size) {
        return this.winnersArray
      }

      const prizesStore = usePrizesStore()

      try {
        this.fetchPromise = fetcher
          .get<Winner>('/data/winners.json', false, {
            key: 'prize_code',
            map: this.winners,
          })
          .then(async () => {
            await Promise.all(
              this.winnersArray.map((winner: Winner) => {
                return prizesStore.fetchPrize(winner.prize_code)
              })
            )

            return this.winnersArray
          })
      } catch (error: any) {
        Sentry.captureException(error)
      }

      return this.fetchPromise ?? this.winnersArray
    },
    async fetchWinnerByPrizeCode(prizeCode: string): Promise<Nullable<Winner>> {
      const prizesStore = usePrizesStore()

      await Promise.all([
        this.fetchWinners(),
        prizesStore.fetchPrize(prizeCode),
      ])

      return (
        this.winnersArray.find((w: Winner) => w.prize_code === prizeCode) ??
        null
      )
    },
  },
})
