<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2319_11779)">
      <path
        d="M13.3001 3.16439L15.6667 5.53105L4.70008 16.4977L15.6667 27.4644L13.3001 29.8311L-0.0332558 16.4977L13.3001 3.16439Z"
        fill="currentColor"
        :stroke="strokeColor"
        :stroke-width="strokeWidth"
      />
    </g>
    <defs>
      <clipPath id="clip0_2319_11779">
        <rect
          width="16"
          height="32"
          fill="white"
          transform="translate(16 32.5) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    strokeColor: {
      type: String,
      default: '#000000',
    },
    strokeWidth: {
      type: Number,
      default: 1,
    },
  },
})
</script>
