<script lang="ts">
import { usePrizesStore } from '@/stores/prizes'
import type { Nullable, Prize, Winner } from '@/types'
import { capitalCase } from 'change-case'
import { type PropType, defineComponent } from 'vue'

/**
 * A card to display a winner.
 *
 */
export default defineComponent({
  props: {
    /**
     * A configured winner's object.
     */
    winner: {
      type: Object as PropType<Winner>,
      required: true,
    },
  },
  setup() {
    return {
      capitalCase,
      prizeStore: usePrizesStore(),
    }
  },
  data() {
    return {
      prize: null as Nullable<Prize>,
    }
  },
  async mounted() {
    this.prize = await this.prizeStore.fetchPrize(this.winner.prize_code)
  },
  methods: {
    open(openVideoModal?: () => void) {
      if (!this.prize) {
        console.error('Prize not found')
        return
      }

      if (this.prize.youtube_id && openVideoModal) {
        return openVideoModal()
      }

      return this.$router.push({
        name: 'live-draw',
        params: { prizeCode: this.prize.code },
      })
    },
  },
})
</script>

<template>
  <article
    class="card winner-card flex !min-h-full flex-col hover:cursor-pointer"
  >
    <AppYouTubeModal
      v-if="prize"
      :id="prize.youtube_id ?? winner.name"
      :title="`Watch a video of ${winner.name} winning`"
    >
      <template #default="{ openModal }">
        <div class="flex h-full flex-grow flex-col" @click="open(openModal)">
          <div class="relative">
            <img
              :src="winner.image"
              :alt="winner.name"
              class="aspect-square w-full rounded-t-lg object-cover"
            />
            <button
              v-if="prize.youtube_id"
              class="absolute bottom-3 right-2 w-max rounded-100 bg-black/70 px-2 py-1 text-white"
              :aria-label="prize ? `Watch ${prize.title} draw video now` : ''"
            >
              <AppIcon
                :icon="Icons.Play"
                class="rounded-full bg-tertiary p-[2px]"
              />
              Watch now
            </button>
          </div>

          <p
            v-if="prize"
            class="flex justify-center gap-2 bg-primary px-3 py-2 text-lg font-black text-white"
          >
            Big Winner
          </p>

          <div
            class="flex flex-grow flex-col rounded-b-lg bg-pure-white px-3 pb-8 pt-3 text-center text-black"
          >
            <p class="text-center text-xl font-extrabold">
              {{ winner.name }}
            </p>

            <div class="flex flex-grow flex-col gap-3">
              <p v-if="prize">
                Won this incredible<br />
                <span class="font-semibold">{{ prize.title }}</span>
              </p>

              <hr class="mt-auto" />

              <p class="whitespace-nowrap">
                Ticket number: {{ winner.ticket }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </AppYouTubeModal>
  </article>
</template>

<style lang="scss" scoped>
.winner-card {
  img {
    height: 288px;
  }
}
</style>
