import { useUserStore } from '@/stores/user'
import type { RouteLocation, RouteLocationRaw } from 'vue-router'

/**
 * Middleware to check if the user is logged out
 * and if not redirect to the login page.
 *
 * @param {RouteLocation} to
 * @returns {RouteLocationRaw | boolean}
 */
export default function AuthMiddleware(
  to: RouteLocation
): RouteLocationRaw | boolean {
  const userStore = useUserStore()

  // reject the navigation
  if (userStore.isLoggedOut && to.name !== 'login') {
    return { name: 'login' }
  }

  return true
}
