<script lang="ts">
import { type PropType, defineComponent } from 'vue'

export default defineComponent({
  props: {
    route: {
      type: Object as PropType<{
        name: String
        params: String
      }>,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      defaultClass:
        'rounded-full border-2 border-slate-800 bg-transparent px-3 py-1 text-sm font-bold text-slate-800',
    }
  },
})
</script>

<template>
  <!-- @vue-skip  -->
  <RouterLink v-if="route" :to="route" :title="text" :class="defaultClass">
    {{ text }}
  </RouterLink>
  <div v-else :class="defaultClass">
    {{ text }}
  </div>
</template>
