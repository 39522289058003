<script lang="ts">
import FaqSection from '@/components/sections/FaqSection.vue'
import InstantWinsAndUpcomingPrizes from '@/components/sections/InstantWinsAndUpcomingPrizes.vue'
import ProductOverviewSection from '@/components/sections/ProductOverviewSection.vue'
import SponsorshipSection from '@/components/sections/SponsorshipSection.vue'
import WinnersSection from '@/components/sections/WinnersSection.vue'
import { useRouteMeta } from '@/plugins/useRouteMeta'
import { useFaqStore } from '@/stores/faq'
import { Themes } from '@/utils/helper-objects'
import { defineComponent } from 'vue'

/**
 * The "Home" page.
 */
export default defineComponent({
  components: {
    FaqSection,
    ProductOverviewSection,
    SponsorshipSection,
    WinnersSection,
    InstantWinsAndUpcomingPrizes,
  },
  setup() {
    return {
      routeMeta: useRouteMeta(),
      faqStore: useFaqStore(),
      Themes,
    }
  },
})
</script>

<template>
  <AppSection
    class="dark-section md:bleed hero-section max-sm:flex max-sm:flex-col"
  >
    <div
      class="max-md:hangover-content relative flex flex-col justify-center px-5 py-5 max-md:!-mt-[7vh] max-sm:pb-6 md:items-start md:pr-8 lg:pr-16"
    >
      <div>
        <h1
          id="home-hero-header"
          class="heading-1 text-center lg:text-left"
          v-html="$t('page.home.hero.heading')"
        />
      </div>

      <div class="mt-6 w-full">
        <AppUpgradeModal
          v-if="$userStore.showUpgradePlan"
          :user-plan="$userStore.highestPlan"
          :upgrade-plan="
            $plansStore.getPlanByTier('vip', $userStore.highestPlan?.period)
          "
          button-class="!mx-0"
        />
        <AppButton
          v-if="$userStore.hasSubscription && !$userStore.upsellToPlan"
          :route="{
            name: 'members-zone',
          }"
          class="button-primary mt-6 w-full md:my-6"
          :title="$t('button.view.membership')"
        >
          {{ $t('button.view.membership') }}
        </AppButton>
        <AppButton
          v-if="$userStore.isLoggedIn && !$userStore.hasSubscription"
          :route="{ name: 'memberships' }"
          class="button-primary mx-auto w-full"
          title="Free for 7 days"
        >
          Free for 7 days
        </AppButton>
        <AppButton
          v-if="$userStore.isLoggedOut && routeMeta.featuredCampaign"
          class="button-primary mx-auto w-full md:mx-0"
          :route="{
            name: 'campaign',
            params: {
              campaignCode: routeMeta.featuredCampaign.code,
            },
          }"
          title="Join for free"
        >
          Join for free
        </AppButton>

        <AppTrustpilot
          :theme="Themes.Dark"
          class="mx-auto mt-6 flex max-w-[400px] justify-center rounded-lg bg-black/80 p-3 md:mx-0"
        />
      </div>
    </div>

    <div
      class="hero-image bleed-right relative order-first w-full md:order-last"
    >
      <AppPicture
        v-if="routeMeta.featuredCampaign"
        :mobile="routeMeta.featuredCampaign.main_image.desktop"
        :tablet="routeMeta.featuredCampaign.main_image.tablet"
        :desktop="routeMeta.featuredCampaign.main_image.desktop"
        img-class="!aspect-16/9"
        :alt="routeMeta.featuredCampaign.main_image.alt"
        class="hero-section-image"
      />
    </div>
  </AppSection>

  <InstantWinsAndUpcomingPrizes />

  <AsSeenInBanner />

  <ProductOverviewSection id="product-overview-section" />

  <PlansSection />

  <FaqSection :faq-content="faqStore.homepageFaq" />

  <TrustpilotBanner v-if="$userStore.isLoggedIn" class="!pb-12" />

  <WinnersSection>
    <template #heading>
      {{ $t('section.winners.heading') }}
    </template>

    <template #sub-heading>
      {{ $t('section.winners.excerpt') }}
    </template>
  </WinnersSection>

  <SponsorshipSection :display-watch-button="false" />

  <HighlightBanner />
</template>

<style lang="scss" scoped>
.hero-section {
  @apply bg-[url('@/assets/images/hero-background-sm.svg')] bg-right-bottom bg-no-repeat md:bg-[url('@/assets/images/hero-background.svg')] md:bg-right lg:bg-[length:auto_100%] lg:bg-left-bottom;
}
</style>
