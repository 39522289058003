import fetcher from '@/services/fetcher'
import type { Nullable, Plan, TMembershipPeriod } from '@/types'
import { type TPlanTier } from '@/types'
import { MembershipPeriods } from '@/utils/helper-objects'
import { toPortalUrl } from '@/utils/url-generation-utils'
import { groupBy } from '@/utils/utils'
import * as Sentry from '@sentry/vue'
import { useCookies } from '@vueuse/integrations/useCookies.mjs'
import { defineStore } from 'pinia'

import { useUserStore } from './user'

type PlansStoreState = {
  plans: Map<string, Plan>
  legacyPlans: Map<string, Plan>
  upsellPlans: Map<string, Plan>
  affiliatePlans: Map<string, Plan>
  selectedMembershipPeriod: string
}

const upgradePaths: Record<TPlanTier, TPlanTier[]> = {
  postal: [],
  lite: ['standard', 'platinum', 'vip'],
  standard: ['platinum', 'vip'],
  'standard-plus': ['platinum', 'vip'],
  unlimited: ['platinum'],
  platinum: ['vip'],
  gold: ['vip'],
  vip: [],
} as const

export const usePlansStore = defineStore('plans', {
  state: (): PlansStoreState => ({
    plans: new Map(),
    legacyPlans: new Map(),
    upsellPlans: new Map(),
    affiliatePlans: new Map(),
    selectedMembershipPeriod: MembershipPeriods.Monthly,
  }),

  actions: {
    async fetchPlans(): Promise<PlansStoreState['plans']> {
      // if (this.plans.size) {
      //   return this.plans
      // }

      try {
        await fetcher.get<Plan>('/data/plans.json', false, {
          key: 'code',
          map: this.plans,
        })
      } catch (error: any) {
        Sentry.captureException(error)
      }

      return this.plans
    },
    async fetchLegacyPlans(): Promise<PlansStoreState['legacyPlans']> {
      // if (this.plans.size) {
      //   return this.plans
      // }

      try {
        await fetcher.get<Plan>('/data/legacy-plans.json', false, {
          key: 'id',
          map: this.legacyPlans,
        })
      } catch (error: any) {
        Sentry.captureException(error)
      }

      return this.legacyPlans
    },
    async fetchUpsellPlans(): Promise<PlansStoreState['upsellPlans']> {
      try {
        await fetcher.get<Plan>('/data/upsell-plans.json', false, {
          key: 'code',
          map: this.upsellPlans,
        })
      } catch (error: any) {
        Sentry.captureException(error)
      }

      return this.upsellPlans
    },
    async fetchAffiliatePlans(): Promise<PlansStoreState['affiliatePlans']> {
      try {
        await fetcher.get<Plan>('/data/affiliate-plans.json', false, {
          key: 'code',
          map: this.affiliatePlans,
        })
      } catch (error: any) {
        Sentry.captureException(error)
      }

      return this.affiliatePlans
    },
    async fetchAllPlans() {
      await Promise.all([
        this.fetchPlans(),
        this.fetchLegacyPlans(),
        this.fetchUpsellPlans(),
        this.fetchAffiliatePlans(),
      ])
    },
    getPlan(planCode: string, isAffiliatePlan: number = 0): Nullable<Plan> {
      if (isAffiliatePlan) {
        return this.affiliatePlans.get(planCode) ?? null
      }

      return (
        this.plans.get(planCode) ??
        this.legacyPlans.get(planCode) ??
        this.upsellPlans.get(planCode) ??
        null
      )
    },
    getPlanByTier(
      planTier: string,
      period: TMembershipPeriod = MembershipPeriods.Monthly
    ): Nullable<Plan> {
      return (
        this.plansArray.find(
          (p: Plan) =>
            p.tier.toLowerCase() === planTier.toLowerCase() &&
            p.period.toLowerCase() === period.toLowerCase()
        ) ?? null
      )
    },
    legacyPlansByCodeArray(planCode: string): Array<Plan> {
      return [...this.legacyPlans.values(), ...this.plans.values()].filter(
        (plan: Plan) => plan.id && plan.id.includes(planCode)
      )
    },
    getAffiliatePlanByTier(
      planTier: string,
      period: TMembershipPeriod = MembershipPeriods.Monthly
    ): Nullable<Plan> {
      return (
        this.affiliatePlansArray.find(
          (p: Plan) => p.tier === planTier && p.period === period
        ) ?? null
      )
    },
    async getUpgradePlans(purchasedPlanCode: string): Promise<Plan[]> {
      // Return empty array if upsellPlans is not set or empty
      // We can fetch the upsellPlans from the server here if we want
      // First ensure upsell plans are loaded
      if (this.upsellPlans.size === 0) {
        await this.fetchUpsellPlans()
      }
      const purchasedPlan = this.getPlan(purchasedPlanCode)
      const allowedTiers = purchasedPlan?.tier
        ? upgradePaths[purchasedPlan.tier]
        : []

      return Array.from(this.upsellPlans.values()).filter((p: Plan) =>
        allowedTiers.includes(p.tier as TPlanTier)
      )
    },
    async upgrade(
      upgradePlan: Plan,
      userPlan: Plan,
      type: 'upsell' | 'upgrade' = 'upgrade'
    ) {
      let url = ''

      if (type === 'upsell') {
        url = `subscriptions/${upgradePlan.tier}-upsell/upgrade/`
      } else {
        url = `subscriptions/${upgradePlan.tier}-${upgradePlan.period}/upgrade/`
      }

      const { error } = await fetcher.post(
        toPortalUrl(url),
        { purchased_plan_code: userPlan.code },
        true
      )

      if (error.value) {
        throw error.value
      }

      return true
    },
    async endTrial() {
      const userStore = useUserStore()
      const cookies = useCookies(['authToken'], {
        autoUpdateDependencies: true,
      })
      const authToken = cookies.get('authToken')

      if (!userStore.user || !authToken) {
        console.error('User must be logged in to end trial')
        return
      }

      const { data, error } = await fetcher.post(
        toPortalUrl('/pipe'),
        {
          action: 'POST',
          endpoint: 'subscription/endTrial',
          data: {
            email: userStore.user.email,
          },
        },
        true
      )

      if (
        error.value ||
        !data.value ||
        !fetcher.isOk(data.value?.csiResponseStatus ?? 0)
      ) {
        throw new Error(
          data.value?.csiResponseJson.message ??
            error.value?.message ??
            `Unable to end your trial, please contact support.`
        )
      }

      return true
    },
  },

  getters: {
    plansArray(): Array<Plan> {
      return this.plans.size > 0 ? Array.from(this.plans.values()) : []
    },
    annualPlans(): Array<Plan> {
      return [...this.plans.values()].filter((plan: Plan) =>
        plan.period.includes(MembershipPeriods.Yearly)
      )
    },
    legacyPlansArray(): Array<Plan> {
      return this.legacyPlans.size > 0 ? Array.from(this.plans.values()) : []
    },
    affiliatePlansArray(): Array<Plan> {
      return this.affiliatePlans.size > 0
        ? Array.from(this.affiliatePlans.values())
        : []
    },
    allPlansArray(): Array<Plan> {
      const allPlans = new Map([
        ...this.plans,
        ...this.legacyPlans,
        ...this.upsellPlans,
        ...this.affiliatePlans,
      ])
      return allPlans.size > 0 ? Array.from(allPlans.values()) : []
    },
    plansGroupedByType(): Record<string, Array<Plan>> {
      return groupBy((p: Plan) => p.tier, this.plansArray)
    },
  },
})
