<template>
  <div class="relative">
    <div
      v-if="props.navigation"
      class="absolute -left-14 top-0 z-10 hidden h-full items-center xl:flex"
      :class="props.leftNavigationWrapperClass"
      @click="slidePrev"
    >
      <div class="cursor-pointer rounded-full bg-slate-200 p-2 shadow">
        <IconArrowLeft class="h-4 w-4" :stroke-width="3" />
      </div>
    </div>
    <div
      v-if="props.navigation"
      class="absolute -right-14 top-0 z-10 hidden h-full items-center xl:flex"
      :class="props.rightNavigationWrapperClass"
      @click="slideNext"
    >
      <div class="cursor-pointer rounded-full bg-slate-200 p-2 shadow">
        <IconArrowLeft class="h-4 w-4 rotate-180 transform" :stroke-width="3" />
      </div>
    </div>

    <div
      :id="props.sliderId"
      ref="swiperElRef"
      class="swiper w-full"
      @keydown="makeCardsFocusable"
      :class="[
        swiperLoaded ? 'opacity-100 duration-200' : 'opacity-0 duration-0',
        'transition-opacity',
        swiperClass,
      ]"
    >
      <div
        ref="swiperWrapperElRef"
        class="swiper-wrapper"
        :class="swiperWrapperClass"
        :aria-roledescription="`${capitalCase(sliderId)} Carousel`"
      >
        <slot />
      </div>

      <div
        v-if="props.pagination"
        class="navigation relative mb-4 flex h-9 w-full items-center justify-center gap-6"
        :class="paginationClass"
      >
        <div v-if="props.pagination" class="swiper-pagination" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import { useTailwind } from '@/plugins/useTailwind'
import { useDebounceFn, useMutationObserver } from '@vueuse/core'
import { capitalCase } from 'change-case'
import Swiper from 'swiper'
import { register } from 'swiper/element/bundle'
import { type SwiperOptions } from 'swiper/types'
import { type PropType, computed, onMounted, ref } from 'vue'

register()

const swiperElRef = ref<HTMLElement | null>(null)
const swiperWrapperElRef = ref<HTMLElement | null>(null)
const swiperInstance = ref<Swiper | null>(null)
const tailwindBreakpoints = useTailwind().breakpoints
const swiperLoaded = ref(false)

const props = defineProps({
  sliderId: {
    type: String,
    required: true,
  },
  enabled: {
    type: Boolean,
    default: true,
  },
  navigation: {
    type: Boolean,
    default: false,
  },
  centered: {
    type: Boolean,
    default: false,
  },
  autoScroll: {
    type: Boolean,
    default: false,
  },
  scrollSpeed: {
    type: Number,
    default: 10000,
  },
  pagination: {
    type: Boolean,
    default: false,
  },
  slidesPerView: {
    type: [String, Number],
    default: 'auto',
  },
  spaceBetween: {
    type: Number,
    default: 16,
  },
  breakpoints: {
    type: Object as PropType<SwiperOptions['breakpoints']>,
    default: () => ({}),
  },
  sideOffsets: {
    type: Object as PropType<Record<'before' | 'after', number>>,
    default: () => ({
      before: 0,
      after: 0,
    }),
  },
  thumbnailsId: {
    type: String,
    default: null,
  },
  swiperClass: {
    type: String,
    default: '',
  },
  swiperWrapperClass: {
    type: String,
    default: '',
  },
  paginationClass: {
    type: String,
    default: '',
  },
  leftNavigationWrapperClass: {
    type: String,
    default: '',
  },
  rightNavigationWrapperClass: {
    type: String,
    default: '',
  },
})

onMounted(() => {
  useMutationObserver(swiperWrapperElRef.value, () => debouncedInitSlider(), {
    childList: true,
  })

  debouncedInitSlider()
})

const debouncedInitSlider = useDebounceFn(() => {
  if (!swiperElRef.value || !swiperWrapperElRef.value) {
    return
  }

  swiperLoaded.value = false

  swiperInstance.value = new Swiper(swiperElRef.value, sliderOptions.value)

  const slotContents = swiperWrapperElRef.value.children

  Array.from(slotContents)
    .filter((el) => !el.classList.contains('swiper-slide'))
    .forEach((el) => {
      el.classList.add('swiper-slide', 'will-change-scroll')
      el.setAttribute('tabindex', '0')

      const imgChildren = el.querySelectorAll('img')
      imgChildren.forEach((img) => {
        img.setAttribute('loading', 'lazy')

        const wrapper = document.createElement('div')
        const preloader = document.createElement('div')
        preloader.classList.add('swiper-lazy-preloader')

        // Wrap the image
        img.replaceWith(wrapper)
        wrapper.appendChild(img)
        wrapper.appendChild(preloader)
      })
    })

  swiperInstance.value.update()
  swiperLoaded.value = true
}, 250)

const makeCardsFocusable = (e: KeyboardEvent) => {
  if (e.key === 'Tab') {
    var focusEl = document?.activeElement?.closest('.swiper-slide')
    if (null != focusEl && !focusEl.classList.contains('swiper-slide-active')) {
      var slideIndex = Array.prototype.indexOf.call(
        focusEl?.parentNode?.children,
        focusEl
      )

      swiperInstance.value?.slideTo(slideIndex)
    }
  }
}

const hideSwiper = () => {
  swiperLoaded.value = false
}

const slideNext = () => {
  swiperInstance.value?.slideNext()
}
const slidePrev = () => {
  swiperInstance.value?.slidePrev()
}

const sliderBreakpoints = computed(() => {
  return {
    0: {
      slidesPerView: props.thumbnailsId ? 1 : props.slidesPerView,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      spaceBetween: 8,
    },
    [tailwindBreakpoints.md]: {
      slidesPerView: props.thumbnailsId ? 1 : 3,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      spaceBetween: 16,
    },
    [tailwindBreakpoints.lg]: {
      slidesPerView: props.thumbnailsId ? 1 : 4,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
    },
    ...(props.breakpoints ?? {}),
  }
})

const sliderOptions = computed((): SwiperOptions => {
  return {
    lazy: true,
    edgeSwipeDetection: true,
    freeMode: {
      enabled: true,
      sticky: true,
    },
    spaceBetween: props.spaceBetween,
    // @ts-ignore
    slidesPerView: props.thumbnailsId ? 1 : props.slidesPerView,
    slidesOffsetBefore: props.sideOffsets.before,
    slidesOffsetAfter: props.sideOffsets.after,
    centeredSlides: props.centered,
    centeredSlidesBounds: props.centered,
    centerInsufficientSlides: props.centered,
    watchSlidesProgress: true,
    watchOverflow: true,
    observer: true,
    observeSlideChildren: true,
    observeParents: true,
    pagination: {
      enabled: props.pagination,
      clickable: true,
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
    },
    keyboard: {
      enabled: props.navigation,
      onlyInViewport: true,
    },
    thumbs: {
      swiper: props.thumbnailsId,
    },
    // @ts-ignore 'auto' string IS a valid option
    breakpoints: sliderBreakpoints.value,
    navigation: {
      enabled: props.navigation,
    },
  }
})

defineExpose({
  debouncedInitSlider,
  hideSwiper,
})
</script>
