<script lang="ts">
import { useRouteMeta } from '@/plugins/useRouteMeta'
import { usePlansStore } from '@/stores/plans'
import { type Benefit } from '@/types'
import { toPeriod } from '@/utils/date-time-utils'
import { capitalCase } from 'change-case'
import { type PropType, defineComponent } from 'vue'

/**
 * A modal to end the user's current trial (which will immediately charge the user).
 */
export default defineComponent({
  props: {
    /**
     * A configured benefit object.
     */
    benefit: {
      type: Object as PropType<Benefit>,
      required: true,
    },
  },
  setup() {
    return {
      plansStore: usePlansStore(),
      routeMeta: useRouteMeta(),
      toPeriod,
    }
  },
  data() {
    return {
      isLoading: false,
      showConfirmation: false,
    }
  },
  computed: {
    benefitTitle(): string {
      return (
        this.benefit.title +
        (['tickets', 'movies'].includes(this.benefit.redemption_category)
          ? ` ${capitalCase(this.benefit.redemption_category)}`
          : '')
      )
    },
  },
  unmounted() {
    this.showConfirmation = false
  },
  methods: {
    async endTrial() {
      try {
        this.isLoading = true

        const success = await this.plansStore.endTrial()

        if (success) {
          // Forcing /me to be loaded again and refresh user store
          window.location.reload()
        }
      } catch (e: any) {
        this.$alertStore.showErrorAlert({
          message: e.error?.message ?? e.message,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>

<template>
  <AppModal
    id="end-trial-modal"
    close-button-class="right-5 top-5 bg-pure-white"
    class="w-[280px]"
  >
    <template #button="{ openModal }">
      <slot :open-modal>
        <AppButton
          class="button-primary mx-auto mt-auto w-full"
          title="End Trial to Claim"
          @click="openModal"
        >
          End Trial to Claim
        </AppButton>
      </slot>
    </template>

    <template #default="{ closeModal }">
      <div
        class="end-trial-modal-content flex h-full w-full flex-col gap-6 bg-black p-5 pb-6 text-pure-white"
      >
        <p class="font-bold">Hi {{ $userStore.user?.first_name }},</p>

        <img
          :src="benefit.image"
          :title="benefit.title"
          class="mx-auto aspect-square h-[220px] w-[220px] rounded-lg object-cover"
        />

        <p>
          <strong>Redeem {{ benefitTitle }}:</strong>
        </p>

        <p>
          In order to use this benefit, you need to end your trial.
          <br />
          You will be charged:
          <strong>
            {{
              $n($userStore.highestPlan?.discount_cost ?? 0, 'currency', {
                minimumFractionDigits: 2,
              })
            }}
          </strong>
        </p>

        <AppButton
          title="Confirm End Trial"
          :is-loading="isLoading"
          class="button-primary mx-auto w-full"
          @click="endTrial"
        >
          Confirm End Trial
        </AppButton>

        <button
          type="button"
          class="mx-auto w-full underline hover:no-underline"
          title="No thanks"
          @click="closeModal"
        >
          No thanks
        </button>
      </div>
    </template>
  </AppModal>
</template>

<style lang="scss" scoped>
.end-trial-modal-content {
  @apply bg-[url('@/assets/images/upgrade-background.svg')] bg-[length:auto_100%] bg-right-top bg-no-repeat;

  min-height: fit-content;
  height: auto;
  // Safari iOS
  -webkit-overflow-scrolling: touch;
}
</style>
