<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.42865 18.3325V9.26188H2.0824V4.91918H6.39177C6.27427 4.78348 6.19441 4.63064 6.15219 4.46063C6.10997 4.29077 6.08886 4.11237 6.08886 3.92543C6.08886 3.30223 6.30698 2.77251 6.74323 2.33626C7.17948 1.90001 7.7092 1.68188 8.3324 1.68188C8.65184 1.68188 8.94781 1.7489 9.22031 1.88293C9.49268 2.01709 9.73622 2.19633 9.95094 2.42063C10.1657 2.18772 10.4093 2.0064 10.6818 1.87668C10.9541 1.74681 11.25 1.68188 11.5695 1.68188C12.1928 1.68188 12.7226 1.90001 13.1589 2.33626C13.5951 2.77251 13.8132 3.30223 13.8132 3.92543C13.8132 4.11029 13.79 4.28605 13.7434 4.45272C13.6969 4.61939 13.6192 4.77487 13.5103 4.91918H17.9157V9.26188H16.5695V18.3325H3.42865ZM11.5695 2.93188C11.288 2.93188 11.052 3.02709 10.8616 3.21751C10.6711 3.40793 10.5759 3.6439 10.5759 3.92543C10.5759 4.20695 10.6711 4.44293 10.8616 4.63334C11.052 4.8239 11.288 4.91918 11.5695 4.91918C11.851 4.91918 12.0871 4.8239 12.2776 4.63334C12.468 4.44293 12.5632 4.20695 12.5632 3.92543C12.5632 3.6439 12.468 3.40793 12.2776 3.21751C12.0871 3.02709 11.851 2.93188 11.5695 2.93188ZM7.33886 3.92543C7.33886 4.20695 7.43406 4.44293 7.62448 4.63334C7.8149 4.8239 8.05087 4.91918 8.3324 4.91918C8.61393 4.91918 8.8499 4.8239 9.04032 4.63334C9.23073 4.44293 9.32594 4.20695 9.32594 3.92543C9.32594 3.6439 9.23073 3.40793 9.04032 3.21751C8.8499 3.02709 8.61393 2.93188 8.3324 2.93188C8.05087 2.93188 7.8149 3.02709 7.62448 3.21751C7.43406 3.40793 7.33886 3.6439 7.33886 3.92543ZM3.3324 6.16897V8.01209H9.37406V6.16897H3.3324ZM9.37406 17.0825V9.26188H4.67844V17.0825H9.37406ZM10.6241 17.0825H15.3197V9.26188H10.6241V17.0825ZM16.6657 8.01209V6.16897H10.6241V8.01209H16.6657Z"
      fill="currentColor"
    />
  </svg>
</template>
