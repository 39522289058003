<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8334 25.8333L27.5 18.3333L15.8334 10.8333V25.8333ZM13.3334 35V31.6667H6.66671C5.75004 31.6667 4.96532 31.3403 4.31254 30.6875C3.65976 30.0347 3.33337 29.25 3.33337 28.3333V8.33333C3.33337 7.41667 3.65976 6.63194 4.31254 5.97917C4.96532 5.32639 5.75004 5 6.66671 5H33.3334C34.25 5 35.0348 5.32639 35.6875 5.97917C36.3403 6.63194 36.6667 7.41667 36.6667 8.33333V28.3333C36.6667 29.25 36.3403 30.0347 35.6875 30.6875C35.0348 31.3403 34.25 31.6667 33.3334 31.6667H26.6667V35H13.3334ZM6.66671 28.3333H33.3334V8.33333H6.66671V28.3333Z"
      fill="currentColor"
    />
  </svg>
</template>
