import fetcher from '@/services/fetcher'
import type {
  InstantWinCountersUpdateEvent,
  InstantWinDraw,
  InstantWinMainPrize,
  InstantWinPrize,
  InstantWinProgress,
  InstantWinTicketCountersUpdateEvent,
  Nullable,
} from '@/types'
import { isWithin48Hours, mapToDateTime } from '@/utils/date-time-utils'
import { EntryTypes, PrizeCategories } from '@/utils/helper-objects'
import { toApiUrl } from '@/utils/url-generation-utils'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'

type InstantWinsStoreState = {
  instantWins: Map<string, InstantWinDraw>
  fetchPromise: Promise<Array<InstantWinDraw>> | null
  ticketStatus: Nullable<
    Record<string, Array<InstantWinTicketCountersUpdateEvent>>
  >
  selectedEntryType: string
}

export const useInstantWinsStore = defineStore('instant-wins', {
  state: (): InstantWinsStoreState => ({
    instantWins: new Map<string, InstantWinDraw>(),
    ticketStatus: null,
    fetchPromise: null,
    selectedEntryType: EntryTypes.Online,
  }),

  actions: {
    async fetchInitialCounters(instantWin: InstantWinDraw) {
      try {
        const params = new URLSearchParams({ code: instantWin.code })

        const { data } = await fetcher.get<{
          success: boolean
          data: InstantWinCountersUpdateEvent
        }>(toApiUrl(`instantwin/get_counters?${params}`))

        if (data.value) {
          this.updateCounters(data.value.data)
        }
      } catch (error: any) {
        Sentry.captureException(error, {
          data: { instantWinCode: instantWin.code },
        })
      }
    },
    async fetchProgress(): Promise<InstantWinProgress[] | undefined> {
      try {
        const { data } = await fetcher.get(toApiUrl('instantwin/get_progress'))

        return data.value as InstantWinProgress[]
      } catch (error: any) {
        Sentry.captureException(error)
      }
    },
    updateCounters(event: InstantWinCountersUpdateEvent) {
      const instantWin = this.instantWins.get(event.code)

      if (instantWin) {
        // Update the total purchased tickets
        instantWin.total_purchased_tickets = event.purchased_entries

        // Update prize counts and ticket statuses
        instantWin.prizes.forEach((prize) => {
          const updatedPrize = event.prizes.find(
            (p) => p.prize_code === prize.prizes_code
          )

          if (updatedPrize) {
            // Update prize won count
            prize.prizes_won_count = updatedPrize.won_count

            // Update ticket statuses if available
            if (updatedPrize.tickets) {
              prize.tickets = updatedPrize.tickets.map((ticket) => ({
                entry_code: ticket.entry_code,
                is_won: ticket.is_won,
              }))
            }
          }
        })

        // Store the updated ticket status for all prizes
        this.ticketStatus = {
          ...this.ticketStatus,
          [event.code]: event.prizes,
        }

        // Update the instantWin in the store
        this.instantWins.set(event.code, instantWin)
      }
    },
    async fetchInstantWins() {
      // return the fetch prizes promise to prevent multiple calls
      if (this.fetchPromise) {
        return this.fetchPromise
      }

      // Fetch all prizes in the prizes folder
      this.fetchPromise = new Promise((resolve, reject) => {
        const instantWins = import.meta.glob('../data/instant-wins/*.json', {
          import: 'default',
        })
        const fetchPromises = []

        for (const path in instantWins) {
          fetchPromises.push(
            instantWins[path]().then((instantWin: any) => {
              const typedInstantWin = mapToDateTime<InstantWinDraw>({
                ...instantWin,
              })

              // If the instant win is within 48 hours of the close date, add the featured category
              // if it is not already in the categories array
              if (
                isWithin48Hours(typedInstantWin.main_prize.close_at) &&
                !typedInstantWin.main_prize.categories.includes(
                  PrizeCategories.Featured
                )
              ) {
                typedInstantWin.main_prize.categories.push(
                  PrizeCategories.Featured
                )
              }

              this.instantWins.set(typedInstantWin.code, typedInstantWin)
            })
          )
        }

        Promise.all(fetchPromises)
          .then(() => {
            resolve(this.instantWinDrawArray)
          })
          .catch((e: any) => {
            reject(e)
          })
          .finally(() => {
            this.fetchPromise = null
          })
      })

      return this.fetchPromise ?? this.instantWinDrawArray
    },
    getUpdatedTicketStatus(
      instantWinPrize: InstantWinPrize,
      instantWin: InstantWinDraw
    ): InstantWinTicketCountersUpdateEvent['tickets'] | undefined {
      return this.ticketStatus?.[instantWin.code]?.find(
        (prize: InstantWinTicketCountersUpdateEvent) =>
          prize.prize_code === instantWinPrize.prizes_code
      )?.tickets
    },
    async getInstantWin(
      instantWinCode: string
    ): Promise<Nullable<InstantWinDraw>> {
      if (this.instantWins.has(instantWinCode)) {
        return this.instantWins.get(instantWinCode) ?? null
      }

      try {
        const { data } = await fetcher.get<InstantWinDraw>(
          `/data/${instantWinCode}.json`
        )

        const instantWin = data.value as InstantWinDraw

        this.instantWins.set(instantWin.code, instantWin)

        // Doing async call to fetchInitialCounters in the component
        // await this.fetchInitialCounters(instantWin)

        return instantWin
      } catch (error: any) {
        Sentry.captureException(error, {
          data: { instantWinCode },
        })

        return null
      }
    },
  },

  getters: {
    instantWinDrawArray({ instantWins }): Array<InstantWinDraw> {
      return Array.from(instantWins.values()).sort(
        (instantWin1: InstantWinDraw, instantWin2: InstantWinDraw) => {
          return (
            instantWin1.main_prize.close_at.toMillis() -
            instantWin2.main_prize.close_at.toMillis()
          )
        }
      )
    },
    futureInstantWinDraws(): Array<InstantWinDraw> {
      return this.instantWinDrawArray.filter(
        (instantWinDraw: InstantWinDraw) =>
          instantWinDraw.main_prize.close_at.diffNow().milliseconds >= 0 &&
          instantWinDraw.main_prize.open_at.diffNow().milliseconds < 0
      )
    },
    pastInstantWinDraws(): Array<InstantWinDraw> {
      return this.instantWinDrawArray
        .filter(
          (instantWinDraw: InstantWinDraw) =>
            instantWinDraw.main_prize.close_at.diffNow().milliseconds < 0
        )
        .reverse()
    },
    futureInstantWinMainPrizes(): Array<InstantWinMainPrize> {
      return this.futureInstantWinDraws.map(
        (instantWinDraw: InstantWinDraw) => instantWinDraw.main_prize
      )
    },
    pastInstantWinMainPrizes(): Array<InstantWinMainPrize> {
      return this.pastInstantWinDraws.map(
        (instantWinDraw: InstantWinDraw) => instantWinDraw.main_prize
      )
    },
  },
})
