import resolveConfig from 'tailwindcss/resolveConfig'
import type { DefaultColors } from 'tailwindcss/types/generated/colors.js'
import { ref, toValue } from 'vue'

// @ts-ignore No typing necessary
import tailwindConfig from '../../tailwind.config.js'

type TailwindConfig = {
  breakpoints: {
    xs: number
    sm: number
    md: number
    lg: number
    xl: number
  }
  colors: DefaultColors // | keyof (typeof tailwindConfig)['theme']['colors']
}

/**
 * Returns an object with the TailwindCSS configuration properties.
 *
 * @returns {TailwindConfig}
 */
export function useTailwind(): TailwindConfig {
  const fullConfig = resolveConfig(tailwindConfig)

  const breakpoints = ref({
    xs: Number(`${fullConfig.theme.screens.xs}`.replace('px', '')),
    sm: Number(`${fullConfig.theme.screens.sm}`.replace('px', '')),
    md: Number(`${fullConfig.theme.screens.md}`.replace('px', '')),
    lg: Number(`${fullConfig.theme.screens.lg}`.replace('px', '')),
    xl: Number(`${fullConfig.theme.screens.xl}`.replace('px', '')),
  })

  return {
    breakpoints: toValue(breakpoints),
    colors: fullConfig.theme.colors,
  }
}
