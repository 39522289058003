<script lang="ts">
type PointerEventWithTarget = PointerEvent & { target?: HTMLElement }

/**
 * A single accordion element component.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/accordion/examples/accordion/
 */
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    summaryClass: {
      type: [String, Array<String>],
    },
    detailsClass: {
      type: [String, Array<String>],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    changeIsOpen($event: PointerEventWithTarget) {
      if (this.disabled) {
        $event.preventDefault()
        $event.target?.removeAttribute('open')
        return
      }

      this.$nextTick(() => {
        this.isOpen = this.$el.hasAttribute('open')
      })
    },
    closeAccordion() {
      if (this.$el.hasAttribute('open')) {
        this.isOpen = false
      }
    },
  },
}
</script>

<template>
  <details :name class="h-fit border-slate-300">
    <summary
      class="flex items-center justify-between gap-2 font-bold"
      :class="[summaryClass, disabled ? '' : 'hover:cursor-pointer']"
      @click="changeIsOpen($event as PointerEventWithTarget)"
    >
      <!-- @slot Used to place the title shown when opened or closed. -->
      <div class="w-full">
        <slot name="title" :props="{ isOpen }">
          <!-- @slot Used to place text when the accordion is open. -->
          <slot v-if="isOpen" name="open-title" />

          <!-- @slot Used to place text when the accordion is closed. -->
          <slot v-if="!isOpen" name="closed-title" />
        </slot>
      </div>

      <!-- @slot Used to place the icon when the accordion is opened or closed. -->
      <slot name="icon" :props="{ isOpen }">
        <AppIcon
          v-if="!disabled"
          :icon="Icons.AccordionArrow"
          class="control-icon"
        />
      </slot>
    </summary>

    <article :aria-expanded="isOpen" :class="detailsClass">
      <div class="pb-4">
        <slot name="text" />
      </div>
    </article>
  </details>
</template>

<style lang="scss" scoped>
details {
  :slotted(svg.control-icon),
  :deep(svg.control-icon) {
    @apply pointer-events-none mr-2 select-none transition-transform duration-500;
  }

  &[open] > summary :slotted(svg.control-icon),
  &[open] > summary :deep(svg.control-icon) {
    @apply -rotate-180;
  }
}

// details:not(:only-of-type):not(:last-of-type) {
details:not(:last-of-type) {
  @apply border-b-[1px];

  // &:first-of-type {
  //   @apply border-t-[1px];
  // }
}

details > summary {
  list-style: none;
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

article {
  @apply mt-2;
}
</style>
