import { i18n } from '@/i18n'
import type { FilterFunctionProperties, MenuItem, Plan } from '@/types'
import type { PlansStore } from 'env'
import { type Ref, ref, toValue, watch } from 'vue'

export function usePlansFilter(
  store: PlansStore
): FilterFunctionProperties<Plan, false> {
  const categories: Ref<Array<MenuItem>> = ref([])
  const items: Ref<Array<Plan>> = ref([])
  const selectedItemCode: Ref<null | string> = ref(null)
  const filteredItems: Ref<Array<Plan>> = ref([])

  watch(selectedItemCode, () => {
    filterItems()
  })

  // TODO: We need to review this is working intentionally as it's working but watching the whole object. So not performant
  watch(store, () => (categories.value = generateCategories()))

  async function loadItems() {
    const plans = await store.fetchPlans()

    items.value = (Array.from(plans.values()) as Array<Plan>).filter(
      (p: Plan) => p.tier !== 'platinum'
    )

    categories.value = generateCategories()

    filterItems()

    return toValue(items)
  }

  function filterItems() {
    filteredItems.value = !selectedItemCode.value
      ? items.value
      : items.value.filter((p: Plan) => {
          return p.tier === selectedItemCode.value
        })
  }

  function setSelectedItem(menuItem: MenuItem) {
    selectedItemCode.value = toValue(menuItem).code
  }

  function generateCategories(): Array<MenuItem> {
    const categoriesMap = new Map()

    for (const plan of store.plansArray) {
      if (plan.tier === 'postal' || plan.tier === 'platinum') {
        continue
      }

      categoriesMap.set(plan.tier, {
        text: plan.name,
        code: plan.tier,
        subText:
          i18n.global.n(
            (store.selectedMembershipPeriod === 'monthly'
              ? plan.cost
              : plan.discount_cost) / 365,
            'currency',
            // @ts-ignore - This needs to be here to display the trailing 0 on the price per day. Not sure why the n() doesn't like it
            { minimumFractionDigits: 2 }
          ) + '/ day',
        class: 'font-bold',
        subTextClass: 'text-sm',
      })
    }

    categoriesMap.set('postal', {
      text: 'Postal',
      code: 'postal',
      subText: 'No purchase',
      class: 'font-bold',
      subTextClass: 'text-sm',
    })

    return Array.from(categoriesMap.values())
  }

  return {
    categories,
    filteredItems,
    items,
    loadItems,
    selectedItemCode,
    setSelectedItem,
  }
}
