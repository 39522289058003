<script lang="ts">
import type { TTheme } from '@/types'
import { Themes } from '@/utils/helper-objects'
import { type PropType, defineComponent } from 'vue'

/**
 * Trustpilot component.
 *
 * The Trustpilot-provided Javascript is **not** reactive and therefore
 * requires a page reload for any of this component prop's to take effect.
 */
export default defineComponent({
  props: {
    size: {
      type: String,
      default: 'sm',
    },
    /**
     * The Trustpilot theme.
     *
     * Note that Trustpilot theme naming is opposite to GLP,
     * a "dark" theme to be used on a dark background.
     */
    theme: {
      type: String as PropType<TTheme>,
      default: Themes.Light,
    },
  },
  setup() {
    return { Themes }
  },
  computed: {
    trustpilotConfig() {
      switch (this.size) {
        default:
        case 'sm':
          return {
            template: '5419b6ffb0d04a076446a9af',
            height: '40px',
            width: '100%',
          }

        case 'md':
          return {
            template: '5406e65db0d04a09e042d5fc',
            height: '20px',
            width: '100%',
          }

        case 'lg':
          return {
            template: '54ad5defc6454f065c28af8b',
            height: '240px',
            width: '100%',
          }
      }
    },
  },
  mounted() {
    const trustbox = this.$refs.trustbox

    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustbox)
    }
  },
})
</script>

<template>
  <div
    ref="trustbox"
    class="trustpilot-widget"
    data-locale="en-GB"
    :data-template-id="trustpilotConfig.template"
    data-businessunit-id="62e8df2a864cf2b2acdb4dbc"
    data-stars="5"
    data-review-languages="en-GB"
    :data-style-height="trustpilotConfig.height"
    :data-style-width="trustpilotConfig.width"
    :data-theme="theme"
  >
    <a
      href="https://uk.trustpilot.com/review/goodlifeplus.co.uk"
      target="_blank"
      rel="noopener"
    >
      Trustpilot
    </a>
  </div>
</template>
