<script lang="ts">
import { type Sponsorship } from '@/types'
import { Genders } from '@/utils/helper-objects'
import { type PropType, defineComponent } from 'vue'

/**
 * A card to display a GLP sponsorship.
 *
 */
export default defineComponent({
  props: {
    /**
     * A configured sponsorship object.
     */
    sponsorship: {
      type: Object as PropType<Sponsorship>,
      required: true,
    },
    displayButton: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return { Genders }
  },
  computed: {
    imageUrl() {
      return `url(${this.sponsorship.image})`
    },
  },
})
</script>

<template>
  <div
    class="card sponsorship-card relative flex flex-col justify-between rounded-md px-5 py-4 text-left text-white"
  >
    <img
      :src="sponsorship.image"
      :alt="`A picture of ${sponsorship.name}`"
      class="image absolute bottom-0 left-1/2 -z-10 w-[283px] -translate-x-1/2"
    />

    <div class="dropshadow">
      <div class="text-center text-xl font-bold">
        {{ sponsorship.name }}
      </div>
      <div class="text-center text-lg font-medium">
        {{ sponsorship.profession }}
      </div>
    </div>

    <div class="flex flex-col gap-4">
      <div
        class="dropshadow h-line-3 text-balance text-center text-xl font-semibold leading-5"
      >
        "{{ sponsorship.quote }}"
      </div>

      <AppYouTubeModal
        v-if="displayButton"
        :id="sponsorship.youtube_id"
        :title="`${sponsorship.name}'s Story'`"
      >
        <template #default="{ openModal }">
          <AppButton
            class="button-secondary mx-auto mb-4 w-full border-white bg-primary/25 text-white transition-all duration-300 hover:bg-primary/75"
            :title="`Watch ${sponsorship.name}'s story`"
            :aria-label="`Watch ${sponsorship.name}'s story`"
            @click="openModal"
          >
            Watch
            {{ sponsorship.gender === Genders.Female ? 'her' : 'his' }}
            story here
          </AppButton>
        </template>
      </AppYouTubeModal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sponsorship-card {
  @apply bg-primary bg-cover bg-no-repeat;

  height: 480px;
  background-image: url('@/assets/images/sponsorships-background.svg');
}
</style>
