<script lang="ts">
import { defineComponent } from 'vue'

/**
 * A Skip Link component for ally.
 */
export default defineComponent({
  props: {
    contentId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const contentElement = document.getElementById(this.contentId)

    // loop over h1/h2 and add to list
    setTimeout(() => {
      contentElement?.focus()
    }, 1000)
  },
  updated() {
    const contentElement = document.getElementById(this.contentId)

    if (!contentElement) {
      console.warn(
        `AppSkipLink: Element not found with ID #${this.contentId}. This will effect accessibility!`
      )
    }
  },
})
</script>

<template>
  <a ref="skip-link" tabindex="0" class="skip-link" :href="`#${contentId}`">
    <slot>Skip to content</slot>
  </a>
</template>

<style lang="scss" scoped>
.skip-link {
  clip: rect(1px, 1px, 1px, 1px);
  display: block;
  height: 1px;
  overflow: hidden;
  padding: 20px;
  position: absolute;
  text-decoration: underline;
  top: 1rem;
  left: 1rem;
  width: 1px;
  z-index: 9999999;
}

.skip-link:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  width: auto;
}
</style>
