<script lang="ts">
import { useTailwind } from '@/plugins/useTailwind'
import type { FilterFunctionProperties, MenuItem, Nullable } from '@/types'
import { type SwiperOptions } from 'swiper/types'
import { defineComponent } from 'vue'

import AppFilterMenuItem from './AppFilterMenuItem.vue'

export default defineComponent({
  components: { AppFilterMenuItem },
  props: {
    id: {
      type: String,
      required: true,
    },
    filter: {
      type: Object as () => FilterFunctionProperties<any>,
      required: true,
    },
    useSlider: {
      type: Boolean,
      default: true,
    },
    slidesPerView: {
      type: Number,
      default: 3.75,
    },
  },
  emits: ['updateFilter'],
  setup() {
    const tailwindBreakpoints = useTailwind().breakpoints

    return {
      tailwindBreakpoints,
    }
  },
  data() {
    return {
      current: 0,
    }
  },
  computed: {
    sliderBreakpoints(): SwiperOptions['breakpoints'] {
      return {
        0: {
          slidesPerView: this.slidesPerView,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          enabled: true,
        },
        [this.tailwindBreakpoints.lg]: {
          slidesPerView: this.filter.categories.value.length,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          enabled: false,
        },
      }
    },
  },
  watch: {
    'filter.categories.value': {
      handler() {
        const selectedMenuItem: Nullable<MenuItem> =
          this.filter.categories.value.find(
            (m: MenuItem) => m.code === this.filter.selectedItemCode.value
          ) ?? null

        if (!selectedMenuItem) {
          return
        }

        this.updateFilter(selectedMenuItem)
      },
      immediate: true,
    },
  },
  methods: {
    updateFilter(menuItem: MenuItem) {
      const categoryIndex: number = this.filter.categories.value.findIndex(
        (m: MenuItem) => m.code === menuItem.code
      )

      this.current = categoryIndex
      this.filter.setSelectedItem(menuItem)
      this.$emit('updateFilter', categoryIndex)
    },
  },
})
</script>

<template>
  <AppSlider
    v-if="useSlider"
    :slider-id="'filter-' + id + '-slider'"
    :pagination="false"
    :navigation="false"
    :breakpoints="sliderBreakpoints"
    :space-between="0"
    class="full-bleed-mobile mb-8 border-b border-slate-200"
    v-bind="$attrs"
  >
    <AppFilterMenuItem
      v-for="(menuItem, key) in filter.categories.value"
      :key
      :menu-item
      :class="[
        current === key
          ? 'currently-selected border-tertiary'
          : 'border-b-transparent',
      ]"
      class="swiper-slide"
      @click="updateFilter(menuItem)"
    />
  </AppSlider>

  <div
    v-if="!useSlider"
    class="flex flex-row justify-evenly border-b border-slate-200"
    v-bind="$attrs"
  >
    <AppFilterMenuItem
      v-for="(menuItem, key) in filter.categories.value"
      :key
      :menu-item
      :class="[current === key ? 'border-tertiary' : 'border-b-transparent']"
      @click="updateFilter(menuItem)"
    />
  </div>
</template>
