<script lang="ts">
import { Themes } from '@/utils/helper-objects'
import { defineComponent } from 'vue'

/**
 * Trustpilot banner.
 */
export default defineComponent({
  setup() {
    return {
      Themes,
    }
  },
})
</script>

<template>
  <AppBanner labelled-by="trustpilot" class="relative py-0">
    <div id="trustpilot" class="sr-only">
      {{ BUSINESS_NAME }} Trustpilot widget with user reviews
    </div>

    <AppTrustpilot
      class="trustpilot-banner mx-auto flex justify-center rounded-lg p-6"
      :theme="Themes.Dark"
    />
  </AppBanner>
</template>

<style lang="scss" scoped>
.trustpilot-banner {
  @apply w-full bg-black bg-cover bg-no-repeat;

  background-image: url('@/assets/images/trustpilot-background.svg');

  :deep(iframe) {
    @apply min-[515px]:!h-[20px];
  }
}
</style>
