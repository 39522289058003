<script lang="ts">
// @ts-nocheck TypeScript doesn't play nicely with these props as per VueRouter docs
import { defineComponent } from 'vue'
import { RouterLink } from 'vue-router'

/**
 * Breadcrumb component.
 */
export default defineComponent({
  inheritAttrs: false,
  props: {
    ...RouterLink.props,
  },
  data() {
    return {
      id: Math.random(),
    }
  },
})
</script>

<template>
  <AppBanner class="breadcrumb dark-section !p-4" :labelled-by="`${id}`">
    <RouterLink v-bind="$props" custom v-slot="{ href, navigate }">
      <a
        v-bind="$attrs"
        :href="href"
        @click="navigate"
        class="flex items-center text-xl font-bold"
        :id="`${id}`"
      >
        <AppIcon :icon="Icons.ArrowLeft" /><span class="divider" /><slot />
      </a>
    </RouterLink>
  </AppBanner>
</template>

<style lang="scss" scoped>
.divider {
  @apply mr-2 h-[1.25rem] w-1 border-r border-r-pure-white;
}
</style>
