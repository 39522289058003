<script lang="ts">
import { type PrizeDetails, usePrizesStore } from '@/stores/prizes'
import type { Prize } from '@/types'
import { isToday, isTomorrow } from '@/utils/date-time-utils'
import { DateTime, Interval } from 'luxon'
import { type PropType, defineComponent } from 'vue'

/**
 * A card to display a prize.
 *
 */
export default defineComponent({
  props: {
    prize: {
      type: Object as PropType<Prize>,
      required: true,
    },
    displayCountdown: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      prizesStore: usePrizesStore(),
    }
  },
  computed: {
    details(): PrizeDetails {
      return this.prizesStore.generateDetails(this.prize)
    },
    header(): Partial<{ text: string; class: string }> {
      if (isToday(this.prize.drawn_at)) {
        return {
          text: 'Ends Today',
          class: 'bg-pastel-blue',
        }
      }

      if (isTomorrow(this.prize.drawn_at)) {
        return {
          text: 'Ends Tomorrow',
          class: 'bg-slate-800',
        }
      }

      return {
        class: 'bg-primary',
      }
    },
    to() {
      if (this.prize.campaign_code) {
        return {
          name: 'campaign',
          params: { campaignCode: this.prize.campaign_code },
        }
      }

      if (
        Interval.fromDateTimes(
          this.prize.close_at,
          DateTime.now().endOf('day')
        ).contains(DateTime.now())
      ) {
        return { name: 'live-draw', params: { prizeCode: this.prize.code } }
      }

      return {
        name: 'prize',
        params: { prizeCode: this.prize.code },
      }
    },
  },
})
</script>

<template>
  <RouterLink
    :to="to"
    class="card prize-card relative flex h-auto flex-col bg-pure-white"
  >
    <div
      v-if="header.text"
      class="w-full rounded-t-lg border border-b-0 border-slate-300 px-6 py-3 text-center text-xl font-bold leading-none text-white"
      :class="header.class"
    >
      {{ header.text }}
    </div>

    <template v-if="!header.text">
      <AppCountdown
        v-if="displayCountdown"
        :datetime="prize.drawn_at"
        class="rounded-t-lg border border-b-0 border-slate-300"
      />
      <AppDateTime
        v-if="!displayCountdown"
        :datetime="prize.drawn_at"
        :end-datetime="prize.drawn_at.plus({ minute: 30 })"
        class="rounded-t-lg"
      />
    </template>

    <img
      :src="prize.card_image.src"
      :alt="prize.card_image.alt"
      class="border-x border-slate-300"
      width="340"
      height="240"
    />

    <div
      class="flex flex-grow flex-col justify-between gap-2 rounded-b-lg border border-slate-300 px-5 py-6"
    >
      <span>
        <p class="text-lg font-extrabold">
          {{ prize.title }}
        </p>

        <p class="line-clamp-3" :title="details.description">
          {{ details.description }}
        </p>
      </span>

      <div class="mt-3 flex items-center justify-between">
        <div class="text-sm font-bold">Entered automatically:</div>
        <div class="flex gap-2">
          <AppBadge
            size="md"
            class="bg-primary text-center uppercase text-white"
          >
            All
          </AppBadge>
        </div>
      </div>

      <template v-if="$userStore.isLoggedOut">
        <slot name="button" :prize>
          <AppButton
            :route="to"
            class="button-secondary button-secondary-light not:last:mb-2 mt-4 min-w-full font-semibold"
            title="Enter for free"
          >
            Enter for free
          </AppButton>
        </slot>
      </template>
    </div>
  </RouterLink>
</template>
