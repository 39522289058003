import type { Nullable } from '@/types'
import { defineStore } from 'pinia'

type AlertStoreState = {
  show: boolean
  title: Nullable<string>
  message: Nullable<string>
  buttonText: Nullable<string>
  callbackFn: Nullable<() => any>
  type: 'success' | 'error'
}

type AlertDetails = {
  message?: string
  title?: string
  buttonText?: string
  callbackFn?: () => any
}

export const useAlertStore = defineStore('alert', {
  state: (): AlertStoreState => ({
    show: false,
    title: null,
    message: null,
    buttonText: null,
    callbackFn: null,
    type: 'success',
  }),

  actions: {
    /**
     * Shows an alert dialog.
     *
     * @param {'success' | 'error'} type
     * @param {undefined | AlertDetails} alertDetails
     */
    showAlert(
      type: 'success' | 'error',
      alertDetails: undefined | AlertDetails
    ) {
      this.type = type

      this.title =
        alertDetails?.title ??
        (type === 'error' ? 'Something went wrong' : null)

      this.message =
        alertDetails?.message ??
        (type === 'error'
          ? 'Error occurred. We are working hard to fix it!'
          : 'Success!')

      this.buttonText = alertDetails?.buttonText ?? 'Go Back'
      this.callbackFn = alertDetails?.callbackFn ?? null

      this.show = true
    },
    /**
     * Shows an error alert dialog.
     *
     * @param {AlertDetails} errorDetails
     */
    showErrorAlert(errorDetails?: AlertDetails) {
      this.showAlert('error', {
        title: errorDetails?.title,
        message: errorDetails?.message,
        buttonText: errorDetails?.buttonText,
        callbackFn: errorDetails?.callbackFn,
      })
    },
    /**
     * Shows a success alert dialog.
     *
     * @param {AlertDetails} successDetails
     */
    showSuccessAlert(successDetails?: AlertDetails) {
      this.showAlert('success', {
        title: successDetails?.title,
        message: successDetails?.message,
        buttonText: successDetails?.buttonText,
        callbackFn: successDetails?.callbackFn,
      })
    },
    /**
     * Resets the alert state and hides the modal.
     */
    hideAlert() {
      this.show = false
      this.title = null
      this.message = null
      this.buttonText = null
      this.callbackFn = null
    },
  },
})
