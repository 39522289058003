import { useFeatureFlagsStore } from '@/stores/feature-flags'

/**
 * Fetches feature flags from the API if they are not already initialized
 * This is important for when the cache is cleared during login/logout
 */
export default async function fetchFeatureFlags() {
  const featureFlagsStore = useFeatureFlagsStore()

  // If feature flags are not initialized, fetch them
  if (!featureFlagsStore.isInitialized) {
    await featureFlagsStore.fetchFeatureFlags()
  }
}
