import { isEmpty } from '@/utils/utils'
import type { RouteLocation } from 'vue-router'

/**
 * Sets the page title.
 *
 * @param {RouteLocation} to
 * @param {RouteLocation} from
 */
export default function setPageTitle(to: RouteLocation, from: RouteLocation) {
  if (to.meta.title && to.name !== from.name) {
    const routeTitle = Object.values(to.matched)
      .map(
        (route) =>
          `${typeof route.meta.title === 'function' ? route.meta.title(to) : (route.meta.title ?? '')}`
      )
      .filter((title: string) => !isEmpty(title))
      .join(' > ')

    document.title = `${routeTitle} - ${import.meta.env.GLP_APP_TITLE}`
  }
}
