import { useUserStore } from '@/stores/user'
import * as Sentry from '@sentry/vue'
import { useCookies } from '@vueuse/integrations/useCookies.mjs'

/**
 * If the user is logged in, their details are fetched and added to the user store.
 */
export default async function fetchUserDetailsGuard() {
  const userStore = useUserStore()
  const cookies = useCookies(['authToken'], {
    autoUpdateDependencies: true,
  })

  if (cookies.get('authToken')) {
    try {
      await userStore.fetchDetails()
    } catch (e: any) {
      Sentry.captureException(e, {
        data: {
          token: cookies.get('token'),
        },
      })
    }
  }
}
