<script lang="ts">
import { useTailwind } from '@/plugins/useTailwind'
import { defineComponent } from 'vue'

/**
 * AppPicture
 */
export default defineComponent({
  props: {
    mobile: {
      type: String,
    },
    tablet: {
      type: String,
    },
    desktop: {
      type: String,
    },
    alt: {
      type: String,
      require: true,
    },
    imgClass: {
      type: String,
      default: '',
    },
  },
  setup() {
    const tailwind = useTailwind()

    return {
      tailwindBreakpoints: tailwind.breakpoints,
    }
  },
})
</script>

<template>
  <picture class="h-full w-full">
    <source
      v-if="tablet"
      :media="`(min-width: ${tailwindBreakpoints.md}px, max-width: ${tailwindBreakpoints.lg - 1}px)`"
      :srcset="tablet"
    />
    <source
      v-if="desktop"
      :media="`(min-width: ${tailwindBreakpoints.lg}px)`"
      :srcset="desktop"
    />

    <img
      :src="mobile"
      :alt
      loading="lazy"
      class="h-full w-full object-cover object-center"
      :class="imgClass"
      v-bind="$attrs"
    />
  </picture>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  img {
    @apply aspect-square;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  img {
    @apply aspect-5/4;
  }
}

@media screen and (min-width: 1024px) {
  img {
    @apply aspect-16/9;
  }
}
</style>
