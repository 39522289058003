<script lang="ts">
import { defineComponent } from 'vue'

/**
 * A progress indicator.
 */
export default defineComponent({
  props: {
    /**
     * The name of the progress bar.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Maximum value to progress towards.
     */
    max: {
      type: Number,
      required: true,
    },
    /**
     * @model
     */
    value: {
      type: Number,
      required: true,
    },
    progressClass: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showProgressInline: {
      type: Boolean,
      default: false,
    },
    showLoadingInline: {
      type: Boolean,
      default: false,
    },
    progressValueClass: {
      type: String,
      default: '',
    },
    loadingClass: {
      type: String,
      default: '',
    },
    inlineLoadingIndicatorClass: {
      type: String,
      default: 'border-primary',
    },
  },
})
</script>

<template>
  <div class="relative">
    <progress
      :id="name"
      :name
      :value
      :max
      class="h-2 w-full"
      :class="progressClass"
    />
    <div
      v-if="showLoadingInline && isLoading"
      class="absolute top-0 flex w-full justify-center"
      :class="loadingClass"
    >
      <span
        class="inline-block h-3 w-3 animate-spin rounded-full border-2 border-t-transparent align-middle"
        :class="inlineLoadingIndicatorClass"
      />
    </div>
    <div
      v-if="showProgressInline && !isLoading"
      class="absolute top-0 flex w-full items-center justify-center text-xs font-bold text-black"
      :class="progressValueClass"
    >
      {{ value }}%
    </div>
  </div>
</template>

<style lang="scss" scoped>
// https://css-tricks.com/html5-progress-element/
progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Get rid of default border in Firefox. */
  border: none;

  &::-webkit-progress-bar {
    @apply rounded-100 bg-slate-300;
  }

  &::-webkit-progress-value {
    @apply rounded-100 bg-tertiary;
  }
}
</style>
