<script lang="ts">
import { defineComponent } from 'vue'

/**
 * A range slider.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/range
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/slider_role
 */
export default defineComponent({
  props: {
    /**
     * The name of the input range selector.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * @model
     */
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value: string) {
        this.$emit(
          'update:modelValue',
          Math.min(Math.max(this.min, Number.parseInt(value)), this.max)
        )
      },
    },
  },
  mounted() {
    // Forces the range selector to set initial position
    this.$forceUpdate()
  },
})
</script>

<template>
  <div class="flex w-full items-center justify-center gap-3">
    <button
      @click="value--"
      type="button"
      class="flex h-[32px] w-[32px] items-center justify-center rounded bg-slate-600/50 p-3 text-2xl"
    >
      -
    </button>

    <label
      class="flex h-[32px] w-full justify-center rounded bg-slate-600/50 p-3"
    >
      <span class="sr-only">A slider element.</span>
      <input
        :id="name"
        v-model="value"
        type="range"
        :name
        :min
        :max
        class="w-full"
      />
    </label>

    <button
      type="button"
      @click="value++"
      class="flex h-[32px] w-[32px] items-center justify-center rounded bg-slate-600/50 p-3 text-2xl"
    >
      +
    </button>
  </div>
</template>

<style lang="scss" scoped>
// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/
input[type='range'] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  margin-top: -10px;
  border: 4px solid;

  @apply cursor-pointer rounded-full border-black bg-tertiary p-2;
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  height: 24px;
  width: 24px;
  margin-top: -10px;
  border: 4px solid;

  @apply cursor-pointer rounded-full border-black bg-tertiary p-2;
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  height: 24px;
  width: 24px;
  margin-top: -10px;
  border: 4px solid;

  @apply cursor-pointer rounded-full border-black bg-tertiary p-2;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #fff;
}

input[type='range']::-moz-range-track {
  background: #fff;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: #fff;
}

input[type='range']::-ms-fill-upper {
  background: #fff;
}
</style>
