import type { FormDatum, InputValidator } from '@/types'
import {
  type LaravelValidationErrors,
  type ValidationErrors,
  ValidationException,
} from '@/validation-exception'
import type { VGSErrors } from '@vgs/collect-js'
import { capitalCase, sentenceCase } from 'change-case'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { postcodeValidator } from 'postcode-validator'

import { arrayWrap, isEmpty } from './utils'

/**
 * Returns form data determined by the input name.
 *
 * @param {string} inputName
 * @param {Record<string | number | symbol, FormDatum>} data
 * @returns {FormDatum | null}
 */
const findInputData = (
  inputName: string | number | symbol,
  data: Record<string | number | symbol, FormDatum>
): FormDatum | null => {
  for (const [name, inputData] of Object.entries(data)) {
    if (name === inputName) {
      return inputData
    }
  }

  return null
}

/**
 * Extracts an object of errors returned from a Laravel endpoint.
 *
 * @param {string} field
 * @param {LaravelValidationErrors} errors
 * @returns {ValidationErrors}
 */
export const extractLaravelErrors = (
  field: string,
  errors: LaravelValidationErrors
): [] | ValidationErrors => {
  return (
    Object.fromEntries(
      Object.entries(errors).filter(([fieldName]) => {
        return fieldName === field
      })
    )[field] ?? []
  )
}

/**
 * Instantiates a ValidationException from a VGSErrors object.
 *
 * @param {VGSErrors} errors
 * @returns {ValidationException}
 */
export const createVgsException = (errors: VGSErrors): ValidationException => {
  const vgsErrors = Object.fromEntries(
    Object.entries(errors).map(([fieldName, error]) => {
      return [
        fieldName,
        error.errorMessages.map((e: string) => {
          return `${capitalCase(fieldName)} ${e}`
        }),
      ]
    })
  )

  return new ValidationException({
    message: 'An error has occured',
    errors: vgsErrors,
  })
}

/**
 * Maps a record of strings to a record of FormDatum objects.
 *
 * @param {Record<string, string>} data
 * @returns {Record<string, FormDatum>}
 */
const mapDataToFormData = (
  data: Record<string, string>
): Record<string, FormDatum> => {
  return Object.fromEntries(
    Object.entries(data).map(([name, value]) => {
      return [name, { name, value }]
    })
  )
}

/**
 * Runs a validator or array of validators on some form data.
 *
 * @param  {TData} data
 * @param {Record<keyof TData, InputValidator> | Record<keyof TData, Array<InputValidator>>} validators
 * @returns {null | ValidationException}
 */
export const validate = <TData extends Record<string | number | symbol, any>>(
  data: TData,
  validators:
    | Record<keyof TData, InputValidator>
    | Record<keyof TData, Array<InputValidator>>
): null | ValidationException => {
  let generalErrorMessage = null
  let isValid = true

  const inputErrors: Record<string, Array<string>> = {}

  for (const [inputName, inputValidators] of Object.entries(validators)) {
    const validatorsArray: Array<InputValidator> = arrayWrap(inputValidators)

    for (const validator of validatorsArray) {
      const formData = findInputData(inputName, data)

      try {
        const inputValidity = validator(
          {
            name: inputName,
            value: formData,
          },
          mapDataToFormData(data)
        )

        if (inputValidity !== true) {
          if (!Array.isArray(inputErrors[inputName])) {
            inputErrors[inputName] = []
          }

          inputErrors[inputName].push(inputValidity)
        }

        isValid = isValid && inputValidity === true
      } catch (e) {
        generalErrorMessage = 'An error has occurred, please try again.'
      }
    }
  }

  if (!isValid) {
    return new ValidationException({
      message: generalErrorMessage,
      errors: inputErrors,
    })
  }

  return null
}

/**
 * Validates if the given phone number is in E.164 format for the GB region.
 *
 * @param {FormDatum} data - The form data containing the phone number to validate.
 * @returns {string | true} - Returns true if the phone number is valid, otherwise returns an error message.
 */
export const isE164PhoneNumber: InputValidator = (
  data: FormDatum
): string | true => {
  const phoneUtil = new PhoneNumberUtil()

  try {
    const phoneNumber = phoneUtil.parse(`${data.value}`, 'GB')
    const isValidNumberForRegion: boolean = phoneUtil.isValidNumberForRegion(
      phoneNumber,
      'GB'
    )

    if (!isValidNumberForRegion) {
      return 'Invalid phone number'
    }

    return true
  } catch (e: any) {
    return `${sentenceCase(data.name.replace('_', ' '))} is an invalid phone number. Format must be in <a href="https://en.wikipedia.org/wiki/E.164" target="_blank" class="underline hover:no-underline font-bold">E.164 format</a>`
  }
}

/**
 * Returns an error message string if the form data is empty, else `true`.
 *
 * @param {FormDatum} data
 * @returns {string | true}
 */
export const isRequired: InputValidator = (data: FormDatum): string | true => {
  return isEmpty(data.value)
    ? `${sentenceCase(data.name.replace('_', ' '))} is required`
    : true
}

/**
 * Returns an error message string if the password is invalid, else `true`.
 *
 * @param {FormDatum} data
 * @returns {string | true}
 */
export const isPassword: InputValidator = (data: FormDatum): string | true => {
  // min 8 chars, one uppercase, one lowercase, one number, one symbol
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/

  return !passwordRegex.test(`${data.value}`)
    ? 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one symbol'
    : true
}

/**
 * Returns an error message string if the form data is not a valid email, else `true`.
 *
 * @param {FormDatum} data
 * @returns {string | true}
 */
export const isEmail: InputValidator = (data: FormDatum): string | true => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  return !emailRegex.test(`${data.value}`)
    ? `${sentenceCase(data.name.replace('_', ' '))} is an invalid email`
    : true
}

/**
 * Returns an error message string if the for mdata is not a valid GB postcode, else `true`.
 *
 * @param {FormDatum} data
 * @returns {string | true}
 */
export const isPostcode: InputValidator = (data: FormDatum): string | true => {
  return isEmpty(data.value) || !postcodeValidator(data.value as string, 'GB')
    ? `${sentenceCase(data.name.replace('_', ' '))} is an invalid GB postcode`
    : true
}

/**
 * Returns a function checking that the passed character is present in the form data.
 *
 * @param {string} character
 * @returns {InputValidator}
 */
export const requiredCharacter = (character: string): InputValidator => {
  return function requiredChar(data: FormDatum) {
    return isEmpty(data.value) || !`${data.value}`.includes(character)
      ? `${sentenceCase(data.name.replace('_', ' '))} must contain an '${character}' symbol`
      : true
  }
}

/**
 * Confirms that both the password and confirm password fields match.
 *
 * @param {FormDatum} formDatum
 * @param {Record<string, FormDatum>} formData
 * @returns {string | true}
 */
export const confirmPassword = (
  formDatum: FormDatum,
  formData: Record<string, FormDatum>
): string | true => {
  return formDatum.value === formData.password.value
    ? true
    : 'New passwords must match'
}

/**
 * Generates a new object with keyed by the input name with values
 * of an array of validators added determined by properties on the input
 * element.
 * If the input element is :
 * - Required => adds the `isRequired` validator.
 * - Type is `email` => adds the `isEmail` validator.
 * - Type is `tel` => adds the `isE164PhoneNumber` validator.
 *
 * @param {Array<HTMLInputElement>} inputs
 * @param {Record<string, InputValidator> | Record<string, Array<InputValidator>>} validators
 * @returns {null | Record<string, InputValidator> | Record<string, Array<InputValidator>}
 */
export const generateValidatorsByInputType = (
  inputs: Array<HTMLInputElement>,
  validators:
    | Record<string, InputValidator>
    | Record<string, Array<InputValidator>>
):
  | null
  | Record<string, InputValidator>
  | Record<string, Array<InputValidator>> => {
  inputs.forEach((input: HTMLInputElement) => {
    let inputValidators = validators[input.name] ?? []

    // Check if the input is an email and if the isEmail validator exists
    // If not, add the isEmail validator to the inputValidators array
    const isEmailValidatorExists = Array.isArray(inputValidators)
      ? inputValidators.includes(isEmail)
      : inputValidators === isEmail

    if (input.type === 'email' && !isEmailValidatorExists) {
      Array.isArray(inputValidators)
        ? inputValidators.push(isEmail)
        : (inputValidators = [inputValidators, isEmail])

      validators[input.name] = inputValidators
    }

    // Check if the input is a phone number and if the isE164PhoneNumber validator exists
    // If not, add the isE164PhoneNumber validator to the inputValidators array
    const isPhoneValidatorExists = Array.isArray(inputValidators)
      ? inputValidators.includes(isE164PhoneNumber)
      : inputValidators === isE164PhoneNumber

    if (input.type === 'tel' && !isPhoneValidatorExists) {
      Array.isArray(inputValidators)
        ? inputValidators.push(isE164PhoneNumber)
        : (inputValidators = [inputValidators, isE164PhoneNumber])

      validators[input.name] = inputValidators
    }

    if (!validators[input.name]?.length) {
      // Check if the input is required and if the isRequired validator exists
      // If not, add the isRequired validator to the inputValidators array
      const isRequiredValidatorExists = Array.isArray(inputValidators)
        ? inputValidators.includes(isRequired)
        : inputValidators === isRequired

      if (input.required && !isRequiredValidatorExists) {
        Array.isArray(inputValidators)
          ? inputValidators.push(isRequired)
          : (inputValidators = [inputValidators, isRequired])

        validators[input.name] = inputValidators
      }
    }
  })

  return Object.values(validators).length ? validators : null
}
