<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6.94107 18.8379C5.58135 18.8379 4.43447 18.3711 3.50044 17.4373C2.56655 16.5036 2.09961 15.357 2.09961 13.9975C2.09961 13.4297 2.196 12.8777 2.38878 12.3413C2.58169 11.8049 2.85607 11.3207 3.2119 10.8886L6.13503 7.38502L4.76544 4.63398C4.57947 4.26204 4.59378 3.90294 4.80836 3.55669C5.02308 3.2103 5.33725 3.03711 5.75086 3.03711H14.2373C14.6509 3.03711 14.9651 3.2103 15.1798 3.55669C15.3945 3.90294 15.4089 4.26204 15.2229 4.63398L13.8534 7.38502L16.7763 10.8886C17.141 11.3227 17.4195 11.8081 17.6119 12.3446C17.8043 12.8813 17.9004 13.4328 17.9004 13.9992C17.9004 15.3554 17.4297 16.5009 16.4884 17.4356C15.547 18.3705 14.4038 18.8379 13.059 18.8379H6.94107ZM10 14.424C9.50239 14.424 9.08058 14.251 8.73461 13.905C8.38864 13.5589 8.21565 13.1371 8.21565 12.6396C8.21565 12.142 8.38864 11.7202 8.73461 11.3742C9.08058 11.0281 9.50239 10.855 10 10.855C10.4977 10.855 10.9195 11.0281 11.2654 11.3742C11.6114 11.7202 11.7844 12.142 11.7844 12.6396C11.7844 13.1371 11.6114 13.5589 11.2654 13.905C10.9195 14.251 10.4977 14.424 10 14.424ZM8.13857 6.44107H11.8732L12.4711 5.23377H7.52899L8.13857 6.44107ZM6.94107 16.6413H13.059C13.798 16.6413 14.4236 16.3854 14.9357 15.8736C15.4477 15.3619 15.7038 14.7368 15.7038 13.9982C15.7038 13.6872 15.6507 13.3872 15.5444 13.0984C15.4382 12.8093 15.2847 12.5477 15.0842 12.3134L12.0336 8.63773H7.97565L4.91586 12.2925C4.7153 12.5347 4.5619 12.8027 4.45565 13.0963C4.3494 13.3899 4.29628 13.69 4.29628 13.9965C4.29628 14.7355 4.55232 15.3611 5.0644 15.8732C5.57648 16.3852 6.20204 16.6413 6.94107 16.6413Z"
      fill="#0F172A"
    />
  </svg>
</template>
