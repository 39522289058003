<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.69225 17.5V16H8.25V12.573C7.40133 12.4025 6.65133 12.0455 6 11.502C5.34867 10.9583 4.88583 10.2807 4.6115 9.46925C3.45767 9.33208 2.484 8.84233 1.6905 8C0.896833 7.15767 0.5 6.15767 0.5 5V4C0.5 3.591 0.64775 3.23875 0.94325 2.94325C1.23875 2.64775 1.591 2.5 2 2.5H4.327V0.5H13.673V2.5H16C16.409 2.5 16.7613 2.64775 17.0568 2.94325C17.3523 3.23875 17.5 3.591 17.5 4V5C17.5 6.15767 17.1032 7.15767 16.3095 8C15.516 8.84233 14.5423 9.33208 13.3885 9.46925C13.1142 10.2807 12.6513 10.9583 12 11.502C11.3487 12.0455 10.5987 12.4025 9.75 12.573V16H13.3077V17.5H4.69225ZM4.327 7.85775V4H2V5C2 5.6975 2.21858 6.31 2.65575 6.8375C3.09292 7.365 3.65 7.70508 4.327 7.85775ZM9 11.1348C9.87817 11.1348 10.6233 10.8286 11.2355 10.2163C11.8477 9.60408 12.1538 8.85892 12.1538 7.98075V2H5.84625V7.98075C5.84625 8.85892 6.15233 9.60408 6.7645 10.2163C7.37667 10.8286 8.12183 11.1348 9 11.1348ZM13.673 7.85775C14.35 7.70508 14.9071 7.365 15.3443 6.8375C15.7814 6.31 16 5.6975 16 5V4H13.673V7.85775Z"
      fill="currentColor"
    />
  </svg>
</template>
