<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00033 7.8335L2.33366 3.66683V12.0002H9.83366V13.6668H2.33366C1.87533 13.6668 1.48296 13.5036 1.15658 13.1772C0.830187 12.8509 0.666992 12.4585 0.666992 12.0002V2.00016C0.666992 1.54183 0.830187 1.14947 1.15658 0.82308C1.48296 0.496691 1.87533 0.333496 2.33366 0.333496H15.667C16.1253 0.333496 16.5177 0.496691 16.8441 0.82308C17.1705 1.14947 17.3337 1.54183 17.3337 2.00016V7.8335H15.667V3.66683L9.00033 7.8335ZM9.00033 6.16683L15.667 2.00016H2.33366L9.00033 6.16683ZM14.8337 16.1668L13.667 15.0002L14.9795 13.6668H11.5003V12.0002H14.9795L13.6462 10.6668L14.8337 9.50016L18.167 12.8335L14.8337 16.1668ZM2.33366 3.66683V12.8335V7.8335V7.896V2.00016V3.66683Z"
      fill="currentColor"
    />
  </svg>
</template>
