<script lang="ts">
import EnvBanner from '@/components/EnvBanner.vue'
import ImpersonateBanner from '@/components/ImpersonateBanner.vue'
import AppFooter from '@/components/common/AppFooter.vue'
import AppHeader from '@/components/common/AppHeader.vue'
import AppAlert from '@/components/modals/AppAlert.vue'
import { useCookiebot } from '@ambitiondev/vue-cookiebot'
import { Analytics } from '@vercel/analytics/vue'
import { SpeedInsights } from '@vercel/speed-insights/vue'
import { usePreferredReducedMotion } from '@vueuse/core'
import { defineComponent, watch } from 'vue'

import { usePageProgressStore } from './stores/page-progress'

// @see https://www.w3.org/WAI/ARIA/apg/practices/landmark-regions/

// @see https://vercel.com/docs/analytics
// @see https://vercel.com/docs/speed-insights

export default defineComponent({
  components: {
    Analytics,
    AppAlert,
    AppFooter,
    AppHeader,
    EnvBanner,
    ImpersonateBanner,
    SpeedInsights,
  },
  setup() {
    const { consentBanner } = useCookiebot()

    return {
      prefersReduceMotion: usePreferredReducedMotion(),
      consentBanner,
    }
  },
  data() {
    return {
      show: false,
    }
  },
  async mounted() {
    const pageProgress = usePageProgressStore()

    if (!this.prefersReduceMotion) {
      this.show = true
    } else {
      watch(
        () => pageProgress.isLoading,
        (isLoading) => {
          this.show = false
          setTimeout(() => {
            this.show = !isLoading
          }, 200)
        },
        {
          immediate: true,
        }
      )
    }

    await this.consentBanner()
  },
})
</script>

<template>
  <SpeedInsights />
  <Analytics />
  <EnvBanner v-if="APP_ENV !== 'production'" />
  <ImpersonateBanner v-if="$userStore.isImpersonating" />

  <!-- <AppSkipLink content-id="member-zone" /> -->

  <AppHeader />

  <RouterView v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <main v-if="show">
        <component :is="Component" />
      </main>
    </transition>
  </RouterView>

  <transition name="fade" mode="out-in">
    <AppFooter v-if="show" />
  </transition>

  <AppAlert />
</template>

<style lang="scss" scoped>
@media (prefers-reduced-motion) {
  .fade-enter-active,
  .fade-leave-active {
    transition: none;
  }
}

// main will-change

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
