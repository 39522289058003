<script lang="ts">
import { Themes } from '@/utils/helper-objects'
import { toValue } from '@/utils/utils'
import { defineComponent } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

/**
 * Displays a navivation header with menu links.
 *
 * @see https://www.makethingsaccessible.com/guides/site-navigation-is-not-an-aria-menu/
 */
export default defineComponent({
  setup() {
    return { Themes }
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    routeOptions() {
      return this.$router.currentRoute.value.meta.nav?.header
    },
    navRoutes() {
      let routes = this.$router.options.routes.filter(
        (r) => toValue(r.meta?.nav?.header?.display ?? false) || r.children
      )

      routes = routes.reduce(
        (routes: Array<RouteRecordRaw>, route: RouteRecordRaw) => {
          if (route.children) {
            routes = routes.concat(
              route.children.filter((route: RouteRecordRaw) =>
                toValue(route.meta?.nav?.header?.display ?? false)
              )
            )
          }

          if (toValue(route.meta?.nav?.header?.display ?? false)) {
            routes.push(route)
          }

          return routes
        },
        []
      )

      return routes
    },
    navMobileRoutes() {
      let routes = this.$router.options.routes.filter(
        (r) =>
          toValue(r.meta?.nav?.header?.displayMobile ?? false) || r.children
      )

      routes = routes.reduce(
        (routes: Array<RouteRecordRaw>, route: RouteRecordRaw) => {
          if (route.children) {
            routes = routes.concat(
              route.children.filter((route: RouteRecordRaw) =>
                toValue(route.meta?.nav?.header?.displayMobile ?? false)
              )
            )
          }

          if (toValue(route.meta?.nav?.header?.displayMobile ?? false)) {
            routes.push(route)
          }

          return routes
        },
        []
      )

      return routes
    },
  },
  methods: {
    closeMenu() {
      this.open = false
    },
  },
})
</script>

<template>
  <header
    ref="header"
    class="sticky top-0 w-full border-b border-slate-300"
    :class="routeOptions?.theme ?? Themes.Light"
    aria-labelledby="business"
  >
    <nav aria-labelledby="site-nav-label">
      <div class="mx-auto h-[64px] max-w-7xl px-4 xl:px-0">
        <div class="relative flex h-full items-center justify-between">
          <!-- <div
                        class="desktop-nav flex flex-1 items-center justify-center md:items-stretch md:justify-start"
                    > -->

          <div class="flex items-center">
            <RouterLink :to="{ name: 'home' }" class="!border-0">
              <span id="business" class="sr-only">{{ BUSINESS_NAME }}</span>
              <img
                src="@/assets/logo.svg"
                :title="`${BUSINESS_NAME} Logo`"
                :alt="`${BUSINESS_NAME} Logo`"
                height="24"
                width="180"
                class="hidden md:block"
              />
              <img
                src="@/assets/logo-sm.svg"
                :title="`${BUSINESS_NAME} Logo`"
                :alt="`${BUSINESS_NAME} Logo`"
                height="24"
                width="57"
                class="md:hidden"
              />
            </RouterLink>
          </div>

          <div
            v-if="routeOptions?.displayLinks ?? true"
            class="hidden flex-grow lg:block"
          >
            <span id="site-nav-label" class="sr-only" hidden
              >{{ BUSINESS_NAME }} Website Navigation</span
            >
            <ol
              role="list"
              class="flex h-full items-center justify-center gap-6 space-x-4"
            >
              <li
                v-for="(route, key) in navRoutes"
                :key
                class="flex items-center"
              >
                <RouterLink
                  :to="route.path"
                  class="px-2 py-3 hover:bg-primary/10"
                  :tabindex="0"
                >
                  {{
                    $t(
                      'header.' +
                        route.meta?.nav?.header?.text
                          ?.toLowerCase()
                          .replace(/ /g, '_')
                    )
                  }}
                </RouterLink>
              </li>
            </ol>
          </div>
          <!-- </div> -->

          <div class="flex items-center gap-6">
            <!-- @click.away="onClickAway($event)" -->
            <div
              v-if="routeOptions?.displayLinks ?? true"
              class="relative hidden lg:block"
            >
              <RouterLink
                v-if="$userStore.isLoggedIn"
                :to="{ name: 'logout' }"
                @click="closeMenu"
                class="px-2 py-3 hover:bg-primary/10"
              >
                {{ $t('label.logout') }}
              </RouterLink>

              <AppLoginDrawer
                v-if="
                  $userStore.isLoggedOut &&
                  $router.currentRoute.value.name !== 'login'
                "
              >
                <template #button="{ openDrawer }">
                  <button
                    type="button"
                    class="w-full px-2 py-3 hover:bg-primary/10"
                    @click="openDrawer"
                  >
                    {{ $t('label.login') }}
                  </button>
                </template>
              </AppLoginDrawer>
            </div>

            <div v-if="$userStore.user" class="lg:hidden">
              <p class="text-center text-xs">Balance</p>
              <p class="text-center font-bold">
                {{
                  $n($userStore.user.credit.totals.overall / 100, 'currency')
                }}
              </p>
            </div>

            <div class="hidden sm:block">
              <AppButton
                :route="{
                  name: $userStore.isLoggedIn ? 'members-zone' : 'memberships',
                }"
                :title="$userStore.isLoggedIn ? 'members-zone' : 'memberships'"
                class="button-primary text-sm"
                :icon="null"
              >
                <template #loggedIn>
                  {{ $t('label.members_zone') }}
                </template>
                <template #loggedOut>
                  {{ $t('header.7_day_trial') }}
                </template>
              </AppButton>
            </div>

            <div class="flex items-center lg:hidden">
              <!-- Mobile menu button-->
              <button
                type="button"
                class="relative inline-flex items-center justify-center rounded-md p-2"
                aria-controls="mobile-menu"
                :aria-expanded="open"
                @click="open = !open"
              >
                <span class="absolute -inset-0.5" />
                <span class="sr-only">{{ $t('header.main_menu') }}</span>
                <svg
                  class="h-8 w-8"
                  :class="{ hidden: open, flex: !open }"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  :aria-hidden="open"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>

                <a
                  class="rounded-full bg-black p-1 text-pure-white"
                  :class="{ flex: open, hidden: !open }"
                  :aria-hidden="!open"
                >
                  <AppIcon :icon="Icons.Times" size="sm" />
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div
        v-if="routeOptions?.displayLinks ?? true"
        id="mobile-menu"
        class="mobile-nav h-full w-full bg-pure-white pb-6 lg:hidden"
        :class="[open ? 'block' : 'hidden']"
      >
        <div class="mobile-nav-content flex h-full flex-col">
          <ol role="list" class="mb-6 flex flex-col gap-4 pl-4 pr-6">
            <li>
              <RouterLink
                :to="{ name: 'home' }"
                class="flex items-center gap-2 rounded p-2 text-xl leading-7"
                @click="closeMenu"
              >
                <AppIcon :icon="Icons.Home" /> Home
              </RouterLink>
            </li>

            <li v-for="(route, key) in navMobileRoutes" :key>
              <RouterLink
                :to="route.path"
                class="flex items-center gap-2 rounded p-2 text-xl leading-7"
                @click="closeMenu"
              >
                <AppIcon :icon="route.meta?.nav?.header?.icon" />
                {{
                  $t(
                    'header.' +
                      route.meta?.nav?.header?.text
                        ?.toLowerCase()
                        .replace(/ /g, '_')
                  )
                }}
              </RouterLink>
            </li>
          </ol>

          <div class="flex h-full flex-col justify-between gap-6">
            <div
              class="button mx-6 rounded-full"
              :class="[
                $userStore.isLoggedOut
                  ? 'bg-primary'
                  : 'button-secondary button-secondary-light',
                $userStore.isLoggedIn && !$userStore.showUpgradePlan
                  ? 'mt-auto'
                  : '',
              ]"
            >
              <AppLoginDrawer v-if="$userStore.isLoggedOut">
                <template #button="{ openDrawer }">
                  <button
                    type="button"
                    class="flex w-full items-center justify-center gap-2"
                    @click="
                      () => {
                        closeMenu()
                        openDrawer()
                      }
                    "
                  >
                    <p class="flex items-center font-semibold">
                      {{ $t('label.login') }}
                    </p>

                    <AppIcon :icon="Icons.ArrowRight" />
                  </button>
                </template>
              </AppLoginDrawer>

              <RouterLink
                v-if="$userStore.isLoggedIn"
                :to="{ name: 'logout' }"
                @click="closeMenu"
                class=""
              >
                <div class="flex items-center font-semibold">
                  {{ $t('label.logout') }}
                </div>
              </RouterLink>
            </div>

            <div class="upsell dark-section mx-6 rounded-lg p-4">
              <p v-if="$userStore.isLoggedIn" class="text-xl font-bold">
                Start your {{ BUSINESS_NAME }} Journey
              </p>
              <p v-if="$userStore.isLoggedOut" class="text-xl font-bold">
                Ready for the VIP experience?
              </p>

              <p v-if="$userStore.isLoggedIn" class="font-medium">
                Upgrade now to increase your entries, unlock exclusive treats,
                and enjoy premium perks.
              </p>
              <p v-if="$userStore.isLoggedOut" class="font-medium">
                Unlock amazing prizes and exclusive perks today. Join risk-free
                with our 7-day trial.
              </p>

              <AppUpgradeModal
                v-if="$userStore.showUpgradePlan"
                :user-plan="$userStore.highestPlan"
                :upgrade-plan="
                  $plansStore.getPlanByTier(
                    'vip',
                    $userStore.highestPlan?.period
                  )
                "
              >
                <template #default="{ openModal }">
                  <AppButton
                    class="mt-5 w-full border-secondary bg-secondary !text-sm text-black"
                    :title="`Upgrade to ${$userStore.upsellToPlan?.name}`"
                    @click="openModal"
                  >
                    Increase my odds
                  </AppButton>
                </template>
              </AppUpgradeModal>

              <AppButton
                v-if="$userStore.isLoggedOut"
                class="mt-5 w-full border-secondary bg-secondary !text-sm text-black"
                :title="$t('header.7_day_trial')"
                :route="{ name: 'memberships' }"
                @click="closeMenu"
              >
                Start 7-day free trial
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
header {
  z-index: 2147483637; // max z-index value
  transition: all 0.15s ease-in-out;

  &.light {
    @apply bg-pure-white;
  }

  nav {
    @apply overflow-y-auto;
  }

  .mobile-nav {
    height: calc(100dvh - 64px);

    .mobile-nav-content {
      li {
        a {
          &.active-link {
            @apply border-b-0 bg-primary/10;
          }
        }
      }

      .upsell {
        @apply bg-[length:100%_100%] bg-no-repeat;

        background-image: url('@/assets/images/nav-upsell-background.svg');
      }
    }
  }
}
</style>
