import fetcher from '@/services/fetcher'
import type { Sponsorship } from '@/types'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'

type SponsorshipsStoreState = {
  sponsorships: Map<string, Sponsorship>
}

export const useSponsorshipsStore = defineStore('sponsorships', {
  state: (): SponsorshipsStoreState => ({
    sponsorships: new Map(),
  }),

  actions: {
    async fetchSponsors(): Promise<Array<Sponsorship>> {
      if (this.sponsorships.size) {
        return this.sponorshipsArray
      }

      try {
        await fetcher.get<Sponsorship>('/data/sponorships.json', false, {
          key: 'name',
          map: this.sponsorships,
        })
      } catch (error: any) {
        Sentry.captureException(error)
      }

      return this.sponorshipsArray
    },
  },
  getters: {
    sponorshipsArray(): Array<Sponsorship> {
      return Array.from(this.sponsorships.values())
    },
  },
})
