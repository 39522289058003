<script lang="ts">
import { useFuturePrizesFilter } from '@/plugins/filters/useFuturePrizesFilter'
import { useInstantWinsStore } from '@/stores/instant-wins'
import { usePrizesStore } from '@/stores/prizes'
import { defineComponent } from 'vue'

/**
 * Prizes Section.
 */
export default defineComponent({
  setup() {
    const prizesStore = usePrizesStore()
    const instantWinsStore = useInstantWinsStore()
    const prizesFilter = useFuturePrizesFilter(
      prizesStore,
      instantWinsStore,
      false
    )

    return {
      prizesFilter,
    }
  },
})
</script>

<template>
  <PrizesSection id="prizes" :filter="prizesFilter">
    <template #heading>
      <h2 class="heading-2">
        {{ $t('prizes_slider.see_prizes') }}
      </h2>
    </template>

    <template #prizeButton="{ prize }">
      <slot name="prizeButton" :prize />
    </template>

    <template #button>
      <AppUpgradeModal
        v-if="$userStore.showUpgradePlan"
        :user-plan="$userStore.highestPlan"
        :upgrade-plan="
          $plansStore.getPlanByTier('vip', $userStore.highestPlan?.period)
        "
      />
      <AppMembershipButton v-if="$userStore.isLoggedOut" />
    </template>
  </PrizesSection>
</template>
