import type {
  FilterFunctionProperties,
  InstantWinMainPrize,
  MenuItem,
  Prize,
} from '@/types'
import { PrizeCategories } from '@/utils/helper-objects'
import type { InstantWinsStore, PrizesStore } from 'env'
import { type Ref, ref, toValue, watch } from 'vue'

export function useFuturePrizesFilter(
  prizesStore: PrizesStore,
  instantWinsStore: InstantWinsStore,
  paginate: boolean = true
): FilterFunctionProperties<Prize | InstantWinMainPrize, true> {
  const categories: Ref<Array<MenuItem>> = ref([])
  const items: Ref<Array<Prize | InstantWinMainPrize>> = ref([])
  const selectedItemCode = ref(PrizeCategories.Featured as string)
  const filteredItems: Ref<Array<Prize | InstantWinMainPrize>> = ref([])
  const page: Ref<number> = ref(1)
  const hasMoreItems: Ref<boolean> = ref(false)

  watch(selectedItemCode, () => {
    filterItems()
  })

  watch(page, () => {
    filterItems()
  })

  async function loadItems() {
    await Promise.all([
      instantWinsStore.fetchInstantWins(),
      prizesStore.fetchPrizes(),
    ])

    const futureInstantWinMainPrizes =
      instantWinsStore.futureInstantWinMainPrizes

    if (
      futureInstantWinMainPrizes.length &&
      !futureInstantWinMainPrizes[0].categories.includes(
        PrizeCategories.Featured
      )
    ) {
      futureInstantWinMainPrizes[0].categories.push(PrizeCategories.Featured)
    }

    items.value = [...prizesStore.futurePrizes, ...futureInstantWinMainPrizes]
    categories.value = prizesStore.generateCategories(items.value)

    filterItems()

    return toValue(items)
  }

  function filterItems() {
    const filtered =
      selectedItemCode.value === PrizeCategories.AllPrizes
        ? items.value
        : items.value.filter((p: Prize | InstantWinMainPrize) => {
            return p.categories.includes(selectedItemCode.value as string)
          })

    // Sort by close_at date, but ONLY for Featured category
    if (selectedItemCode.value === PrizeCategories.Featured) {
      filtered.sort((a, b) => {
        // Use close_at if available, otherwise use drawn_at
        const aTime = a.close_at?.toMillis() || a.drawn_at.toMillis()
        const bTime = b.close_at?.toMillis() || b.drawn_at.toMillis()
        return aTime - bTime
      })
    }

    //   function round(x) {
    //     if (x % 5 == 0) {
    //         return (Math.floor(x / 5)) * 5;
    //     } else {
    //         return ((Math.floor(x / 5)) * 5) + 5;
    //     }
    // }

    if (paginate) {
      const pageSize = 4

      let paginated = filtered.slice(0, page.value * pageSize)

      if (
        Math.floor(paginated.length / pageSize) &&
        paginated.length !== filtered.length
      ) {
        paginated = filtered.slice(
          0,
          Math.floor(paginated.length / pageSize) * pageSize
        )
      }

      hasMoreItems.value = filtered.length > paginated.length

      filteredItems.value = paginated
    } else {
      filteredItems.value = filtered
    }
  }

  function setSelectedItem(menuItem: MenuItem) {
    selectedItemCode.value = toValue(menuItem).code
    page.value = 1
  }

  function increasePage() {
    page.value = page.value + 1
  }

  return {
    categories,
    filteredItems,
    hasMoreItems,
    items,
    loadItems,
    selectedItemCode,
    increasePage,
    setSelectedItem,
  }
}
