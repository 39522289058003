<script lang="ts">
import { isToday, isTomorrow } from '@/utils/date-time-utils'
import { type DateTime } from 'luxon'
import { type PropType, defineComponent } from 'vue'

/**
 * A component to display relative and actual date times.
 */
export default defineComponent({
  props: {
    /**
     * A datetime.
     * Must be of type (Luxon) DateTime.
     */
    datetime: {
      type: Object as PropType<DateTime>,
      required: true,
    },
    /**
     * The ending datetime to countdown to.
     * Must be of type (Luxon) DateTime.
     */
    endDatetime: {
      type: Object as PropType<DateTime>,
      required: false,
    },
  },
  setup() {
    return {
      isToday,
      isTomorrow,
    }
  },
})
</script>

<template>
  <div
    class="flex items-center rounded-t-md bg-primary px-6 py-3 text-xl font-bold text-white"
  >
    <div class="flex flex-grow items-center justify-center gap-3">
      <div class="font-medium leading-none">
        {{
          isToday(datetime)
            ? 'Ends Today'
            : isTomorrow(datetime)
              ? 'Ends Tomorrow'
              : 'Date drawn'
        }}
      </div>

      <div class="font-black leading-none">
        {{
          $d(
            datetime.toJSDate(),
            isToday(datetime) || isTomorrow(datetime) ? 'time' : 'short'
          )
        }}
        <template
          v-if="(isToday(datetime) || isTomorrow(datetime)) && endDatetime"
        >
          -
          {{ $d(endDatetime.toJSDate(), 'time') }}
        </template>
      </div>
    </div>

    <!-- <AppIcon :icon="Icons.Calendar" size="md" class="text-primary" /> -->
  </div>
</template>
