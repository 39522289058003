<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.12497 11.3755L11.375 8.00049L6.12497 4.62549V11.3755ZM7.99997 15.5005C6.96247 15.5005 5.98747 15.3036 5.07497 14.9099C4.16247 14.5161 3.36872 13.9817 2.69372 13.3067C2.01872 12.6317 1.48434 11.838 1.09059 10.9255C0.696844 10.013 0.499969 9.03799 0.499969 8.00049C0.499969 6.96299 0.696844 5.98799 1.09059 5.07549C1.48434 4.16299 2.01872 3.36924 2.69372 2.69424C3.36872 2.01924 4.16247 1.48486 5.07497 1.09111C5.98747 0.697363 6.96247 0.500488 7.99997 0.500488C9.03747 0.500488 10.0125 0.697363 10.925 1.09111C11.8375 1.48486 12.6312 2.01924 13.3062 2.69424C13.9812 3.36924 14.5156 4.16299 14.9093 5.07549C15.3031 5.98799 15.5 6.96299 15.5 8.00049C15.5 9.03799 15.3031 10.013 14.9093 10.9255C14.5156 11.838 13.9812 12.6317 13.3062 13.3067C12.6312 13.9817 11.8375 14.5161 10.925 14.9099C10.0125 15.3036 9.03747 15.5005 7.99997 15.5005ZM7.99997 14.0005C9.67497 14.0005 11.0937 13.4192 12.2562 12.2567C13.4187 11.0942 14 9.67549 14 8.00049C14 6.32549 13.4187 4.90674 12.2562 3.74424C11.0937 2.58174 9.67497 2.00049 7.99997 2.00049C6.32497 2.00049 4.90622 2.58174 3.74372 3.74424C2.58122 4.90674 1.99997 6.32549 1.99997 8.00049C1.99997 9.67549 2.58122 11.0942 3.74372 12.2567C4.90622 13.4192 6.32497 14.0005 7.99997 14.0005Z"
      fill="currentColor"
    />
  </svg>
</template>
