// Core Vue imports
import App from '@/App.vue'
// App services
import type { StateChange } from '@/ably'
// Styles
import '@/assets/main.scss'
// Component imports (could be moved to separate file for better organization)
import AppBanner from '@/components/banners/AppBanner.vue'
import AsSeenInBanner from '@/components/banners/AsSeenInBanner.vue'
import HighlightBanner from '@/components/banners/HighlightBanner.vue'
import TrustpilotBanner from '@/components/banners/TrustpilotBanner.vue'
import WhyJoinBanner from '@/components/banners/WhyJoinBanner.vue'
import AppAccordion from '@/components/common/AppAccordion.vue'
import AppAccordionSingle from '@/components/common/AppAccordionSingle.vue'
import AppBadge from '@/components/common/AppBadge.vue'
import AppBreadcrumb from '@/components/common/AppBreadcrumb.vue'
import AppChip from '@/components/common/AppChip.vue'
import AppCountdown from '@/components/common/AppCountdown.vue'
import AppDateTime from '@/components/common/AppDateTime.vue'
import AppFilter from '@/components/common/AppFilter.vue'
import AppList from '@/components/common/AppList.vue'
import AppPicture from '@/components/common/AppPicture.vue'
import AppSkipLink from '@/components/common/AppSkipLink.vue'
import AppSlider from '@/components/common/AppSlider.vue'
import AppSlideshow from '@/components/common/AppSlideshow.vue'
import AppSocialIcons from '@/components/common/AppSocialIcons.vue'
import AppTrustpilot from '@/components/common/AppTrustpilot.vue'
import GlSlider from '@/components/common/carousel/GlSlider.vue'
import AppDrawer from '@/components/drawers/AppDrawer.vue'
import AppLoginDrawer from '@/components/drawers/AppLoginDrawer.vue'
import AppIcon from '@/components/icons/AppIcon.vue'
import AppButton from '@/components/inputs/AppButton.vue'
import AppCheckbox from '@/components/inputs/AppCheckbox.vue'
import AppForm from '@/components/inputs/AppForm.vue'
import AppInput from '@/components/inputs/AppInput.vue'
import AppMembershipButton from '@/components/inputs/AppMembershipButton.vue'
import AppProgress from '@/components/inputs/AppProgress.vue'
import AppRange from '@/components/inputs/AppRange.vue'
import AppSelect from '@/components/inputs/AppSelect.vue'
import AppSwitch from '@/components/inputs/AppSwitch.vue'
import AppToggle from '@/components/inputs/AppToggle.vue'
import AppEndTrialModal from '@/components/modals/AppEndTrialModal.vue'
import AppInformationModal from '@/components/modals/AppInformationModal.vue'
import AppModal from '@/components/modals/AppModal.vue'
import AppUpgradeModal from '@/components/modals/AppUpgradeModal.vue'
import AppYouTubeModal from '@/components/modals/AppYouTubeModal.vue'
import AppSection from '@/components/sections/AppSection.vue'
import PlansSection from '@/components/sections/PlansSection.vue'
import PrizesAllSection from '@/components/sections/PrizesAllSection.vue'
import PrizesSection from '@/components/sections/PrizesSection.vue'
import router from '@/router'
import { Calendar } from '@/services/calendar'
// Stores
import { useAlertStore } from '@/stores/alert'
import { usePlansStore } from '@/stores/plans'
import { useUserStore } from '@/stores/user'
// Utility imports
import { Icons } from '@/utils/helper-objects'
import { toFullUrl } from '@/utils/url-generation-utils'
// Third-party libraries
import Echo from '@ably/laravel-echo'
import { cookieBot } from '@ambitiondev/vue-cookiebot'
import { createGtm } from '@gtm-support/vue-gtm'
import * as Sentry from '@sentry/vue'
import * as Ably from 'ably'
import type { AppEnv } from 'env'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import { DateTime } from 'luxon'
import { vMaska } from 'maska/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueGtag, { trackRouter } from 'vue-gtag-next'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import { i18n } from './i18n'
import { useFeatureFlagsStore } from './stores/feature-flags'

// Environment variables
const APP_ENV = import.meta.env.MODE as AppEnv
const APP_DEBUG = import.meta.env.GLP_APP_ENV !== 'production'

// Define global constants
export const globalConstants = {
  APP_ENV,
  APP_DEBUG,
  PORTAL_URL: import.meta.env.GLP_PORTAL_URL,
  API_URL: import.meta.env.GLP_API_URL,
  GLX_URL: import.meta.env.GLP_GLX_URL,
  WORDPRESS_URL: import.meta.env.GLP_WORDPRESS_URL,
  BROADCASTING_URL: import.meta.env.GLP_BROADCASTING_URL,
  GLIDE_URL: import.meta.env.GLP_GLIDE_URL,
  BIG_PRIZE_VALUE: Number(import.meta.env.GLP_BIG_PRIZE_VALUE ?? 25000),

  BUSINESS_NAME: import.meta.env.GLP_BUSINESS_NAME,
  BUSINESS_PHONE: import.meta.env.GLP_BUSINESS_PHONE,
  BUSINESS_EMAIL: import.meta.env.GLP_BUSINESS_EMAIL,
  BUSINESS_ADDRESS: import.meta.env.GLP_BUSINESS_ADDRESS,
  BUSINESS_REGISTERED_NAME: import.meta.env.GLP_BUSINESS_REGISTERED_NAME,
  BUSINESS_REGISTERED_ADDRESS: import.meta.env.GLP_BUSINESS_REGISTERED_ADDRESS,
  BUSINESS_COMPLAINTS_EMAIL: import.meta.env.GLP_BUSINESS_COMPLAINTS_EMAIL,

  REFERRAL_CREDIT_VALUE: 20,
  REFERRAL_DISCOUNT_VALUE: 0.5,
  SOCIALS_ENTRIES_VALUE: 25,

  $router: router,
  $i18n: i18n.global,
  $toFullUrl: toFullUrl,
  $calendar: Calendar,

  DateTime,
  Icons,
}

// Create pinia store
const pinia = createPinia()
pinia.use(() => ({
  ...globalConstants,
}))

// Create Vue application
const app = createApp(App)

// https://github.com/vuejs/core/issues/3031#issuecomment-1085291941
app.provide('globalConstants', globalConstants)

// Configure plugins
app.use(i18n)
app.use(pinia)
app.use(router)
app.use(FloatingVue, {
  themes: {
    tooltip: {
      triggers: ['hover', 'focus', 'touch', 'click'],
    },
  },
})
app.use(Toast, {
  position: POSITION.TOP_LEFT,
})
app.use(VueGtag, {
  appName: import.meta.env.GLP_APP_TITLE,
  useDebugger: globalConstants.APP_DEBUG,
  property: {
    id: import.meta.env.GLP_GA_MEASUREMENT_ID,
  },
})
app.use(cookieBot, {
  cookieBotId: import.meta.env.GLP_COOKIEBOT_ID,
})
app.use(
  createGtm({
    id: 'GTM-NNPVJFH',
    // queryParams: {
    //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //   gtm_preview: 'env-4',
    //   gtm_cookies_win: 'x',
    // },
    // source: 'https://customurl.com/gtm.js', // Add your own serverside GTM script
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  })
)

// https://www.npmjs.com/package/@sentry/vite-plugin
// https://docs.sentry.io/platforms/javascript/guides/vue/
Sentry.init({
  app,
  dsn: 'https://762c963cc13ba795e2441d7c6c34130b@o4507856233955328.ingest.de.sentry.io/4507856269017168',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  enabled: APP_ENV === 'production',
  environment: APP_ENV,
  tracesSampleRate: APP_ENV === 'production' ? 1.0 : 0,
  replaysSessionSampleRate: APP_ENV === 'production' ? 0.1 : 0,
  replaysOnErrorSampleRate: APP_ENV === 'production' ? 1.0 : 0,
})

// Register global components
// Consider using auto-registration or component libraries in the future
Object.entries({
  AppAccordion,
  AppAccordionSingle,
  AppBadge,
  AppBanner,
  AppBreadcrumb,
  AppButton,
  AppCheckbox,
  AppChip,
  AppCountdown,
  AppDateTime,
  AppDrawer,
  AppEndTrialModal,
  AppFilter,
  AppForm,
  AppIcon,
  AppInformationModal,
  AppInput,
  AppList,
  AppLoginDrawer,
  AppMembershipButton,
  AppModal,
  AppPicture,
  AppProgress,
  AppRange,
  AppSection,
  AppSelect,
  AppSkipLink,
  AppSlider,
  AppSlideshow,
  AppSocialIcons,
  AppSwitch,
  AppToggle,
  AppTrustpilot,
  AppUpgradeModal,
  AppYouTubeModal,
  AsSeenInBanner,
  GlSlider,
  HighlightBanner,
  PlansSection,
  PrizesAllSection,
  PrizesSection,
  TrustpilotBanner,
  WhyJoinBanner,
}).forEach(([name, component]) => {
  app.component(name, component)
})

// Register directives
app.directive('mask', vMaska)

// Define global properties
app.config.globalProperties = {
  ...app.config.globalProperties,
  ...globalConstants,
  $userStore: useUserStore(),
  $plansStore: usePlansStore(),
  $alertStore: useAlertStore(),
  $featureFlagsStore: useFeatureFlagsStore(),
}

app.config.performance = globalConstants.APP_DEBUG

// Configure Ably broadcasting
window.Ably = Ably
window.Echo = new Echo({
  broadcaster: 'ably',
  authEndpoint: import.meta.env.GLP_BROADCASTING_URL,
})

// Monitor Ably connection state
window.Echo.connector.ably.connection.on((stateChange: StateChange) => {
  if (stateChange.current === 'failed') {
    console.error('Ably connection failed:', stateChange.reason)
  }
  if (stateChange.current === 'connected') {
    console.log('Ably server connected')
  }
})

// Disable console in production
if (import.meta.env.GLP_APP_ENV === 'production') {
  console.log = () => {}
  console.debug = () => {}
  console.info = () => {}
  console.warn = () => {}
}

/**
 * Ensure device ID for guest users.
 *
 * @returns {string} Device ID
 */
function ensureDeviceId(): string {
  let deviceId = localStorage.getItem('device_id')
  if (!deviceId) {
    deviceId =
      'device_' +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    localStorage.setItem('device_id', deviceId)
  }

  return deviceId
}

/**
 * Initialize the application and mount it to the DOM
 */
async function initializeApp() {
  trackRouter(router)
  ensureDeviceId()

  try {
    const featureFlagsStore = useFeatureFlagsStore()
    const cacheLoaded = featureFlagsStore.initializeFromCache()

    if (!cacheLoaded) {
      await featureFlagsStore.fetchFeatureFlags()
    }
  } catch (error) {
    console.error('Failed to initialize feature flags:', error)
  }

  app.mount('#app')
}

// Initialize when router is ready
router.isReady().then(initializeApp)
