<script lang="ts">
import PrizeCard from '@/components/cards/PrizeCard.vue'
import type GlSlider from '@/components/common/carousel/GlSlider.vue'
import { useTailwind } from '@/plugins/useTailwind'
import type { FilterFunctionProperties, Prize } from '@/types'
import { isInstantWinMainPrize } from '@/utils/type-guard-utils'
import { defineComponent } from 'vue'

import InstantWinDrawCard from '../cards/InstantWinDrawCard.vue'

export default defineComponent({
  components: { PrizeCard, InstantWinDrawCard },
  props: {
    id: {
      type: String,
      required: true,
    },
    filter: {
      type: Object as () => FilterFunctionProperties<Prize>,
    },
    prizes: {
      type: Array as () => Prize[],
    },
  },
  setup() {
    return {
      isInstantWinMainPrize,
    }
  },
  data() {
    const tailwindBreakpoints = useTailwind().breakpoints

    return {
      currentPrizeTab: 0,
      tailwindBreakpoints,
      sliderBreakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 8,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
        [tailwindBreakpoints.xs]: {
          slidesPerView: 1.5,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          spaceBetween: 8,
        },
        [tailwindBreakpoints.sm]: {
          slidesPerView: 2,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          spaceBetween: 8,
        },
        [tailwindBreakpoints.md]: {
          slidesPerView: 3,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          spaceBetween: 16,
        },
        [tailwindBreakpoints.lg]: {
          slidesPerView: 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
      },
    }
  },
  computed: {
    prizesArray() {
      return this.filter?.filteredItems.value ?? this.prizes ?? []
    },
  },
  async mounted() {
    await this.filter?.loadItems()
  },
  methods: {
    handleFilterUpdate(index: number) {
      if (this.currentPrizeTab === index) return

      const glSliderComp = this.$refs.prizesSlider as unknown as InstanceType<
        typeof GlSlider
      >
      glSliderComp.hideSwiper()
      glSliderComp.debouncedInitSlider()

      this.currentPrizeTab = index
    },
  },
})
</script>

<template>
  <AppSection>
    <slot name="heading" />

    <slot name="sub-heading" />

    <AppFilter
      v-if="filter"
      :id
      :filter
      class="full-bleed-mobile"
      @updateFilter="handleFilterUpdate"
    />

    <GlSlider
      ref="prizesSlider"
      slider-id="prizes-slider"
      class="full-bleed-mobile"
      :breakpoints="sliderBreakpoints"
      :pagination="true"
      :navigation="true"
    >
      <template
        v-for="(prize, key) in prizesArray"
        :key="`${prize.code}-${key}`"
      >
        <InstantWinDrawCard v-if="isInstantWinMainPrize(prize)" :prize />

        <PrizeCard v-if="!isInstantWinMainPrize(prize)" :prize>
          <template #button="{ prize }">
            <slot name="prizeButton" :prize />
          </template>
        </PrizeCard>
      </template>
    </GlSlider>

    <slot name="button" />
  </AppSection>
</template>
