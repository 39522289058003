<script lang="ts">
import { useTailwind } from '@/plugins/useTailwind.js'
import {
  useDebounceFn,
  useMutationObserver,
  usePreferredReducedMotion,
} from '@vueuse/core'
import { capitalCase } from 'change-case'
import Swiper from 'swiper'
import { register } from 'swiper/element/bundle'
import type { SwiperOptions } from 'swiper/types'
import { type PropType } from 'vue'

register()

/**
 * A slider component utilising the SwiperJS package.
 *
 * @see https://swiperjs.com/swiper-api
 */
export default {
  props: {
    /**
     * An ID to make each slider unique.
     */
    sliderId: {
      type: String,
      required: true,
    },
    /**
     * Enables/disables the slider.
     */
    enabled: {
      type: Boolean,
      default: true,
    },
    /**
     * Displays arrows to control moving through the elements.
     */
    navigation: {
      type: Boolean,
      default: false,
    },
    /**
     * Centers slides if the number is less than can fill the bounding box.
     */
    centered: {
      type: Boolean,
      default: false,
    },
    /**
     * Animates through the slider elements.
     */
    autoScroll: {
      type: Boolean,
    },
    /**
     * The speed the elements will scroll in milliseconds.
     * Default: 10000
     */
    scrollSpeed: {
      type: Number,
      default: 10000,
    },
    /**
     * Makes the component the show the currently selected item.
     */
    pagination: {
      type: Boolean,
      default: true,
    },
    /**
     * Number of slides to show per view.
     * Default: 1.25 (screen responsive to > md = 4)
     */
    slidesPerView: {
      type: [String, Number],
      default: 'auto',
    },
    /**
     * Space between each slide.
     */
    spaceBetween: {
      type: Number,
      default: 16,
    },
    /**
     * Configuration at different breakpoints.
     */
    breakpoints: {
      type: Object as PropType<SwiperOptions['breakpoints']>,
      default: () => ({}),
    },
    /**
     * Offset (in px) on the first or last element.
     */
    sideOffsets: {
      type: Object as PropType<Record<'before' | 'after', number>>,
      default: () => ({
        before: 0,
        after: 0,
      }),
    },
    /**
     * Index number of the initial slide.
     */
    initialSlide: {
      type: Number,
      default: 0,
    },
    /**
     * A CSS selector of slider thumbnails.
     */
    thumbnails: null,
    /**
     * Classes to apply to the slider wrapper.
     */
    sliderClass: {
      type: String,
      default: '',
    },
  },
  emits: ['slide-change'],
  setup() {
    const prefersReduceMotion = usePreferredReducedMotion()
    const tailwind = useTailwind()

    return {
      prefersReduceMotion,
      capitalCase,
      tailwindBreakpoints: tailwind.breakpoints,
    }
  },
  data() {
    return {
      debouncedInitSlider: () => {},
      swiper: {} as Swiper,
    }
  },
  computed: {
    sliderBreakpoints() {
      // Responsive breakpoints
      return {
        0: {
          slidesPerView: this.thumbnails ? 1 : this.slidesPerView,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          spaceBetween: 8,
        },
        [this.tailwindBreakpoints.md]: {
          slidesPerView: this.thumbnails ? 1 : 3,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          spaceBetween: 16,
        },
        [this.tailwindBreakpoints.lg]: {
          slidesPerView: this.thumbnails ? 1 : 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
        ...(this.breakpoints ?? {}),
      }
    },
    sliderOptions(): SwiperOptions {
      const onInit = this.makeCardsFocusable
      const $emit = this.$emit

      return {
        edgeSwipeDetection: true,
        freeMode: {
          enabled: true,
          sticky: true,
        },
        spaceBetween: this.spaceBetween,
        // @ts-ignore
        slidesPerView: this.thumbnails ? 1 : this.slidesPerView,
        slidesOffsetBefore: this.sideOffsets.before,
        slidesOffsetAfter: this.sideOffsets.after,
        centeredSlides: this.centered,
        centeredSlidesBounds: this.centered,
        centerInsufficientSlides: this.centered,
        watchSlidesProgress: true,
        watchOverflow: true,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        initialSlide: this.initialSlide,
        pagination: {
          enabled: this.pagination,
          clickable: true,
          el: '.swiper-pagination',
          type: 'bullets',
        },
        keyboard: {
          enabled: this.navigation,
          onlyInViewport: true,
        },
        thumbs: {
          swiper: this.thumbnails,
        },
        // @ts-ignore 'auto' string IS a valid option
        breakpoints: this.sliderBreakpoints,
        navigation: {
          enabled: this.navigation,
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          init: function (swiper) {
            swiper.el.addEventListener('keydown', onInit)
          },
          slideChange(swiper) {
            $emit('slide-change', swiper.slides[swiper.activeIndex])
          },
        },
      }
    },
  },
  watch: {
    sliderOptions: {
      handler() {
        this.debouncedInitSlider()
      },
      deep: true,
      immediate: true,
    },
    breakpoints: {
      handler() {
        this.debouncedInitSlider()
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    const swiperElement = this.$refs.swiper
    this.swiper = new Swiper(swiperElement, this.sliderOptions)

    this.debouncedInitSlider = useDebounceFn(() => {
      this.swiper.destroy()

      const swiperElement = this.$refs.swiper

      if (!swiperElement) {
        return
      }

      this.swiper = new Swiper(swiperElement, this.sliderOptions)

      const slotContents = this.$refs.swiperWrapper.children

      Array.from(slotContents)
        .filter((el) => !el.classList.contains('swiper-slide'))
        .forEach((el) => {
          el.classList.add('swiper-slide', 'will-change-scroll')
          el.setAttribute('tabindex', '0')
        })

      this.swiper.update()
      this.swiper.slideTo(this.initialSlide)
    }, 250)

    useMutationObserver(this.$refs.swiperWrapper, this.debouncedInitSlider, {
      childList: true,
    })

    this.debouncedInitSlider()
  },
  methods: {
    makeCardsFocusable(e: any) {
      if (e.key === 'Tab') {
        var focusEl = document?.activeElement?.closest('.swiper-slide')
        if (
          null != focusEl &&
          !focusEl.classList.contains('swiper-slide-active')
        ) {
          var slideIndex = Array.prototype.indexOf.call(
            focusEl?.parentNode?.children,
            focusEl
          )

          this.swiper.slideTo(slideIndex)
        }
      }
    },
  },
}
</script>

<template>
  <div class="relative">
    <div :id="sliderId" ref="swiper" class="swiper w-full">
      <div
        ref="swiperWrapper"
        class="swiper-wrapper"
        :class="sliderClass"
        :aria-roledescription="`${capitalCase(sliderId)} Carousel`"
      >
        <slot />
      </div>

      <div
        v-if="navigation || pagination"
        class="navigation relative my-4 flex h-9 w-full items-center justify-center gap-6"
      >
        <div class="swiper-button-prev" />
        <!-- If we need pagination -->
        <div class="swiper-pagination" />
        <div class="swiper-button-next" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.swiper-slide {
  height: auto;
}

.swiper-button-prev,
.swiper-button-next {
  position: unset;
  margin-top: 0;
}

.swiper-pagination {
  @apply flex;

  position: unset;
  width: fit-content !important;
}

.navigation:has(.swiper-button-prev:empty):has(.swiper-button-next:empty):has(
    .swiper-pagination > span:only-child
  ),
.navigation:has([aria-disabled='true']):has(.swiper-pagination:empty),
.navigation:has(.swiper-pagination:empty) {
  @apply h-0 p-0;
}

.navigation:has([aria-disabled='false']):has(.swiper-pagination:not(:empty)) {
  @apply mt-4;
}
</style>
