<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 37 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 4V0H24.5V4H12.5ZM16.5 26H20.5V14H16.5V26ZM18.5 42C16.0333 42 13.7083 41.525 11.525 40.575C9.34167 39.625 7.43333 38.3333 5.8 36.7C4.16667 35.0667 2.875 33.1583 1.925 30.975C0.975 28.7917 0.5 26.4667 0.5 24C0.5 21.5333 0.975 19.2083 1.925 17.025C2.875 14.8417 4.16667 12.9333 5.8 11.3C7.43333 9.66667 9.34167 8.375 11.525 7.425C13.7083 6.475 16.0333 6 18.5 6C20.5667 6 22.55 6.33333 24.45 7C26.35 7.66667 28.1333 8.63333 29.8 9.9L32.6 7.1L35.4 9.9L32.6 12.7C33.8667 14.3667 34.8333 16.15 35.5 18.05C36.1667 19.95 36.5 21.9333 36.5 24C36.5 26.4667 36.025 28.7917 35.075 30.975C34.125 33.1583 32.8333 35.0667 31.2 36.7C29.5667 38.3333 27.6583 39.625 25.475 40.575C23.2917 41.525 20.9667 42 18.5 42ZM18.5 38C22.3667 38 25.6667 36.6333 28.4 33.9C31.1333 31.1667 32.5 27.8667 32.5 24C32.5 20.1333 31.1333 16.8333 28.4 14.1C25.6667 11.3667 22.3667 10 18.5 10C14.6333 10 11.3333 11.3667 8.6 14.1C5.86667 16.8333 4.5 20.1333 4.5 24C4.5 27.8667 5.86667 31.1667 8.6 33.9C11.3333 36.6333 14.6333 38 18.5 38Z"
      fill="currentColor"
    />
  </svg>
</template>
