<script lang="ts">
import type { InputValidator, NullableError } from '@/types'
import {
  generateValidatorsByInputType,
  validate,
} from '@/utils/input-validator-utils'
import { ValidationException } from '@/validation-exception'
import { type PropType, defineComponent } from 'vue'

type BlurEvent = FocusEvent & { target: { name: string } }

/**
 * A general component with common input functionality to extend more specific input types from.
 *
 * @see https://www.w3.org/WAI/tutorials/forms/
 * @see https://www.w3.org/TR/UNDERSTANDING-WCAG20/minimize-error-identified.html
 * @see https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/G89.html
 * @see https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/SCR32
 * @see https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/ARIA19.html
 * @see https://www.w3.org/TR/WCAG20-TECHS/ARIA18 On severe errors displays with a alertdialog
 * @see https://www.w3.org/TR/WCAG20-TECHS/ARIA21
 * @see https://www.w3.org/WAI/tutorials/forms/notifications
 */
export default defineComponent({
  inheritAttrs: false,
  props: {
    /**
     * The data to send the form data to.
     */
    data: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    /**
     * An array of objects with the input name, event on when to call the validator,
     * and either a callback or array of callbacks to validate the input.
     */
    validators: {
      type: Object as PropType<
        Record<string, InputValidator> | Record<string, Array<InputValidator>>
      >,
    },
    errors: {
      type: Object as PropType<NullableError>,
    },
  },
  emits: ['submit'],
  setup() {
    return { generateValidatorsByInputType }
  },
  data() {
    return {
      formErrors: null as NullableError,
      isSubmitting: false,
    }
  },
  computed: {
    completeErrors() {
      return this.errors ?? this.formErrors
    },
  },
  methods: {
    submitForm() {
      this.isSubmitting = true
      this.formErrors = null

      const validators = this.generateValidatorsByInputType(
        Array.from(this.$refs.form.querySelectorAll('input')),
        this.validators ?? {}
      )

      try {
        if (validators) {
          this.formErrors = validate(this.data, validators)
        }
      } catch (e: any) {
        this.formErrors = e
      } finally {
        this.isSubmitting = false
      }

      this.$emit('submit', this.formErrors == null)
    },
    clearError(event: FocusEvent) {
      const blurEvent = event as unknown as BlurEvent

      if (blurEvent.target && this.formErrors instanceof ValidationException) {
        this.formErrors.clear(blurEvent.target.name)
      } else {
        this.formErrors = null
      }
    },
  },
})
</script>

<template>
  <form
    v-bind="$attrs"
    ref="form"
    novalidate
    @submit.stop.prevent="submitForm"
    @blur.capture="clearError"
  >
    <slot :errors="completeErrors" />

    <p
      v-if="completeErrors?.message"
      class="form-error my-1 text-center text-xs text-red-500"
      v-html="completeErrors.message"
    />

    <slot name="buttons" :is-submitting />
  </form>
</template>

<style lang="scss">
.form-error {
  a {
    @apply font-bold;
  }
}
</style>
