<script lang="ts">
import { defineComponent } from 'vue'

/**
 * A banner to show CS agents they're impersonating a customer and with details.
 */
export default defineComponent({
  data() {
    return {
      showDetails: true,
    }
  },
  computed: {
    memberDetails() {
      if (!this.$userStore.user) {
        return []
      }

      const { phone, email, subscriptions } = this.$userStore.user

      return [
        {
          label: 'Phone:',
          value: phone,
        },
        {
          label: 'Email:',
          value: email,
        },
        {
          label: 'Subscriptions:',
          value: subscriptions
            ?.filter((s) => s.status !== 'cancelled')
            .reduce((acc, s) => {
              return `
                <a href="${this.toApiAdminUrl(`admin/subscription/${s.id}`)}" target="_blank" class="underline hover:no-underline">
                  ${s.plan_name}
                </a> (<strong>${s.status}${s.status_raw === 'in_trial' ? ' : Trial' : ''}</strong>)
                <br>
                ${acc}`
            }, ''),
        },
      ]
    },
  },
  methods: {
    toApiAdminUrl(path: string): string {
      const API_URL = import.meta.env.GLP_API_URL.replace(/(api\/)$/gim, '')
      return `${API_URL}${path}`
    },
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
  },
})
</script>

<template>
  <div id="impersonate-banner" class="mt-[65px] text-xs">
    <div class="mb-2 text-lg">
      <div class="flex items-center justify-between gap-4">
        You are now impersonating!

        <p
          @click="toggleDetails"
          class="text-xs underline hover:cursor-pointer hover:no-underline"
        >
          Show/Hide
        </p>
      </div>

      Customer:
      <a
        :href="toApiAdminUrl(`admin/customer/${$userStore.user?.id}`)"
        target="_blank"
        class="font-black underline hover:no-underline"
        >{{ $userStore.user?.first_name }} {{ $userStore.user?.last_name }}</a
      >
    </div>

    <template v-if="showDetails">
      <div
        v-for="(details, key) in memberDetails"
        :key
        class="mb-1 flex flex-wrap"
      >
        <p class="mr-2 flex-grow font-semibold">
          {{ details.label }}
        </p>
        <p v-html="details.value" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
#impersonate-banner {
  @apply left-0 bg-primary text-white;

  z-index: 2147483638; // max z-index value
  position: fixed;

  padding: 6px 12px;
  border-bottom-right-radius: 10px;
}
</style>
