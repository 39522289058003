<script lang="ts">
import SponsorshipCard from '@/components/cards/SponsorshipCard.vue'
import { useSponsorshipsStore } from '@/stores/sponsorships'
import { defineComponent } from 'vue'

/**
 * Sponsorship Section.
 */
export default defineComponent({
  components: {
    SponsorshipCard,
  },
  props: {
    displayWatchButton: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const sponsorshipsStore = useSponsorshipsStore()

    return {
      sponsorshipsStore,
    }
  },
  async mounted() {
    await this.sponsorshipsStore.fetchSponsors()
  },
})
</script>

<template>
  <AppSection>
    <h2 class="heading-2">
      {{ $t('section.sponsorships.heading') }}
    </h2>
    <p>{{ $t('section.sponsorships.excerpt') }}</p>

    <AppSlider slider-id="sponsorships-slider" class="full-bleed-mobile">
      <SponsorshipCard
        v-for="(sponsorship, key) in sponsorshipsStore.sponorshipsArray"
        :key
        :sponsorship
        :display-button="displayWatchButton"
      />
    </AppSlider>

    <AppUpgradeModal
      v-if="$userStore.showUpgradePlan"
      :user-plan="$userStore.highestPlan"
      :upgrade-plan="
        $plansStore.getPlanByTier('vip', $userStore.highestPlan?.period)
      "
    />
    <AppMembershipButton v-if="$userStore.isLoggedOut" />
  </AppSection>
</template>
