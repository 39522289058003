<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 25"
    fill="none"
  >
    <rect
      y="24.9053"
      width="24"
      height="24"
      rx="12"
      transform="rotate(-90 0 24.9053)"
      fill="#E2E8F0"
    />
    <path
      d="M12.0016 14.653L15.6756 10.9793C15.9247 10.73 16.2334 10.6053 16.6016 10.6053C16.9697 10.6053 17.2784 10.73 17.5276 10.9793C17.7769 11.2285 17.9016 11.5371 17.9016 11.9053C17.9016 12.2735 17.7769 12.5821 17.5276 12.8313L12.9418 17.4173C12.8135 17.5455 12.6675 17.6422 12.5038 17.7075C12.34 17.7727 12.1726 17.8053 12.0016 17.8053C11.8306 17.8053 11.6631 17.7727 11.4993 17.7075C11.3356 17.6422 11.1896 17.5455 11.0613 17.4173L6.47556 12.8313C6.22623 12.5821 6.10156 12.2735 6.10156 11.9053C6.10156 11.5371 6.22623 11.2285 6.47556 10.9793C6.72473 10.73 7.0334 10.6053 7.40156 10.6053C7.76973 10.6053 8.0784 10.73 8.32756 10.9793L12.0016 14.653Z"
      fill="#1E293B"
    />
  </svg>
</template>
