import localeEnGB from '@/locales/en-GB.json'
import { createI18n } from 'vue-i18n'

export type GBMessageSchema = typeof localeEnGB

/**
 * Configures internationalisation translation file and functions.
 *
 * @see https://vue-i18n.intlify.dev/api/composition.html#messagecompiler
 */
export const i18n = createI18n<[GBMessageSchema], 'en-GB'>({
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  legacy: true,
  globalInjection: true,
  warnHtmlMessage: false,
  messages: {
    'en-GB': localeEnGB,
  },
  missing: (locale, key) => {
    console.error(
      'Translation not found! Locale:',
      locale,
      'Translation key:',
      key
    )
  },
  numberFormats: {
    'en-GB': {
      number: {
        style: 'decimal',
      },
      currency: {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      'currency-short': {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false,
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
  },
  datetimeFormats: {
    'en-GB': {
      short: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
      long: {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      },
      full: {
        dateStyle: 'full',
      },
      month: {
        month: 'short',
      },
      time: {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      },
    },
  },
})
