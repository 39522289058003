<script lang="ts">
import type { AccordionContent } from '@/types'
import { arrayWrap } from '@/utils/utils'
import type { PropType } from 'vue'

/**
 * An accordion component which displays content when the title is clicked.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/accordion/examples/accordion/
 */
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    content: {
      type: Object as PropType<Array<AccordionContent>>,
      required: true,
    },
    allowMultipleOpen: {
      type: Boolean,
    },
    summaryClass: {
      type: [String, Array<String>],
      default: '',
    },
    detailsClass: {
      type: [String, Array<String>],
      default: '',
    },
    titleClass: {
      type: [String, Array<String>],
      default: '',
    },
  },
  computed: {
    accordionSummaryClass(): string {
      return `${arrayWrap(this.summaryClass).join(' ')} py-4`
    },
  },
  methods: {
    // detailsToggled(event: MouseEvent) {
    //   const activeDetails = event.target as HTMLDetailsElement
    //   if (!this.allowMultipleOpen) {
    //     this.$el
    //       .querySelectorAll('details')
    //       .forEach((detail: HTMLDetailsElement) => {
    //         console.log(activeDetails, detail)
    //         if (detail !== activeDetails) {
    //           detail.removeAttribute('open')
    //         }
    //       })
    //   }
    // },
  },
}
</script>

<template>
  <div class="accordion">
    <AppAccordionSingle
      v-for="(accordionContent, key) in content"
      :key
      :name
      class="text-black"
      :details-class="detailsClass"
      :summary-class="accordionSummaryClass"
    >
      <template #title>
        <p>
          <AppIcon
            v-if="accordionContent.pre_icon"
            :icon="accordionContent.pre_icon.icon"
            class="mr-2"
            :class="accordionContent.pre_icon.class"
          />

          <span :class="titleClass" v-html="accordionContent.title" />
        </p>
      </template>

      <template #text>
        <p v-html="accordionContent.text" />
      </template>

      <!-- ="{ isOpen }" -->
      <template #icon>
        <AppIcon :icon="Icons.AccordionArrow" class="control-icon" />
      </template>
    </AppAccordionSingle>
  </div>
</template>
