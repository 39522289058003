import { defineStore } from 'pinia'

type PageProgressState = {
  isLoading: boolean
}

export const usePageProgressStore = defineStore('page-progress', {
  state: (): PageProgressState => ({
    isLoading: false,
  }),
})
