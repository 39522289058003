import { usePrizesStore } from '@/stores/prizes'
import type { RouteLocation } from 'vue-router'

/**
 * Fetches the next prize to highlight.
 * Setting the prize to the route meta object (`nextPrize`)
 *
 * @param {RouteLocation} to
 */
export default async function fetchNextPrize(to: RouteLocation) {
  const prizesStore = usePrizesStore()

  await prizesStore.fetchPrizes()

  const nextPrize = prizesStore.nextPrize

  to.meta = {
    ...to.meta,
    nextPrize,
  }
}
