<script lang="ts">
import { groupBy, toValue } from '@/utils/utils'
import { defineComponent } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { Social } from './AppSocialIcons.vue'

/**
 * Displays a footer.
 */
export default defineComponent({
  setup() {
    return { Social }
  },
  computed: {
    navRoutes(): Record<
      string,
      Array<{
        path: string
        category: string
        text: string
        isExternal: boolean
      }>
    > {
      let routes = this.$router.options.routes.filter(
        (route: RouteRecordRaw) =>
          (route.meta?.nav?.footer?.display ?? false) || route.children
      )
      routes = routes.reduce(
        (routes: Array<RouteRecordRaw>, route: RouteRecordRaw) => {
          if (route.children) {
            routes = routes.concat(
              route.children.filter((route: RouteRecordRaw) =>
                toValue(route.meta?.nav?.footer?.display ?? false)
              )
            )
          }

          if (
            route.path &&
            toValue(route.meta?.nav?.footer?.display ?? false)
          ) {
            routes.push(route)
          }

          return routes
        },
        []
      )

      const footerRoutes = routes.map((route: RouteRecordRaw) => ({
        path: route.path,
        category: route.meta?.nav?.footer?.category ?? 'other',
        text: route.meta?.nav?.footer?.text as string,
        isExternal: false,
      }))

      // @ts-ignore TODO Fix typing of Footer nav
      const groupedFooterNav = groupBy((r) => r.category, footerRoutes)

      if (!groupedFooterNav.company) {
        groupedFooterNav.company = []
      }

      groupedFooterNav.company.push({
        path: '//investors.goodlifeplus.co.uk/',
        category: 'company',
        text: 'Investors',
        isExternal: true,
      })

      return groupedFooterNav
    },
  },
  methods: {
    categoryToText(category: string): string {
      switch (category) {
        case 'company':
          return 'Company'

        case 'support':
          return 'Help & Support'

        case 'other':
          return 'Other'
      }

      return category
    },
  },
})
</script>

<template>
  <footer class="dark-section px-8 py-8 pb-12">
    <RouterLink :to="{ name: 'home' }" class="!border-0">
      <img
        src="@/assets/logo-light.svg"
        :title="`${BUSINESS_NAME} Logo`"
        :alt="`${BUSINESS_NAME} Logo`"
        height="24"
        width="180"
        class="mb-6"
      />
    </RouterLink>
    <div class="flex flex-col gap-8 md:flex-row">
      <div class="flex-grow italic">
        <div class="text-lg font-bold">Questions?</div>
        <div class="text-2xl font-black" tabindex="0">
          Call us on
          <a :href="`tel:${BUSINESS_PHONE}`" class="link">{{
            BUSINESS_PHONE
          }}</a>
        </div>

        <AppSocialIcons
          :socials="[
            Social.Facebook,
            Social.X,
            Social.Youtube,
            Social.Instagram,
          ]"
          class="mt-8"
        />
      </div>

      <nav class="grid grid-cols-1 gap-4 md:grid-cols-3">
        <div v-for="(routeGroup, category) in navRoutes" :key="category">
          <div class="mb-3 text-lg font-bold">
            {{ categoryToText(category) }}
          </div>

          <ol role="list" class="flex flex-col gap-3">
            <li v-for="(route, key) in routeGroup" :key>
              <RouterLink
                v-if="!route.isExternal"
                :to="route.path"
                class="block rounded-md bg-slate-600 px-4 font-medium leading-10"
              >
                {{ route.text }}
              </RouterLink>
              <a
                v-if="route.isExternal"
                :href="route.path"
                class="block rounded-md bg-slate-600 px-4 font-medium leading-10"
                target="_blank"
              >
                {{ route.text }}
              </a>
            </li>
          </ol>
        </div>
      </nav>
    </div>

    <div class="flex flex-col gap-3">
      <p class="mt-8 text-justify">
        You must be over 18 to enter. If you are under 18 your ticket will be
        void and refunded.
        <a href="//www.gambleaware.org" class="link" target="_blank">
          www.gambleaware.org</a
        >. Our prize draws are in no way sponsored or endorsed by Facebook or
        Instagram. Draws will be made using independent verification software.
      </p>

      <p>&copy; {{ DateTime.now().toFormat('yyyy') }} {{ BUSINESS_NAME }}</p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
a.link {
  @apply text-pure-white;
}
</style>
