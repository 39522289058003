<script lang="ts">
import PlanCard from '@/components/cards/PlanCard.vue'
import { usePlansFilter } from '@/plugins/filters/usePlansFilter'
import { useTailwind } from '@/plugins/useTailwind.js'
import { usePlansStore } from '@/stores/plans'
import { MembershipPeriods, Themes } from '@/utils/helper-objects'
import { useWindowSize } from '@vueuse/core'
import { defineComponent } from 'vue'

/**
 * Plans Section.
 */
export default defineComponent({
  components: {
    PlanCard,
  },
  setup() {
    const { width } = useWindowSize()
    const tailwind = useTailwind()

    const plansStore = usePlansStore()
    const plansFilter = usePlansFilter(plansStore)

    return {
      plansFilter,
      plansStore,
      browserWidth: width,
      tailwindBreakpoints: tailwind.breakpoints,
      Themes,
    }
  },
  data() {
    return {
      isYearlyPayment: false,
    }
  },
  watch: {
    browserWidth: {
      handler() {
        this.updatePlansFilterByBrowserWidth()
      },
      immediate: true,
    },
    isYearlyPayment() {
      this.plansStore.selectedMembershipPeriod = this.isYearlyPayment
        ? MembershipPeriods.Yearly
        : MembershipPeriods.Monthly
    },
  },
  async mounted() {
    await this.plansFilter.loadItems()

    this.isYearlyPayment =
      this.plansStore.selectedMembershipPeriod === MembershipPeriods.Yearly

    // Trigger filter update on plan filter
    this.updatePlansFilterByBrowserWidth()
  },
  methods: {
    updatePlansFilterByBrowserWidth() {
      this.plansFilter.selectedItemCode.value =
        this.browserWidth > this.tailwindBreakpoints.md ? null : 'standard'
    },
  },
})
</script>

<template>
  <AppSection v-if="$userStore.isLoggedOut">
    <div
      class="flex flex-col justify-between gap-y-3 md:flex-row md:items-center"
    >
      <h2 class="heading-2">Your winning journey starts here</h2>

      <AppFilter
        id="plans"
        :filter="plansFilter"
        class="full-bleed-mobile !px-4 md:hidden"
        :use-slider="false"
      />

      <AppToggle
        v-if="plansFilter.selectedItemCode.value !== 'postal'"
        v-model="isYearlyPayment"
        name="plan-period"
        class="mx-auto my-3 md:mx-0"
      >
        <div
          class="inline-flex w-[7ch] items-center text-sm font-semibold md:text-base"
        >
          <template v-if="isYearlyPayment">
            {{ $t('date.annual') }}
          </template>
          <template v-if="!isYearlyPayment">
            {{ $t('date.monthly') }}
          </template>
        </div>
        <span class="text-xs md:text-base">
          ({{ $t('section.plans.discount', 33) }})
        </span>
      </AppToggle>
    </div>

    <div class="grid grid-cols-1 gap-6 md:mt-3 md:grid-cols-3">
      <PlanCard
        v-for="(plan, key) in plansFilter.filteredItems.value"
        :key
        :plan
        :selected-membership-period="plansStore.selectedMembershipPeriod"
      />
    </div>
  </AppSection>

  <TrustpilotBanner v-if="$userStore.isLoggedOut" class="my-0" />
</template>
