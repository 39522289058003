<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 25"
    fill="none"
    class="inline-block flex-shrink-0"
  >
    <path
      d="M10.2571 18.2455C9.86656 18.6361 9.23339 18.6361 8.84287 18.2455L4.55708 13.9597C4.16656 13.5692 4.17195 12.9307 4.56248 12.5401C4.953 12.1496 5.59156 12.1442 5.98208 12.5347L8.84287 15.3955C9.23339 15.7861 9.86656 15.7861 10.2571 15.3955L18.0179 7.63475C18.4084 7.24423 19.047 7.24962 19.4375 7.64014C19.828 8.03067 19.8334 8.66923 19.4429 9.05975L10.2571 18.2455Z"
      fill="currentColor"
    />
  </svg>
</template>
