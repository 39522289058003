<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M8.52923 21.4805C6.89757 21.4805 5.52132 20.9203 4.40048 19.7998C3.27982 18.6793 2.71948 17.3034 2.71948 15.672C2.71948 14.9907 2.83515 14.3282 3.06648 13.6845C3.29798 13.0409 3.62723 12.4598 4.05423 11.9413L7.56198 7.73703L5.91848 4.43578C5.69532 3.98945 5.71248 3.55853 5.96998 3.14303C6.22765 2.72736 6.60465 2.51953 7.10098 2.51953H17.2847C17.7811 2.51953 18.1581 2.72736 18.4157 3.14303C18.6734 3.55853 18.6906 3.98945 18.4675 4.43578L16.824 7.73703L20.3315 11.9413C20.7691 12.4623 21.1034 13.0447 21.3342 13.6885C21.5651 14.3325 21.6805 14.9944 21.6805 15.674C21.6805 17.3015 21.1157 18.6761 19.986 19.7978C18.8563 20.9196 17.4846 21.4805 15.8707 21.4805H8.52923ZM12.2 16.1838C11.6028 16.1838 11.0966 15.9762 10.6815 15.561C10.2663 15.1457 10.0587 14.6395 10.0587 14.0425C10.0587 13.4454 10.2663 12.9392 10.6815 12.524C11.0966 12.1087 11.6028 11.901 12.2 11.901C12.7971 11.901 13.3033 12.1087 13.7185 12.524C14.1336 12.9392 14.3412 13.4454 14.3412 14.0425C14.3412 14.6395 14.1336 15.1457 13.7185 15.561C13.3033 15.9762 12.7971 16.1838 12.2 16.1838ZM9.96623 6.60428H14.4477L15.1652 5.15553H9.23473L9.96623 6.60428ZM8.52923 18.8445H15.8707C16.7576 18.8445 17.5082 18.5374 18.1227 17.9233C18.7372 17.3093 19.0445 16.5591 19.0445 15.6728C19.0445 15.2996 18.9807 14.9397 18.8532 14.593C18.7257 14.2462 18.5416 13.9322 18.301 13.651L14.6402 9.24028H9.77073L6.09898 13.626C5.85832 13.9167 5.67423 14.2382 5.54673 14.5905C5.41923 14.9429 5.35548 15.3029 5.35548 15.6708C5.35548 16.5576 5.66273 17.3083 6.27723 17.9228C6.89173 18.5373 7.6424 18.8445 8.52923 18.8445Z"
      fill="currentColor"
    />
  </svg>
</template>
