<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 19 16"
    fill="none"
  >
    <path
      d="M3.80005 14V15C3.80005 15.2833 3.70422 15.5208 3.51255 15.7125C3.32088 15.9042 3.08338 16 2.80005 16H1.80005C1.51672 16 1.27922 15.9042 1.08755 15.7125C0.895882 15.5208 0.800049 15.2833 0.800049 15V7L2.90005 1C3.00005 0.7 3.17922 0.458333 3.43755 0.275C3.69588 0.0916667 3.98338 0 4.30005 0H15.3C15.6167 0 15.9042 0.0916667 16.1625 0.275C16.4209 0.458333 16.6 0.7 16.7 1L18.8 7V15C18.8 15.2833 18.7042 15.5208 18.5125 15.7125C18.3209 15.9042 18.0834 16 17.8 16H16.8C16.5167 16 16.2792 15.9042 16.0875 15.7125C15.8959 15.5208 15.8 15.2833 15.8 15V14H3.80005ZM3.60005 5H16L14.95 2H4.65005L3.60005 5ZM5.30005 11C5.71672 11 6.07088 10.8542 6.36255 10.5625C6.65422 10.2708 6.80005 9.91667 6.80005 9.5C6.80005 9.08333 6.65422 8.72917 6.36255 8.4375C6.07088 8.14583 5.71672 8 5.30005 8C4.88338 8 4.52922 8.14583 4.23755 8.4375C3.94588 8.72917 3.80005 9.08333 3.80005 9.5C3.80005 9.91667 3.94588 10.2708 4.23755 10.5625C4.52922 10.8542 4.88338 11 5.30005 11ZM14.3 11C14.7167 11 15.0709 10.8542 15.3625 10.5625C15.6542 10.2708 15.8 9.91667 15.8 9.5C15.8 9.08333 15.6542 8.72917 15.3625 8.4375C15.0709 8.14583 14.7167 8 14.3 8C13.8834 8 13.5292 8.14583 13.2375 8.4375C12.9459 8.72917 12.8 9.08333 12.8 9.5C12.8 9.91667 12.9459 10.2708 13.2375 10.5625C13.5292 10.8542 13.8834 11 14.3 11ZM2.80005 12H16.8V7H2.80005V12Z"
      fill="currentColor"
    />
  </svg>
</template>
