<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 25"
    fill="none"
    class="inline-block flex-shrink-0"
  >
    <path
      d="M6.4 19.9526L5 18.5526L10.6 12.9526L5 7.35264L6.4 5.95264L12 11.5526L17.6 5.95264L19 7.35264L13.4 12.9526L19 18.5526L17.6 19.9526L12 14.3526L6.4 19.9526Z"
      fill="currentColor"
    />
  </svg>
</template>
