<script lang="ts">
import { useTailwind } from '@/plugins/useTailwind'
import { defineComponent } from 'vue'

/**
 * The "Product" overview page.
 */
export default defineComponent({
  setup() {
    const tailwind = useTailwind()

    return {
      tailwindBreakpoints: tailwind.breakpoints,
    }
  },
})
</script>

<template>
  <AppBanner labelled-by="as-seen-in" class="bg-primary">
    <div id="as-seen-in" class="mx-auto mb-3 text-lg font-semibold text-white">
      As seen in
    </div>

    <AppSlider
      slider-id="as-seen-in-slider"
      :navigation="false"
      :pagination="false"
      :center="true"
      :breakpoints="{
        0: {
          slidesPerView: 'auto',
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
        [tailwindBreakpoints.sm]: {
          slidesPerView: 'auto',
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
        },
      }"
      class="full-bleed-mobile"
      slider-class="md:gap-6 items-center justify-center md:justify-start lg:justify-center"
    >
      <img
        src="@/assets/images/partners/daily-mail_logo.svg"
        alt="The Daily Mail Logo"
        class="as-seen-in-slide"
        loading="lazy"
      />
      <img
        src="@/assets/images/partners/the-sun_logo.svg"
        alt="The Sun Logo"
        class="as-seen-in-slide"
        loading="lazy"
      />
      <img
        src="@/assets/images/partners/the-mirror_logo.svg"
        alt="The Mirror Logo"
        class="as-seen-in-slide"
        loading="lazy"
      />
      <img
        src="@/assets/images/partners/joe-media_logo.svg"
        alt="Joe Media Logo"
        class="as-seen-in-slide max-sm:hidden"
        loading="lazy"
      />
      <img
        src="@/assets/images/partners/vodafone_logo.svg"
        alt="Vodafone Logo"
        class="as-seen-in-slide max-sm:hidden"
        loading="lazy"
      />
      <img
        src="@/assets/images/partners/wowcha_logo.svg"
        alt="Wowcha Logo"
        class="as-seen-in-slide max-sm:hidden"
        loading="lazy"
      />
      <img
        src="@/assets/images/partners/mumsnet_logo.svg"
        alt="Mumsnet Logo"
        class="as-seen-in-slide max-sm:hidden"
        loading="lazy"
      />
    </AppSlider>
  </AppBanner>
</template>

<style lang="scss" scoped>
.as-seen-in-slide {
  @apply max-h-[24px] max-w-fit !flex-shrink md:max-h-[48px];
}
</style>
