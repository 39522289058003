<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00001 7.07264L13.52 1.55264L15.2 3.23264L8.00001 10.4326L0.800013 3.23264L2.48001 1.55264L8.00001 7.07264Z"
      fill="currentColor"
    />
  </svg>
</template>
