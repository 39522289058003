import { useCampaignsStore } from '@/stores/campaigns'
import type { Campaign, Prize } from '@/types'
import type {
  NavigationGuardNext,
  RouteLocation,
  RouteRecordRaw,
} from 'vue-router'

export default [
  {
    path: '/refund-policy',
    name: 'refund-policy',
    component: () => import('../views/TermsAndConditions/RefundPolicyView.vue'),
    meta: {
      title: 'Refund Policy',
      nav: {
        footer: {
          display: true,
          text: 'Refund Policy',
          category: 'other',
        },
      },
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () =>
      import('../views/TermsAndConditions/PrivacyPolicyView.vue'),
    meta: {
      title: 'Privacy Policy',
      nav: {
        footer: {
          display: true,
          text: 'Privacy Policy',
          category: 'other',
        },
      },
    },
  },
  {
    path: '/data-policy',
    name: 'data-policy',
    component: () => import('../views/TermsAndConditions/DataPolicyView.vue'),
    meta: {
      title: 'Data Policy',
      nav: {
        footer: {
          display: true,
          text: 'Data Policy',
          category: 'other',
        },
      },
    },
  },
  {
    meta: {
      nav: {
        footer: {
          display: true,
        },
      },
    },
    children: [
      {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () =>
          import(
            '../views/TermsAndConditions/TermsAndConditionsOverviewView.vue'
          ),
        meta: {
          title: 'Terms & Conditions',
          nav: {
            footer: {
              display: true,
              text: 'Terms & Conditions',
              category: 'other',
            },
          },
        },
      },
      {
        path: '/full-terms-and-conditions',
        name: 'full-terms-and-conditions',
        component: () =>
          import('../views/TermsAndConditions/TermsAndConditionsView.vue'),
        meta: {
          title: 'Full Terms & Conditions',
        },
      },

      {
        path: '/terms-and-conditions/complaints-policy',
        name: 'complaints-policy',
        component: () =>
          import('../views/TermsAndConditions/ComplaintsPolicyView.vue'),
        meta: {
          title: 'Complaints Policy',
          nav: {
            footer: {
              display: true,
              text: 'Complaints Policy',
              category: 'support',
            },
          },
        },
      },
      {
        path: '/breakfast-baguette-deal-terms',
        name: 'breakfast-baguette-deal-terms',
        component: () =>
          import(
            '../views/TermsAndConditions/Greggs/BreakfastRollDealTermsView.vue'
          ),
        meta: {
          nav: {
            header: {
              display: false,
            },
            footer: {
              display: false,
            },
          },
        },
      },
      {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: () =>
          import('../views/TermsAndConditions/TermOfUseView.vue'),
        meta: {
          title: 'Terms of Use',
          nav: {
            footer: {
              display: true,
              text: 'Terms of Use',
              category: 'other',
            },
          },
        },
      },
      {
        path: '/prize-terms-and-conditions/:prizeCode',
        name: 'prize-terms-and-conditions',
        component: () =>
          import('../views/TermsAndConditions/PrizeTermsView.vue'),
        meta: {
          title: (to: RouteLocation): string => {
            return `Prize Terms & Conditions: ${(to.meta.props as Prize).title}`
          },
        },
        props: (route: { meta: { props: { prize: Prize } } }) => {
          return {
            prize: route.meta.props.prize,
          }
        },
      },
    ],
  },
  {
    path: '/campaign-terms-and-conditions/:campaignCode',
    name: 'campaign-terms-and-conditions',
    component: () =>
      import('../views/TermsAndConditions/CampaignTermsView.vue'),
    meta: {
      title: (to: RouteLocation): string => {
        return `Campaign Terms & Conditions: ${(to.meta.props as Campaign).name}`
      },
    },
    props: (route) => {
      return {
        campaign: route.meta.props,
      }
    },
    async beforeEnter(
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) {
      // check for defined campaigns & redirect
      try {
        const campaignCode = to.params.campaignCode

        if (!campaignCode) {
          return next({ name: 'not-found' })
        }

        const campaignStore = useCampaignsStore()

        to.meta.props = await campaignStore.fetchCampaign(
          campaignCode as string
        )

        next()
      } catch (e) {
        console.error(e)
        // redirect to NotFoundView
        return next({ name: 'not-found' })
      }
    },
  },
] as Readonly<RouteRecordRaw[]>
