<script lang="ts">
import { sentenceCase } from 'change-case'
import { defineComponent, nextTick } from 'vue'

/**
 * An alert modal to show an error/success message.
 *
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/alertdialog/examples/alertdialog/
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
 */
export default defineComponent({
  setup() {
    return {
      sentenceCase,
    }
  },
  methods: {
    hideAlert() {
      nextTick(() => {
        this.$alertStore.hideAlert()
      })
    },
    buttonCallback(closeModal: () => void) {
      this.hideAlert()
      closeModal()

      this.$alertStore.callbackFn?.()
    },
  },
})
</script>

<template>
  <AppModal
    :id="`${$alertStore.type}-alert`"
    :show-close-button="false"
    class="alert min-h-fit flex-col"
    :close-button-class="`right-4 top-4 ${$alertStore.type === 'error' ? 'text-pure-white' : ''}`"
    :open="$alertStore.show"
    :aria-labelledby="`${$alertStore.type}-title`"
    :aria-describedby="`${$alertStore.type}-message`"
    aria-live="assertive"
  >
    <template #default="{ closeModal }">
      <div
        class="flex justify-center px-2 py-8"
        :class="{
          'bg-secondary': $alertStore.type === 'success',
          'bg-primary': $alertStore.type === 'error',
        }"
      >
        <img
          v-if="$alertStore.type === 'error'"
          src="@/assets/images/error-icon.svg"
          title="Error Icon"
          role="presentation"
        />

        <AppIcon
          v-if="$alertStore.type === 'success'"
          :icon="Icons.Member"
          size="lg"
        />
      </div>

      <div class="flex flex-col items-center justify-center gap-4 p-6">
        <AppBadge
          class="rounded-xl uppercase"
          size="md"
          :class="{
            'bg-secondary': $alertStore.type === 'success',
            'bg-primary text-white': $alertStore.type === 'error',
          }"
        >
          {{ sentenceCase($alertStore.type) }}
        </AppBadge>

        <h2
          v-if="$alertStore.title"
          :id="`${$alertStore.type}-title`"
          class="text-xl font-bold"
        >
          {{ $alertStore.title }}
        </h2>

        <p :id="`${$alertStore.type}-message`" class="text-sm">
          {{ $alertStore.message }}
        </p>

        <AppButton
          class="button-primary w-full"
          title="Close alert dialog"
          @click="buttonCallback(closeModal)"
        >
          {{ $alertStore.buttonText }}
        </AppButton>
      </div>
    </template>
  </AppModal>
</template>

<style lang="scss">
.alert {
  @apply min-w-[320px];
}
</style>
