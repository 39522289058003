import type {
  Campaign,
  InstantWinDraw,
  InstantWinMainPrize,
  Plan,
  Prize,
  User,
  Winner,
} from '@/types'

/**
 * Checks if the value is a valid User object.
 *
 * @param {any} value
 * @returns {boolean}
 */
export const isUser = (value: any): value is User =>
  !!value?.__type && value.__type === 'User' && !!value.id

/**
 * Checks if the value is a valid Plan object.
 *
 * @param {any} value
 * @returns {boolean}
 */
export const isPlan = (value: any): value is Plan =>
  !!value?.__type && value.__type === 'Plan'

/**
 * Checks if the value is a valid Prize object.
 *
 * @param {any} value
 * @returns {boolean}
 */
export const isPrize = (value: any): value is Prize =>
  !!value?.__type && value.__type === 'Prize'

/**
 * Checks if the value is a valid Winner object.
 *
 * @param {any} value
 * @returns {boolean}
 */
export const isWinner = (value: any): value is Winner =>
  !!value?.__type && value.__type === 'Winner'

/**
 * Checks if the value is a valid InstantWinMainPrize object.
 *
 * @param {any} value
 * @returns {boolean}
 */
export const isInstantWinMainPrize = (
  value: any
): value is InstantWinMainPrize =>
  !!value?.__type && value.__type === 'InstantWinMainPrize'

/**
 * Checks if the value is a valid InstantWinDraw object.
 *
 * @param {any} value
 * @returns {boolean}
 */
export const isInstantWinDraw = (value: any): value is InstantWinDraw =>
  !!value?.__type && value.__type === 'InstantWinDraw'

/**
 * Checks if the value is a valid Campaign object.
 *
 * @param {any} value
 * @returns {boolean}
 */
export const isCampaign = (value: any): value is Campaign =>
  !!value?.__type && value.__type === 'Campaign'
