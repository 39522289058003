import { useInstantWinsStore } from '@/stores/instant-wins'
import type { InstantWinDraw } from '@/types'
import { isInFuture, isInPast } from '@/utils/date-time-utils'
import type {
  NavigationGuardNext,
  RouteLocation,
  RouteRecordRaw,
} from 'vue-router'
import { useToast } from 'vue-toastification'

export default [
  {
    path: '/instant-win/:instantWinCode',
    name: 'instant-win',
    component: () => import('../views/InstantWinsView.vue'),
    meta: {
      title: (to: RouteLocation): string => {
        return `Instant Win Draw: ${(to.meta.props as InstantWinDraw).title}`
      },
    },
    props: (route) => {
      return {
        instantWin: route.meta.props,
      }
    },
    async beforeEnter(
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) {
      try {
        const toast = useToast()
        const instantWinCode = to.params.instantWinCode as string

        if (!instantWinCode) {
          return next({ name: 'not-found' })
        }

        const instantWinsStore = useInstantWinsStore()
        const instantWin = await instantWinsStore.getInstantWin(instantWinCode)

        if (!instantWin) {
          return next({ name: 'not-found' })
        }

        // Doing async call to fetchInitialCounters in the component
        // await instantWinsStore.fetchInitialCounters(instantWin)

        if (isInFuture(instantWin.main_prize.open_at)) {
          toast.error(
            'This Instant Win is not open yet, please come back after: ' +
              instantWin.main_prize.open_at.toFormat('d/MM/y')
          )

          // TODO: Update to new Instant Wins page
          return next({ name: 'prizes' })
        }

        if (isInPast(instantWin.main_prize.close_at)) {
          toast.error(
            'This Instant Win is in the past, please try a different Instant Win Draw'
          )

          // TODO: Update to new Instant Wins page
          return next({ name: 'prizes' })
        }

        to.meta.props = instantWin

        next()
      } catch (e) {
        console.error(e)
        // redirect to NotFoundView
        return next({ name: 'not-found' })
      }
    },
  },
  {
    path: '/instant-win-checkout/:instantWinCode',
    name: 'instant-win-checkout',
    component: () => import('../views/Checkout/CheckoutInstantWinsView.vue'),
    meta: {
      title: 'Instant Wins Checkout',
    },
    props: (route) => {
      const instantWinsCheckoutProps = route.meta.props as {
        instantWin: InstantWinDraw
        ticketCount: number
      }

      return {
        instantWin: instantWinsCheckoutProps.instantWin,
        ticketCount: instantWinsCheckoutProps.ticketCount,
      }
    },
    async beforeEnter(
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) {
      try {
        const toast = useToast()
        const instantWinCode = to.params.instantWinCode as string

        if (!instantWinCode) {
          return next({ name: 'not-found' })
        }

        const instantWinsStore = useInstantWinsStore()
        const instantWin = await instantWinsStore.getInstantWin(instantWinCode)

        let ticketCount = Number(to.query.tickets)

        if (!instantWin || !ticketCount) {
          return next({ name: 'not-found' })
        }

        if (ticketCount > instantWin.max_ticket_purchase) {
          toast.error(
            `You have exceeded the maximum number of tickets (${instantWin.max_ticket_purchase}) for this Instant Win`
          )

          ticketCount = instantWin.max_ticket_purchase
        }

        if (instantWin.total_purchased_tickets >= instantWin.total_tickets) {
          toast.error(
            `This Instant Win is sold out, please try a different Instant Win Draw`
          )

          return next({ name: 'prizes' })
        }

        if (isInFuture(instantWin.main_prize.open_at)) {
          toast.error(
            `This Instant Win is not open yet, please come back after: ` +
              instantWin.main_prize.open_at.toFormat('d/MM/y')
          )

          return next({ name: 'prizes' })
        }

        if (isInPast(instantWin.main_prize.close_at)) {
          toast.error(
            'This Instant Win is in the past, please try a different Instant Win Draw'
          )

          return next({ name: 'prizes' })
        }

        to.meta.props = {
          instantWin,
          ticketCount,
        }

        next()
      } catch (e) {
        console.error(e)
        // redirect to NotFoundView
        return next({ name: 'not-found' })
      }
    },
  },
  {
    path: '/instant-win-checkout/:instantWinCode/order-confirmation',
    name: 'instant-win-checkout-order-confirmation',
    component: () =>
      import(
        '../views/Checkout/CheckoutStages/CheckoutInstantWinsConfirmationDesktopView.vue'
      ),
    meta: { title: 'Instant Wins Checkout Order Confirmation' },
    props: (route) => {
      const instantWinsCheckoutConfirmationProps = route.meta.props as {
        instantWin: InstantWinDraw
        ticketCount: Number
      }

      return {
        instantWin: instantWinsCheckoutConfirmationProps.instantWin,
        ticketCount: instantWinsCheckoutConfirmationProps.ticketCount,
      }
    },
    async beforeEnter(
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) {
      try {
        const instantWinCode = to.params.instantWinCode as string
        const instantWinsStore = useInstantWinsStore()

        const instantWin = await instantWinsStore.getInstantWin(instantWinCode)
        const ticketCount = to.query.tickets

        if (!instantWin || !ticketCount) {
          return next({ name: 'not-found' })
        }

        to.meta.props = {
          instantWin,
          ticketCount: Number(ticketCount),
        }

        next()
      } catch (e) {
        console.error(e)
        next({ name: 'not-found' })
      }
    },
  },
  {
    path: '/instant-win-checkout/:instantWinCode/winner',
    name: 'instant-win-checkout-winner',
    component: () =>
      import(
        '../views/Checkout/CheckoutStages/CheckoutInstantWinsWinnerDesktopView.vue'
      ),
    meta: { title: 'Instant Wins - Winner' },
    props: (route) => {
      const instantWinsCheckoutConfirmationProps = route.meta.props as {
        instantWin: InstantWinDraw
      }

      return {
        instantWin: instantWinsCheckoutConfirmationProps.instantWin,
      }
    },
    async beforeEnter(
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) {
      try {
        const instantWinCode = to.params.instantWinCode as string
        const instantWinsStore = useInstantWinsStore()

        const instantWin = await instantWinsStore.getInstantWin(instantWinCode)

        if (!instantWin) {
          return next({ name: 'not-found' })
        }

        to.meta.props = {
          instantWin,
        }

        next()
      } catch (e) {
        console.error(e)
        next({ name: 'not-found' })
      }
    },
  },
  {
    path: '/instant-win-checkout/:instantWinCode/next-time',
    name: 'instant-win-checkout-no-winner',
    component: () =>
      import(
        '../views/Checkout/CheckoutStages/CheckoutInstantWinsNoWinnerDesktopView.vue'
      ),
    meta: { title: 'Instant Wins - Next Time' },
    props: (route) => {
      const instantWinsCheckoutConfirmationProps = route.meta.props as {
        instantWin: InstantWinDraw
      }

      return {
        instantWin: instantWinsCheckoutConfirmationProps.instantWin,
      }
    },
    async beforeEnter(
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) {
      try {
        const instantWinCode = to.params.instantWinCode as string
        const instantWinsStore = useInstantWinsStore()

        const instantWin = await instantWinsStore.getInstantWin(instantWinCode)

        if (!instantWin) {
          return next({ name: 'not-found' })
        }

        to.meta.props = {
          instantWin,
        }

        next()
      } catch (e) {
        console.error(e)
        next({ name: 'not-found' })
      }
    },
  },
  {
    path: '/instant-win-terms-and-conditions',
    name: 'instant-win-terms-and-conditions',
    component: () =>
      import('../views/TermsAndConditions/InstantWinTermsView.vue'),
    meta: {
      title: 'Instant Win Terms & Conditions',
    },
  },
] as Readonly<RouteRecordRaw[]>
