<script lang="ts">
import { defineComponent } from 'vue'

/**
 * A drawer component.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alertdialog_role
 */
export default defineComponent({
  inheritAttrs: false,
  props: {
    /**
     * An ID for the drawer, must be unique.
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Places the drawer on a specifc edge of the viewport.
     */
    position: {
      type: String,
      default: 'right',
    },
    /**
     * Closes the drawer when a user clicks outside the contents.
     */
    closeOnBlur: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    drawerPosition() {
      return `slide-from-${this.position}`
    },
  },
  beforeMount() {
    document.addEventListener('keyup', this.closeOnEsc)
    document.addEventListener('mouseup', this.closeWhenOffTarget)
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.closeOnEsc)
    document.removeEventListener('mouseup', this.closeWhenOffTarget)
  },
  methods: {
    openDrawer() {
      this.isOpen = true
      // document.getElementById('app')?.setAttribute('inert', 'true')
    },
    closeDrawer() {
      this.isOpen = false
      // document.getElementById('app')?.removeAttribute('inert')
    },
    closeOnEsc(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        this.closeDrawer()
      }
    },
    closeWhenOffTarget(event: MouseEvent) {
      if (
        this.closeOnBlur &&
        !this.$refs.drawer?.contains(event.target as Node)
      ) {
        this.closeDrawer()
      }
    },
  },
})
</script>

<template>
  <slot name="button" :open-drawer />

  <teleport to="#modal-teleport">
    <div
      v-if="isOpen"
      ref="outsideModal"
      class="backdrop fixed bottom-0 left-0 right-0 top-0 w-full"
    />

    <Transition :name="drawerPosition">
      <div
        v-if="isOpen"
        :id
        ref="drawer"
        class="drawer relative bg-pure-white text-black"
        :class="`drawer-position-${position}`"
        v-bind="$attrs"
        aria-modal="true"
        :aria-label="id"
        role="dialog"
      >
        <a
          class="absolute right-4 top-4 flex cursor-pointer items-center justify-center rounded-full bg-black p-1 text-pure-white"
          @click.passive="closeDrawer"
        >
          <AppIcon :icon="Icons.Times" size="sm" />
        </a>

        <slot :close-drawer />
      </div>
    </Transition>
  </teleport>
</template>

<style lang="scss" scoped>
$positions: top, right, bottom, left;

@each $position in $positions {
  .slide-from-#{$position}-enter-active,
  .slide-from-#{$position}-leave-active {
    transition: all 0.5s ease;
  }

  .slide-from-#{$position}-enter-from,
  .slide-from-#{$position}-leave-to {
    @if $position == right {
      transform: translateX(100%);
    } @else if $position == left {
      transform: translateX(-100%);
    } @else if $position == top {
      transform: translateY(-100%);
    } @else if $position == bottom {
      transform: translateY(100%);
    }
  }

  .drawer-position-#{$position} {
    #{$position}: 0;

    @if $position == right {
      @apply bottom-0 rounded-t-lg max-md:w-full md:h-dvh md:rounded-l-lg;
    } @else if $position == left {
      @apply bottom-0 rounded-t-lg max-md:w-full md:h-dvh md:rounded-r-lg;
    } @else if $position == top {
      @apply bottom-0 rounded-t-lg md:w-dvw md:rounded-b-lg;
    } @else if $position == bottom {
      @apply bottom-0 rounded-t-lg max-md:w-full md:w-dvw;
    }
  }
}

.drawer {
  position: fixed;
  z-index: 2147483638; // max z-index value
  box-shadow: 10px 0 20px 30px rgb(0, 0, 0, 0.2);
}
</style>
