<script lang="ts">
import { defineComponent } from 'vue'

/**
 * A simple badge.
 */
export default defineComponent({
  props: {
    size: {
      default: 'sm',
      type: String,
    },
  },
})
</script>

<template>
  <span
    class="flex h-fit w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 font-bold leading-none text-black"
    :class="{ 'px-1 text-2xs': size === 'sm', 'px-3 text-xs': size === 'md' }"
  >
    <slot />
  </span>
</template>
