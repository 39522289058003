<script lang="ts">
import type { ListItem, Plan, TIcon } from '@/types'
import { toPeriod } from '@/utils/date-time-utils'
import { MembershipPeriods, Themes } from '@/utils/helper-objects'
import { type PropType, defineComponent } from 'vue'

import PlanPostalCard from './PlanPostalCard.vue'

/**
 * A card to display a plan.
 *
 */
export default defineComponent({
  components: {
    PlanPostalCard,
  },
  inheritAttrs: false,
  props: {
    /**
     * A configured plan object.
     */
    plan: {
      type: Object as PropType<Plan>,
      required: true,
    },
    /**
     * Updates the displayed features/pricing based on membership period.
     */
    selectedMembershipPeriod: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { toPeriod, MembershipPeriods, Themes }
  },
  computed: {
    alreadySubscribed(): boolean {
      return (
        this.$userStore.user?.subscriptions?.some((p) =>
          Object.values(this.plan.tier).includes(p.code)
        ) ?? false
      )
    },
    buttonText(): string {
      if (this.$userStore.isLoggedOut) {
        return `Join ${this.plan.name} plan`
      }

      return this.alreadySubscribed
        ? 'This is your current plan'
        : `Upgrade to ${this.plan.name}`
    },
    buttonClass(): string {
      if (this.$userStore.isLoggedOut) {
        return 'button-primary'
      }

      return this.alreadySubscribed
        ? 'button-secondary pointer-events-none'
        : 'button-primary'
    },
    planFeatures(): Array<ListItem> {
      return this.plan.features.map((feature) => ({
        icon: feature.pre_icon?.icon as TIcon,
        class: 'text-dark-secondary',
        description: feature.title,
      }))
    },
  },
})
</script>

<template>
  <article
    v-if="plan.tier !== 'postal' && selectedMembershipPeriod === plan.period"
    class="plan-card flex flex-grow flex-col bg-pure-white"
    :class="plan.theme"
    v-bind="$attrs"
    tabindex="0"
  >
    <div class="plan-header">
      <div class="p-5 pb-3">
        <div class="text-xl">
          <span class="font-bold">{{ plan.name }}</span>
          <span
            v-if="selectedMembershipPeriod === MembershipPeriods.Yearly"
            class="capitalize"
          >
            ({{ plan.period }})</span
          >
        </div>

        <div class="">
          <span class="font-bold">
            {{
              $n(
                selectedMembershipPeriod === MembershipPeriods.Monthly
                  ? plan.cost
                  : plan.discount_cost,
                'currency',
                {
                  minimumFractionDigits: 2,
                }
              )
            }} </span
          >/{{ toPeriod(selectedMembershipPeriod) }}
        </div>

        <div class="text-sm">
          <template
            v-if="selectedMembershipPeriod === MembershipPeriods.Yearly"
          >
            <span class="line-through">
              {{
                $n(plan.cost, 'currency', {
                  minimumFractionDigits: 2,
                })
              }}
            </span>
            {{
              $n(plan.discount_cost, 'currency', {
                minimumFractionDigits: 2,
              })
            }}
            for the first 12 months
          </template>
          <template
            v-if="selectedMembershipPeriod !== MembershipPeriods.Yearly"
          >
            Billed every month
          </template>
        </div>
      </div>
    </div>

    <div class="plan-middle flex items-center gap-2 px-5 py-4">
      <AppBadge
        size="md"
        class="!rounded-2xl uppercase text-white"
        :class="{
          'bg-dark-secondary': plan.period === MembershipPeriods.Yearly,
          'bg-tertiary': plan.period === MembershipPeriods.Monthly,
        }"
      >
        {{ plan.period === MembershipPeriods.Yearly ? 'NEW' : 'FREE TRIAL' }}
      </AppBadge>

      <span v-html="plan.description" />
    </div>

    <div class="plan-footer flex flex-grow flex-col justify-between px-5">
      <AppAccordion
        :name="`${plan.code}-features`"
        :content="plan.features"
        class="my-3"
        :summary-class="[
          plan.theme === Themes.Light ? 'text-black' : 'text-white',
        ]"
        :details-class="[
          plan.theme === Themes.Light ? 'text-black' : 'text-white',
          'text-sm font-semibold',
        ]"
      />

      <AppButton
        :route="{
          name: 'checkout',
          query: { plan: plan.id ?? plan.code },
        }"
        class="mx-auto mb-8 mt-6 w-full font-semibold capitalize"
        :title="buttonText"
        :class="buttonClass"
      >
        {{ buttonText }}
      </AppButton>
    </div>
  </article>
  <PlanPostalCard v-if="plan.tier === 'postal'" v-bind="$attrs" />
</template>

<style lang="scss" scoped>
.plan-card {
  &.dark {
    @apply rounded-lg bg-slate-900 text-white;

    .plan-middle {
      @apply bg-slate-800;
    }

    .badge {
      @apply bg-teal;
    }
  }

  &.light {
    .plan-header {
      @apply rounded-t-lg border border-b-0 border-slate-300;
    }

    .plan-middle {
      @apply bg-primary text-white;
    }

    .badge {
      @apply bg-tertiary text-white;
    }

    .plan-footer {
      @apply rounded-b-lg border border-t-0 border-slate-300;
    }
  }
}

.plan-header {
  @apply bg-right bg-no-repeat;

  background-image: url('@/assets/images/plans-party.png');
}
</style>
