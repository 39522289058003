<script lang="ts">
import { useTailwind } from '@/plugins/useTailwind'
import { defineComponent } from 'vue'

/**
 * "Why Join" section.
 */
export default defineComponent({
  setup() {
    const tailwind = useTailwind()

    return {
      tailwindBreakpoints: tailwind.breakpoints,
    }
  },
})
</script>

<template>
  <AppBanner labelled-by="why-join" class="dark-section">
    <div id="why-join" class="sr-only">
      Join {{ BUSINESS_NAME }} for membership benefits.
    </div>

    <AppSlider
      slider-id="why-join-slider"
      :navigation="false"
      :pagination="false"
      class="full-bleed-mobile"
    >
      <div class="card">
        <div class="relative flex items-center justify-center md:flex-shrink-0">
          <AppIcon :icon="Icons.TV" size="lg" class="absolute text-white" />
          <img
            src="@/assets/images/background-01.svg"
            role="presentation"
            class="w-full rounded-tl-lg rounded-tr-lg md:rounded-bl-lg md:rounded-tr-none"
            width="80"
            height="80"
          />
        </div>

        <p class="">Enjoy daily live draws</p>
      </div>

      <div class="card">
        <div class="relative flex items-center justify-center md:flex-shrink-0">
          <AppIcon :icon="Icons.Reload" size="lg" class="absolute text-white" />
          <img
            src="@/assets/images/background-02.svg"
            role="presentation"
            class="w-full rounded-tl-lg rounded-tr-lg md:rounded-bl-lg md:rounded-tr-none"
            width="80"
            height="80"
          />
        </div>

        <p class="">
          Automatically enter<br />
          prize draws
        </p>
      </div>

      <div class="card">
        <div class="relative flex items-center justify-center md:flex-shrink-0">
          <AppIcon
            :icon="Icons.MoneyBox"
            size="lg"
            class="absolute text-white"
          />
          <img
            src="@/assets/images/background-03.svg"
            role="presentation"
            class="w-full rounded-tl-lg rounded-tr-lg md:rounded-bl-lg md:rounded-tr-none"
            width="80"
            height="80"
          />
        </div>

        <p class="">Exclusive discounts<br />& deals</p>
      </div>

      <div class="card">
        <div class="relative flex items-center justify-center md:flex-shrink-0">
          <AppIcon :icon="Icons.Star" size="lg" class="absolute text-white" />
          <img
            src="@/assets/images/background-04.svg"
            role="presentation"
            class="w-full rounded-tl-lg rounded-tr-lg md:rounded-bl-lg md:rounded-tr-none"
            width="80"
            height="80"
          />
        </div>

        <p class="">Monthly treats<br />& VIP perks</p>
      </div>
    </AppSlider>
  </AppBanner>
</template>

<style lang="scss" scoped>
.card {
  height: auto;

  @apply flex max-w-[100px] flex-grow flex-col items-center rounded-lg bg-pure-white text-black md:max-w-full md:flex-row;

  p {
    @apply flex-grow px-2 py-4 text-center text-xs font-bold leading-4 md:px-5 md:py-4 md:text-left md:text-sm;
  }
}
</style>
