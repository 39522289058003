<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M3.74456 20.4805C3.23372 20.4805 2.80189 20.297 2.44906 19.93C2.09606 19.5629 1.91956 19.1239 1.91956 18.613C1.91956 18.121 2.09364 17.7009 2.44181 17.3528C2.78997 17.0046 3.21014 16.8305 3.70231 16.8305H3.91956V6.16953C3.91956 5.4312 4.17664 4.80495 4.69081 4.29078C5.20497 3.77661 5.83122 3.51953 6.56956 3.51953H20.5556C20.9236 3.51953 21.2364 3.64836 21.4941 3.90603C21.7517 4.1637 21.8806 4.47653 21.8806 4.84453C21.8806 5.2127 21.7517 5.52561 21.4941 5.78328C21.2364 6.04078 20.9236 6.16953 20.5556 6.16953H6.56956V16.8305H10.4056C10.9164 16.8305 11.3482 17.0069 11.7011 17.3598C12.0541 17.7128 12.2306 18.1447 12.2306 18.6555C12.2306 19.1664 12.0541 19.5982 11.7011 19.951C11.3482 20.304 10.9164 20.4805 10.4056 20.4805H3.74456ZM15.5556 20.4805C15.1874 20.4805 14.8745 20.3517 14.6168 20.094C14.3593 19.8364 14.2306 19.5235 14.2306 19.1555V9.49453C14.2306 9.12653 14.3593 8.8137 14.6168 8.55603C14.8745 8.29836 15.1874 8.16953 15.5556 8.16953H21.5556C21.9236 8.16953 22.2364 8.29836 22.4941 8.55603C22.7517 8.8137 22.8806 9.12653 22.8806 9.49453V19.1555C22.8806 19.5235 22.7517 19.8364 22.4941 20.094C22.2364 20.3517 21.9236 20.4805 21.5556 20.4805H15.5556ZM16.5978 16.8305H20.5131V10.537H16.5978V16.8305Z"
      fill="currentColor"
    />
  </svg>
</template>
