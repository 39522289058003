<script lang="ts">
import { usePrizesStore } from '@/stores/prizes'
import { type ProductOverview } from '@/types'
import { type PropType, defineComponent } from 'vue'

/**
 * A card to display a product overview.
 *
 */
export default defineComponent({
  props: {
    product: {
      type: Object as PropType<ProductOverview>,
      required: true,
    },
  },
  setup() {
    return {
      prizesStore: usePrizesStore(),
    }
  },
  methods: {
    planBadgeColour(planTier?: string): string {
      switch (planTier) {
        case 'vip':
          return 'bg-tertiary'

        case 'platinum':
          return 'bg-black'

        default:
          return 'bg-primary'
      }
    },
  },
})
</script>

<template>
  <div class="product-card relative flex flex-col bg-pure-white">
    <img
      :src="product.image.src"
      :alt="product.image.alt"
      class="aspect-square h-full w-full rounded-t-lg border border-b-0 border-slate-300"
    />

    <div
      class="flex flex-grow flex-col gap-2 rounded-b-lg border border-t-0 border-slate-300 px-5 py-6"
    >
      <span>
        <p class="text-lg font-extrabold">
          {{ product.title }}
        </p>

        <p class="line-clamp-3" :title="product.description">
          {{ product.description }}
        </p>
      </span>

      <div class="my-3 flex justify-between">
        <div class="text-xs font-bold">Available with:</div>
        <div class="flex gap-2">
          <template v-if="product.plans.length">
            <AppBadge
              v-for="(plan, key) in prizesStore.productPlans(product)"
              :key
              size="md"
              class="uppercase text-white"
              :class="planBadgeColour(plan.tier)"
            >
              {{ $t('membership_badge.' + plan.tier.toLocaleLowerCase()) }}
            </AppBadge>
          </template>
          <template v-if="!product.plans.length">
            <AppBadge
              size="md"
              class="uppercase text-white"
              :class="planBadgeColour()"
            >
              All
            </AppBadge>
          </template>
        </div>
      </div>

      <AppButton
        v-if="product.__type === 'Prize'"
        :route="{ name: 'prizes' }"
        class="mt-4 min-w-full font-semibold"
        :class="
          $userStore.isLoggedIn
            ? 'button-primary'
            : 'button-secondary button-secondary-light'
        "
        title="View prizes"
      >
        View prizes
      </AppButton>
      <AppButton
        v-if="product.__type === 'Benefit'"
        :route="{ name: $userStore.isLoggedIn ? 'benefits' : 'memberships' }"
        class="mt-4 min-w-full font-semibold"
        :class="
          $userStore.isLoggedIn
            ? 'button-primary'
            : 'button-secondary button-secondary-light'
        "
        :title="$userStore.isLoggedIn ? 'Claim' : 'Join to claim'"
      >
        {{ $userStore.isLoggedIn ? 'View benefits' : 'Join to claim' }}
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-card {
  @apply max-w-[300px];
}
</style>
