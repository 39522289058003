<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 26.0834L20 23L24 26.0834L22.5 21L26.25 18.3334H21.5834L20 13.1667L18.4167 18.3334H13.75L17.5 21L16 26.0834ZM9.70837 35L13.5834 22.3334L3.33337 15H16L20 1.66669L24 15H36.6667L26.4167 22.3334L30.2917 35L20 27.1667L9.70837 35Z"
      fill="currentColor"
    />
  </svg>
</template>
