import { type RouteMeta, useRouter } from 'vue-router'

export function useRouteMeta(): RouteMeta {
  const router = useRouter()
  const routeMeta = router?.currentRoute.value.meta

  return {
    ...routeMeta,
  }
}
