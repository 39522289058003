<script lang="ts">
import type { NullableError } from '@/types'
import { defineComponent } from 'vue'

import AppDrawer from '../drawers/AppDrawer.vue'
import ForgotPasswordDrawer from './ForgotPasswordDrawer.vue'

export default defineComponent({
  components: {
    AppDrawer,
    ForgotPasswordDrawer,
  },
  data() {
    return {
      form: {
        isLoading: false,
        data: {
          email: this.$userStore.user?.email ?? '',
          password: '',
          rememberMe: true,
        },
        errors: null as NullableError,
      },
    }
  },
  methods: {
    async logIn(isValid: boolean, closeDrawer: () => void) {
      if (!isValid) {
        return
      }

      this.form.isLoading = true

      try {
        const success = await this.$userStore.logIn(
          this.form.data.email,
          this.form.data.password,
          this.form.data.rememberMe
        )

        if (success) {
          closeDrawer()
        }
      } catch (e: any) {
        this.form.errors = e
      } finally {
        this.form.isLoading = false
      }
    },
  },
})
</script>

<template>
  <AppDrawer
    id="login-drawer"
    aria-labelledby="drawerTitle"
    aria-describedby="drawerDescription"
    :close-on-blur="false"
  >
    <template #button="{ openDrawer }">
      <slot name="button" :open-drawer>
        <slot v-if="$userStore.isLoggedIn" name="loggedIn" />
        <slot v-if="$userStore.isLoggedOut" name="loggedOut" />
      </slot>
    </template>
    <!--
            Modal panel, show/hide based on modal state.

            Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
    <!-- v-slot="{ methods: { closeModal } }" -->
    <template #default="{ closeDrawer }">
      <div
        class="login-drawer-content flex h-full flex-col bg-black text-pure-white"
      >
        <div
          class="flex h-full flex-col justify-center gap-6 px-6 py-6 lg:px-10"
        >
          <div>
            <h2 id="drawerTitle" class="heading-2 mb-3">
              {{ $t('label.login') }}
            </h2>

            <p id="drawerDescription" class="font-medium">
              Enter your details to access our Members Zone...
            </p>
          </div>

          <AppForm
            :data="form.data"
            :errors="form.errors"
            class="flex flex-col"
            @submit="(isValid: boolean) => logIn(isValid, closeDrawer)"
          >
            <template #default="{ errors }">
              <fieldset>
                <AppInput
                  v-model="form.data.email"
                  name="email"
                  type="email"
                  :required="true"
                  placeholder="Enter your Email"
                  :errors
                />

                <AppInput
                  v-model="form.data.password"
                  name="password"
                  type="password"
                  placeholder="Enter your Password"
                  :required="true"
                  :errors
                />

                <div class="mt-3 flex justify-between text-sm">
                  <AppToggle
                    v-model="form.data.rememberMe"
                    name="remember-me"
                    class="flex-shrink-0"
                    checked-background="bg-primary"
                  >
                    Remember Me
                  </AppToggle>

                  <ForgotPasswordDrawer>
                    <template #button="{ openDrawer }">
                      <button
                        type="button"
                        class="text-right font-bold underline hover:cursor-pointer hover:no-underline"
                        @click="openDrawer"
                      >
                        Forgot password?
                      </button>
                    </template>
                  </ForgotPasswordDrawer>
                </div>
              </fieldset>
            </template>

            <template #buttons="{ isSubmitting }">
              <AppButton
                class="button-primary mx-6 my-5"
                title="Login"
                type="submit"
                icon=""
                :is-loading="form.isLoading || isSubmitting"
              >
                Login
              </AppButton>
            </template>
          </AppForm>
        </div>

        <div
          class="flex w-full items-center justify-between bg-primary p-4 text-white"
        >
          <p class="font-bold">Don't have an account?</p>

          <AppButton
            class="button-primary button-thin text-xs"
            title="Join now"
            :route="{ name: 'memberships' }"
            icon-size="xs"
            @click="closeDrawer"
          >
            Join now
          </AppButton>
        </div>
      </div>
    </template>
  </AppDrawer>
</template>

<style lang="scss" scoped>
.login-drawer-content {
  @apply bg-cover bg-no-repeat;

  background-image: url('@/assets/images/login-background.svg');
}
</style>
