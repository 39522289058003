<script lang="ts">
import { defineComponent } from 'vue'

export enum Social {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Youtube = 'youtube',
  X = 'x',
}

export type SocialIconData = {
  icon: string
  text: string
  url: string
}

/**
 * Displays the social icons.
 */
export default defineComponent({
  props: {
    socials: {
      type: Array<Social>,
      default() {
        return []
      },
    },
  },
  computed: {
    socialsData(): Record<string, SocialIconData> {
      return {
        facebook: {
          icon: '/assets/images/socials/facebook.svg',
          text: 'Facebook',
          url: 'https://www.facebook.com/GoodLifePlusUK',
        },
        instagram: {
          icon: '/assets/images/socials/instagram.svg',
          text: 'Instagram',
          url: 'https://www.instagram.com/joingoodlifeplus/',
        },
        youtube: {
          icon: '/assets/images/socials/youtube.svg',
          text: 'YouTube',
          url: 'https://www.youtube.com/channel/UCR5VzXqBMkIeHsiIAmIT2ig',
        },
        x: {
          icon: '/assets/images/socials/x.svg',
          text: 'X',
          url: 'https://x.com/goodlifeplusuk',
        },
      }
    },
  },
})
</script>

<template>
  <div class="flex w-fit flex-col gap-2 text-center">
    <div class="flex gap-6">
      <a
        v-for="social in socials"
        :key="social"
        target="_blank"
        rel="noopener"
        :href="socialsData[social].url"
        :aria-label="`Visit ${BUSINESS_NAME} on ${socialsData[social].text}`"
        class="text-pure-white"
      >
        <img
          :src="socialsData[social].icon"
          role="presentation"
          :alt="`${socialsData[social].text} Logo`"
          class="text-pure-white"
        />
        <span class="sr-only">
          {{ socialsData[social].text }}
        </span>
      </a>
    </div>

    Join Our 400,000 Followers
  </div>
</template>

<style lang="scss" scoped>
img {
  width: 30px;
  height: 30px;
}
</style>
