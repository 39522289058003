import { useUserStore } from '@/stores/user'
import { Icons } from '@/utils/helper-objects'
import type { RouteRecordRaw } from 'vue-router'

import AuthMiddleware from './middleware/auth'

export default [
  {
    path: '/',
    beforeEnter: [AuthMiddleware],
    meta: {
      title: "Member's Zone",
    },
    children: [
      {
        path: '/member-zone',
        name: 'members-zone',
        component: () => import('../views/MembersZone/MembersZoneView.vue'),
        props() {
          const userStore = useUserStore()

          return {
            user: userStore.user,
          }
        },
        meta: {
          nav: {
            header: {
              displayMobile: () => {
                const userStore = useUserStore()
                return userStore.isLoggedIn
              },
              text: 'Members-zone',
              icon: Icons.MembersZone,
            },
          },
        },
      },
      {
        path: '/member-zone',
        component: () =>
          import('../views/MembersZone/MemberQuickLinksView.vue'),
        props() {
          const userStore = useUserStore()

          return {
            user: userStore.user,
          }
        },
        children: [
          {
            path: 'entries',
            component: () =>
              import('../views/MembersZone/MemberEntriesView.vue'),
            children: [
              {
                path: 'instant-wins',
                name: 'members-zone-instant-win-entries',
                meta: {
                  title: 'Instant Wins Entries',
                },
                props() {
                  const userStore = useUserStore()

                  return {
                    instantWinTickets:
                      userStore.user?.tickets?.instant_win?.draws ?? {},
                  }
                },
                component: () =>
                  import(
                    '../views/MembersZone/Entries/MemberInstantWinEntriesView.vue'
                  ),
              },
              {
                path: 'subscriptions',
                name: 'members-zone-subscription-entries',
                meta: {
                  title: 'Subscription Entries',
                },
                props() {
                  const userStore = useUserStore()

                  return {
                    tickets: userStore.tickets,
                    loyaltyTickets: userStore.loyaltyTickets,
                    drawTickets: userStore.drawTickets,
                  }
                },
                component: () =>
                  import(
                    '../views/MembersZone/Entries/MemberSubscriptionEntriesView.vue'
                  ),
              },
            ],
          },
          {
            path: 'balance-history',
            name: 'members-zone-balance-history',
            meta: {
              title: 'Balance Breakdown',
            },
            props() {
              const userStore = useUserStore()

              return {
                creditHistory: userStore.user?.credit.history ?? {},
              }
            },
            component: () =>
              import('../views/MembersZone/MemberBalanceHistoryView.vue'),
          },
          {
            path: 'balance-withdrawal',
            name: 'members-zone-balance-withdrawal',
            meta: {
              title: 'Request Balance Withdrawal',
            },
            props() {
              const userStore = useUserStore()

              return {
                cashableBalance: userStore.user?.credit.totals.cashable ?? 0,
              }
            },
            component: () =>
              import('../views/MembersZone/MemberBalanceWithdrawalView.vue'),
          },
          {
            path: 'loyalty',
            name: 'members-zone-loyalty',
            meta: {
              title: 'Loyalty',
            },
            component: () =>
              import('../views/MembersZone/MemberLoyaltyView.vue'),
          },
          // {
          //   path: 'refer',
          //   name: 'members-zone-refer',
          //   meta: {
          //     title: 'Refer A Friend',
          //   },
          //   component: () => import('../views/MembersZone/MemberReferView.vue'),
          // },
          {
            path: 'details',
            name: 'members-zone-details',
            meta: {
              title: 'Your Details',
            },
            component: () =>
              import('../views/MembersZone/MemberDetailsView.vue'),
          },
          {
            path: 'socials',
            name: 'members-zone-socials',
            meta: {
              title: 'Socials',
            },
            component: () =>
              import('../views/MembersZone/MemberSocialsView.vue'),
          },
        ],
      },
    ],
  },
] as Readonly<RouteRecordRaw[]>
