<script lang="ts">
import type { MenuItem } from '@/types'
import { type PropType, defineComponent } from 'vue'

/**
 * AppFilterMenuItem
 */
export default defineComponent({
  props: {
    menuItem: {
      type: Object as PropType<MenuItem>,
      required: true,
    },
  },
})
</script>

<template>
  <div
    class="flex flex-grow flex-col items-center justify-center border-b-4 p-3 hover:cursor-pointer"
  >
    <AppIcon v-if="menuItem.icon" :icon="menuItem.icon" />

    <div class="whitespace-nowrap text-center" :class="menuItem.class">
      {{ menuItem.text }}

      <div
        v-if="menuItem.subText"
        class="whitespace-nowrap"
        :class="menuItem.subTextClass"
      >
        {{ menuItem.subText }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.currently-selected * {
  @apply font-bold;
}
</style>
