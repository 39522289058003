<script lang="ts">
import { useRouteMeta } from '@/plugins/useRouteMeta'
import { useBenefitsStore } from '@/stores/benefits'
import { usePlansStore } from '@/stores/plans'
import type { AccordionContent, Plan } from '@/types'
import { type PropType, defineComponent } from 'vue'

/**
 * A button to upgrade to a higher plan.
 */
export default defineComponent({
  props: {
    /**
     * The currently logged in user's highest plan.
     * If user is on monthly plan, this will be the yearly plan for same tier, if exists.
     */
    userPlan: {
      type: Object as PropType<Plan>,
      required: true,
    },
    /**
     * The plan to upgrade to.
     */
    upgradePlan: {
      type: Object as PropType<Plan>,
      required: true,
    },
    /**
     * The type of upgrade to perform.
     */
    upgradeType: {
      type: String as PropType<'upgrade' | 'upsell'>,
      required: false,
    },
  },
  emits: ['close-modal'],
  setup() {
    return {
      plansStore: usePlansStore(),
      routeMeta: useRouteMeta(),
      benefitsStore: useBenefitsStore(),
    }
  },
  data() {
    return {
      isLoading: false,
      showConfirmation: false,
    }
  },
  computed: {
    planCost(): number {
      return Math.max(
        this.upgradePlan.discount_cost - this.userPlan.discount_cost,
        0
      )
    },
    upgradePlanFeatures(): Array<AccordionContent> {
      return this.upgradePlan.features.map((f: AccordionContent) => ({
        ...f,
        pre_icon: {
          icon: this.Icons.Lightening,
          class: 'text-tertiary',
        },
      }))
    },
    isOnBenefitsPage(): boolean {
      return this.$router.currentRoute.value.name === 'benefits'
    },
  },
  unmounted() {
    this.showConfirmation = false
  },
  methods: {
    async upgrade() {
      try {
        this.isLoading = true
        const success = await this.plansStore.upgrade(
          this.upgradePlan,
          this.userPlan,
          this.upgradeType
        )

        if (success) {
          // Force reload user details to get updated data
          await this.$userStore.fetchDetails(true)
          if (this.isOnBenefitsPage) {
            this.$emit('close-modal')
            this.$alertStore.showSuccessAlert({
              message: 'Subscription successfully upgraded.',
              buttonText: 'Continue',
            })
          } else {
            this.$router.push({ name: 'members-zone' })
          }
        }
      } catch (e: any) {
        this.$alertStore.showErrorAlert({
          message: e.error?.message ?? e.message,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>

<template>
  <AppButton
    :title="`Upgrade to ${upgradePlan.name}`"
    :is-loading="isLoading"
    class="button-primary mx-auto mt-6 w-full"
    @click="upgrade"
  >
    {{ `Upgrade to ${upgradePlan.name}` }}
  </AppButton>
</template>

<style lang="scss" scoped></style>
