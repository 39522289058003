<template>
  <div class="flex flex-col">
    <img src="@/assets/images/coming_soon_card.png" alt="Coming Soon" />
    <div class="mb-0.5 mt-2 flex h-5 w-full rounded-full bg-slate-200" />
    <div
      class="mb-2 mt-3 flex w-full items-center justify-between text-sm text-black"
    >
      <div class="text-base font-bold">Coming Soon</div>
    </div>
  </div>
</template>
