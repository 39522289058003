import { useCampaignsStore } from '@/stores/campaigns'
import type { RouteLocation } from 'vue-router'

/**
 * Fetches the featured campaign.
 * Setting the campaign to the route meta object (`featuredCampaign`)
 *
 * @param {RouteLocation} to
 */
export default async function fetchFeaturedCampaign(to: RouteLocation) {
  const campaignStore = useCampaignsStore()

  await campaignStore.fetchCampaigns()

  to.meta = {
    ...to.meta,
    featuredCampaign: campaignStore.featuredCampaign,
  }
}
