import fetcher from '@/services/fetcher'
import type {
  Benefit,
  ClaimedCode,
  Plan,
  TBenefitsCategory,
  TPlanTier,
} from '@/types'
import { BenefitCodes } from '@/utils/helper-objects'
import { isPlan } from '@/utils/type-guard-utils'
import { toPortalUrl } from '@/utils/url-generation-utils'
import { arrayWrap, isAvailableInPlan } from '@/utils/utils'
import * as Sentry from '@sentry/vue'
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'

import { usePlansStore } from './plans'
import { useUserStore } from './user'

type BenefitsStoreState = {
  benefits: Map<string, Benefit>
  posters: Array<{
    src: string
    alt: string
  }>
}

export const useBenefitsStore = defineStore('benefits', {
  state: (): BenefitsStoreState => ({
    benefits: new Map<string, Benefit>(),
    posters: [],
  }),

  getters: {
    benefitsArray(): Array<Benefit> {
      return Array.from(this.benefits.values())
    },
  },

  actions: {
    async fetchBenefits(): Promise<Array<Benefit>> {
      if (this.benefits.size) {
        return this.benefitsArray
      }

      try {
        await fetcher.get<Benefit>('/data/benefits.json', false, {
          key: 'code',
          map: this.benefits,
        })
      } catch (error: any) {
        Sentry.captureException(error)
      }

      return this.benefitsArray
    },
    benefitsByCategory(category: TBenefitsCategory): Array<Benefit> {
      return this.benefitsArray.filter(
        (benefit: Benefit) => benefit.category === category
      )
    },
    benefitPlans(benefit: Benefit): Array<Plan> {
      const plansStore = usePlansStore()
      const userStore = useUserStore()
      const userPeriod = userStore.highestPlanPeriod

      return benefit.plans
        .map((planType: string) =>
          plansStore.getPlanByTier(planType, userPeriod || undefined)
        )
        .filter((p): p is Plan => isPlan(p))
    },
    isAvailableInPlan(benefit: Benefit): boolean {
      const userStore = useUserStore()

      if (!userStore.user?.tier) {
        return false
      }

      if (!benefit.plans.length) {
        return true
      }

      return benefit.plans.some((planType: TPlanTier) => {
        return (
          userStore.highestPlanTier &&
          isAvailableInPlan(userStore.highestPlanTier, planType)
        )
      })
    },
    async redeem(type: string, subtype?: string): Promise<Array<ClaimedCode>> {
      const userStore = useUserStore()

      if (!userStore.user?.id) {
        throw Error('User must be logged in')
      }

      const user = userStore.user

      const { data, error } = await fetcher.post<{
        csi_content: {
          data?: Array<ClaimedCode>
          code?: string // Tastecard only
          status: number | string
        }
      }>(
        toPortalUrl(`accounts/redeem/${type}/${subtype ?? ''}`),
        {
          email: user.email,
          subtype: subtype ?? '',
          end_trial: userStore.isInTrial,
        },
        true
      )

      if (
        error.value ||
        !data.value ||
        data.value?.csi_content.status === 'error-no-subscription'
      ) {
        throw new Error(
          error.value?.message ??
            `Unable to redeem a ${type} code, please contact support.`
        )
      }

      let fetchedClaimedCodes: Array<ClaimedCode> = []

      // Need to handle tastecard differently as it returns a single, string code
      if (data.value.csi_content.data) {
        const redeemedCodes = data.value.csi_content.data as any

        if (type === BenefitCodes.Tastecard) {
          // TODO Have BE return Tastecard in an array & fix type
          fetchedClaimedCodes = [
            Object.assign(redeemedCodes, {
              claimed_at: DateTime.now(),
              customer_id: user.id,
              type: BenefitCodes.Tastecard,
              subtype: null,
            }),
          ]
        } else if (['movies', 'tickets'].includes(type)) {
          fetchedClaimedCodes = arrayWrap(redeemedCodes).map(
            (code: ClaimedCode) => {
              return Object.assign(code, {
                customer_id: user.id,
                type,
                subtype: null,
              })
            }
          )
        } else {
          fetchedClaimedCodes = arrayWrap(redeemedCodes)
        }
      }

      return userStore.setClaimedCodes(fetchedClaimedCodes)
    },
    async fetchPosters() {
      this.posters = [
        {
          src: '/assets/images/benefits/posters/sonic3.webp',
          alt: 'Sonic The Hedgehog 3',
        },
        {
          src: '/assets/images/benefits/posters/captain-america.jpg',
          alt: 'Captain America Brave new world',
        },
        {
          src: '/assets/images/benefits/posters/dog-man.jpg',
          alt: 'Dog Man',
        },
        {
          src: '/assets/images/benefits/posters/mufasa.jpeg',
          alt: 'Mufasa',
        },
        {
          src: '/assets/images/benefits/posters/paddington-in-peru.jpg',
          alt: 'Paddington in Peru',
        },
        {
          src: '/assets/images/benefits/posters/nosferatu.jpg',
          alt: 'Nosferatu',
        },
      ]
      // const { data, error } = await fetcher.get(
      //   toWordpressUrl('/movie-posters')
      // )

      // if (error.value) {
      //   Sentry.captureException('Error fetching movie posters from Wordpress')
      //   return
      // }

      // this.posters = data.value
    },
  },
})
