<script lang="ts">
import type { ListItem } from '@/types'
import { defineComponent } from 'vue'

/**
 * An unordered list.
 */
export default defineComponent({
  props: {
    items: {
      type: Array<ListItem>,
      required: true,
    },
  },
})
</script>

<template>
  <ol class="flex flex-col gap-2">
    <li v-for="(item, key) in items" :key class="flex">
      <AppIcon :icon="item.icon" class="h-6 w-6" :class="item.class" />

      <span
        class="ml-2"
        :class="{
          'line-through': item.icon === Icons.Times,
        }"
      >
        <span v-html="item.description" />
      </span>
    </li>
  </ol>
</template>
