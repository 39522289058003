import fetcher from '@/services/fetcher'
import type { Campaign, Nullable } from '@/types'
import { mapToDateTime } from '@/utils/date-time-utils'
import { toApiUrl } from '@/utils/url-generation-utils'
import { useGtm } from '@gtm-support/vue-gtm'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'

type CampaignsStoreState = {
  campaigns: Map<string, Campaign>
  fetchPromise: Promise<Array<Campaign>> | null
}

export const useCampaignsStore = defineStore('campaigns', {
  state: (): CampaignsStoreState => ({
    campaigns: new Map(),
    fetchPromise: null,
  }),

  getters: {
    /**
     * Returns the next upcoming, featured campaign.
     *
     * @returns {Nullable<Campaign>}
     */
    featuredCampaign(): Nullable<Campaign> {
      return (
        Array.from(this.campaigns.values()).find(
          (campaign: Campaign) =>
            campaign.is_featured && campaign.close_at.diffNow().milliseconds > 0
        ) ?? null
      )
    },
    campaignsArray({ campaigns }): Array<Campaign> {
      return Array.from(campaigns.values()).sort(
        (campaign1: Campaign, campaign2: Campaign) => {
          return campaign1.close_at.toMillis() - campaign2.close_at.toMillis()
        }
      )
    },
  },

  actions: {
    async fetchCampaign(campaignCode: string): Promise<Nullable<Campaign>> {
      if (this.campaigns.has(campaignCode)) {
        return this.campaigns.get(campaignCode) ?? null
      }

      try {
        await fetcher.get<Campaign>(
          `/data/campaigns/${campaignCode}.json`,
          false,
          {
            key: 'code',
            map: this.campaigns,
          }
        )

        return this.campaigns.get(campaignCode) ?? null
      } catch (error: any) {
        Sentry.captureException(error, {
          data: { campaignCode },
        })

        return null
      }
    },
    /**
     * Fetches all campaigns.
     */
    async fetchCampaigns() {
      // return the fetch campaign promise to prevent multiple calls
      if (this.fetchPromise) {
        return this.fetchPromise
      }

      // Fetch all prizes in the prizes folder
      this.fetchPromise = new Promise((resolve, reject) => {
        const campaigns = import.meta.glob('../data/campaigns/*.json')

        const fetchPromises = []

        for (const path in campaigns) {
          fetchPromises.push(
            campaigns[path]().then((campaign: any) => {
              const typedCampaign = mapToDateTime<Campaign>({
                ...campaign,
              })

              this.campaigns.set(typedCampaign.code, typedCampaign)
            })
          )
        }

        Promise.all(fetchPromises)
          .then(() => {
            resolve(this.campaignsArray)
          })
          .catch((e: any) => {
            reject(e)
          })
          .finally(() => {
            this.fetchPromise = null
          })
      })

      return this.fetchPromise ?? this.campaignsArray
    },
    async submitCampaign(listId: string, formData: any) {
      const { data, error } = await fetcher.post(
        toApiUrl('/subscribe-lead/email-phone/'),
        {
          list_id: listId,
          source: 'ORGANIC',
          ...formData,
        },
        true
      )

      if (error.value) {
        throw error.value
      }

      const gtm = useGtm()
      gtm?.trackEvent({
        event: 'lead',
      })

      return data.value?.success
    },
  },
})
