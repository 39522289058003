import { extractLaravelErrors } from '@/utils/input-validator-utils'
import { type Ref, ref, toValue } from 'vue'

export type ValidationErrors = [] | Array<string>
export type LaravelValidationErrors = Record<string, ValidationErrors>

/**
 * Represents a validation exception that can be thrown when validation fails.
 * Extends the built-in Error class.
 *
 * @see https://laravel.com/docs/11.x/validation#validation-error-response-format
 */
export class ValidationException extends Error {
  public response?: Response
  public errors?: Ref<LaravelValidationErrors>

  /**
   * Constructs a new ValidationException.
   *
   * @param {any} data - The data containing the validation error details.
   * @param {Response} [res] - Optional response object.
   */
  constructor(data: any, res?: Response) {
    if (res) {
      super(res.statusText)

      this.response = res.clone()
    } else {
      super('Validation Exception')
    }

    this.name = 'ValidationError'

    // Error.captureStackTrace(this, ValidationException)

    this.message = data.message
    this.errors = ref(data.errors)
  }

  /**
   * Retrieves the validation errors for a specific field.
   *
   * @param {string} field - The field name to get errors for.
   * @returns {ValidationErrors} - The validation errors for the specified field.
   */
  get(field: string): ValidationErrors {
    return this.errors ? extractLaravelErrors(field, toValue(this.errors)) : []
  }

  /**
   * Checks if there are validation errors for a specific field.
   *
   * @param {string} field - The field name to check for errors.
   * @returns {boolean} - True if there are errors for the specified field, false otherwise.
   */
  has(field: string): boolean {
    if (this.errors) {
      const fieldErrors = extractLaravelErrors(field, toValue(this.errors))

      return fieldErrors.length > 0
    }

    return false
  }

  /**
   * Retrieves the first validation error for a specific field.
   *
   * @param {string} field - The field name to get the first error for.
   * @returns {string} - The first validation error for the specified field.
   */
  first(field: string): string {
    if (this.has(field)) {
      const fieldErrors = this.get(field)

      return fieldErrors[0]
    }

    return ''
  }

  /**
   * Clears the validation errors for a specific field.
   *
   * @param {string} field - The field name to clear errors for.
   */
  clear(field: string): void {
    if (this.errors && this.has(field)) {
      delete this.errors.value[field]
    }
  }

  /**
   * Checks if there are any validation errors.
   *
   * @returns {boolean} - True if there are any validation errors, false otherwise.
   */
  get hasErrors(): boolean {
    return Object.values(this.errors?.value ?? {}).length > 0
  }
}
