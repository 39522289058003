<script lang="ts">
import { defineComponent } from 'vue'

/**
 * A modal to display YouTube videos.
 */
export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    modalId(): string {
      return `youtube-modal-${this.id}`
    },
  },
})
</script>

<template>
  <AppModal
    :id="modalId"
    class="h-[400px] w-[700px]"
    button-class="w-full"
    :show-close-button="false"
  >
    <template #button="{ openModal }">
      <slot :open-modal />
    </template>

    <template #default="{ isOpen }">
      <iframe
        v-if="isOpen"
        class="youtube-iframe"
        :src="`https://www.youtube.com/embed/${id}?autoplay=1&rel=0&controls=1&showinfo=0`"
        allowfullscreen
        frameborder="0"
        loading="lazy"
        allow="autoplay; encrypted-media"
        :title
      />
    </template>
  </AppModal>
</template>

<style lang="scss" scoped>
.youtube-iframe {
  @apply h-full w-full;
}
</style>
