<script setup lang="ts">
import { abbreviatedSha, branch } from '~build/git'

/**
 * Defines a banner to display on top of the page to determine what environment the page is running in.
 */
const MODE = import.meta.env.MODE
</script>

<template>
  <div id="env-banner" :class="MODE" class="text-xs hover:opacity-50">
    <strong>Env:</strong>&nbsp;<i>{{ MODE }}</i>
    <br />
    <strong>Branch:</strong>&nbsp;
    <i>{{ branch }} [{{ abbreviatedSha }}]</i>
  </div>
</template>

<style lang="scss" scoped>
#env-banner {
  z-index: 2147483638; // max z-index value
  position: fixed;
  top: 0;
  left: 0;
  padding: 6px 12px;
  border-bottom-right-radius: 10px;

  &.development {
    background-color: orangered;
  }

  &.staging {
    background-color: blueviolet;
  }
}
</style>
