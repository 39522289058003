<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M16.5003 29.3333L7.16699 20L9.03366 18.1333L15.167 24.2333V2.66663H17.8337V24.2333L23.967 18.1L25.8337 20L16.5003 29.3333Z"
      fill="currentColor"
    />
  </svg>
</template>
