<script lang="ts">
import { useRouteMeta } from '@/plugins/useRouteMeta'
import { Themes } from '@/utils/helper-objects'
import { defineComponent } from 'vue'

/**
 * A container section/banner to highlight a GLP feature.
 */
export default defineComponent({
  setup() {
    return {
      routeMeta: useRouteMeta(),
      Themes,
    }
  },
})
</script>

<template>
  <AppBanner
    v-if="routeMeta.featuredCampaign && $userStore.isLoggedOut"
    class="highlight-banner bg-primary text-white"
    :labelled-by="`${routeMeta.featuredCampaign.code}-heading`"
  >
    <div class="mx-auto flex w-full flex-col gap-6">
      <h2
        :id="`${routeMeta.featuredCampaign.code}-heading`"
        class="text-center text-xl font-bold"
      >
        Don't miss your chance to win the<br />{{
          routeMeta.featuredCampaign.name
        }}
      </h2>

      <AppButton
        class="button-primary mx-auto w-full"
        :route="{
          name: 'campaign',
          params: {
            campaignCode: routeMeta.featuredCampaign.code,
          },
        }"
        title="Enter for free"
        icon=""
      >
        Enter for free
      </AppButton>

      <slot />

      <AppTrustpilot
        :theme="Themes.Dark"
        class="mx-auto max-w-[400px] rounded-md bg-primary/80 px-3 py-3 md:px-10"
      />
    </div>
  </AppBanner>
</template>

<style lang="scss" scoped>
.highlight-banner {
  @apply bg-[length:auto_100%] bg-left bg-no-repeat;

  background-image: url('@/assets/images/highlight-background.svg');
}
</style>
