import { mapToDateTime } from '@/utils/date-time-utils'
import { ValidationException } from '@/validation-exception'
import * as Sentry from '@sentry/vue'
import {
  type AfterFetchContext,
  type UseFetchReturn,
  useFetch,
  useUrlSearchParams,
} from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'

type SetToMapOptions<T> = {
  key: keyof T | ((obj: T) => string)
  map: Map<string, T>
}

const AfterFetchParser = <T>(
  ctx: AfterFetchContext,
  setToMap?: SetToMapOptions<T>
) => {
  ctx.data = mapToDateTime(ctx.data)

  if (setToMap) {
    const data = ctx.data
    // check type and add to related store map
    if (typeof data === 'object') {
      let objects: Record<string, T>

      if (Object.prototype.hasOwnProperty.call(data, '__type')) {
        objects = { data }
      } else {
        objects = data
      }

      for (const value of Object.values(objects)) {
        const key =
          typeof setToMap.key === 'function'
            ? setToMap.key(value)
            : value[setToMap.key]

        setToMap.map.set(key as string, value)
      }
    }
  }

  return ctx
}

const OnFetchError = (ctx: {
  data: any
  response: Response | null
  error: any
}) => {
  let validationMessage: string | undefined

  // Check if data is directly the errors object
  const errorData = ctx.data?.errors || ctx.data

  // Display the first error message from the error object
  if (errorData && typeof errorData === 'object') {
    const values = Object.values(errorData as Record<string, string[]>)
    if (values.length > 0 && Array.isArray(values[0]) && values[0].length > 0) {
      validationMessage = values[0][0]
    }
  }

  // Managing different error structures
  const errorMessage =
    validationMessage ??
    ctx.data?.error?.message ??
    ctx.data?.error ??
    ctx.data?.message ??
    ctx.response?.statusText

  if (ctx.response?.status === 422) {
    ctx.error = new ValidationException(
      {
        message: errorMessage ?? 'Validation Error',
        errors: errorData ?? ctx.data,
      },
      ctx.response
    )
  } else {
    ctx.error = new Error(errorMessage)
  }

  Sentry.captureException(ctx.error)
  return ctx
}

/**
 * A wrapper class around the browser implementation of Fetch.
 * This will set sensible default configurations.
 *
 * @see https://vueuse.org/core/useFetch/
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#canceling_a_request
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 */
export default class {
  /**
   * POSTs a JSON stringified data object.
   *
   * @param {string} url The API endpoint to send the data to.
   * @param {any} body The body of data to send to the API (uses `JSON.Stringify` to convert the body object).
   * @param {boolean} useCredentials Sets the credentials header which sends the auth cookie.
   * @param {SetToMapOptions<T>} setToMapOptions Set to automatically update a store map.
   * @throws {Error} Thrown when the server encountes an issue and unable to return a valid response.
   * @throws {ValidationException} Thrown when the passed data failed server-side validation (response code: 422).
   * @returns {Promise<T>} The response data will be parsed as JSON.
   */
  static async post<T = any>(
    url: string,
    body: any,
    useCredentials: boolean = true,
    setToMapOptions?: SetToMapOptions<T>
  ): Promise<UseFetchReturn<T>> {
    const utmParams = useUrlSearchParams()
    const cookies = useCookies(['authToken'], {
      autoUpdateDependencies: true,
    })
    const authToken = cookies.get('authToken')

    // Use credentials if not in production for Cloudfare Access
    const shouldIncludeCredentials =
      import.meta.env.APP_ENV !== 'production' || (useCredentials && authToken)

    return useFetch(
      url,
      {
        mode: 'cors',
        headers: {
          Accept: 'application/json', // receive JSON
          'Content-Type': 'application/json', // send JSON
          ...(useCredentials && authToken
            ? {
                Authorization: 'Bearer ' + authToken,
              }
            : {}),
        },
        credentials: shouldIncludeCredentials ? 'include' : 'omit',
      },
      {
        timeout: 60000, // 1 minute
        updateDataOnError: true,
        refetch: true,
        afterFetch: (ctx: AfterFetchContext) =>
          AfterFetchParser(ctx, setToMapOptions),
        onFetchError(ctx: {
          data: any
          response: Response | null
          error: any
        }) {
          console.error('post::onFetchError', ctx.data, ctx.response, ctx.error)

          return OnFetchError(ctx)
        },
      }
    )
      .post({
        ...body,
        ...utmParams,
        list_id: utmParams._list ?? body.list_id,
        source: utmParams.utm_source ?? utmParams.source ?? body.source,
      })
      .json()
  }

  /**
   * Sends a data object via a GET request.
   * The data needs to be stringified.
   *
   * @param {string} url The API endpoint to send the data to.
   * @param {boolean} useCredentials Sets the credentials header which sends the auth cookie.
   * @param {SetToMapOptions<T>} setToMapOptions Set to automatically update a store map.
   * @throws {Error} Thrown when the server encountes an issue and unable to return a valid response.
   * @throws {ValidationException} Thrown when the passed data failed server-side validation (response code: 422).
   * @returns {Promise<T>} The response data will be parsed as JSON.
   */
  static async get<T = any>(
    url: string,
    useCredentials: boolean = true,
    setToMapOptions?: SetToMapOptions<T>
  ): Promise<UseFetchReturn<T>> {
    const cookies = useCookies(['authToken'], {
      autoUpdateDependencies: true,
    })
    const authToken = cookies.get('authToken')

    // Use credentials if not in production for Cloudfare Access
    const shouldIncludeCredentials =
      import.meta.env.APP_ENV !== 'production' || (useCredentials && authToken)

    return useFetch(
      url,
      {
        mode: 'cors',
        headers: {
          Accept: 'application/json', // receive JSON
          'Content-Type': 'application/json', // send JSON
          ...(useCredentials && authToken
            ? {
                Authorization: 'Bearer ' + authToken,
              }
            : {}),
        },
        credentials: shouldIncludeCredentials ? 'include' : 'omit',
      },
      {
        timeout: 60000, // 1 minute
        updateDataOnError: true,
        refetch: true,
        afterFetch: (ctx: AfterFetchContext) =>
          AfterFetchParser(ctx, setToMapOptions),
        onFetchError(ctx: {
          data: any
          response: Response | null
          error: any
        }) {
          console.error('get::onFetchError', ctx.data, ctx.response, ctx.error)

          return OnFetchError(ctx)
        },
      }
    )
      .get()
      .json()
  }

  static isOk(statusCode: number): boolean {
    return statusCode >= 200 && statusCode < 300
  }
}
