<script lang="ts">
import { ValidationException } from '@/validation-exception'
import { type PropType, defineComponent } from 'vue'

/**
 * A general component with common input functionality to extend more specific input types from.
 *
 * @see https://www.w3.org/WAI/tutorials/forms/
 * @see https://www.w3.org/TR/UNDERSTANDING-WCAG20/minimize-error-identified.html
 * @see https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/G89.html
 * @see https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/SCR32
 * @see https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/ARIA19.html
 * @see https://www.w3.org/TR/WCAG20-TECHS/ARIA18 On severe errors displays with a alertdialog
 * @see https://www.w3.org/TR/WCAG20-TECHS/ARIA21
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/alert/examples/alert/
 */
export default defineComponent({
  inheritAttrs: false,
  props: {
    /**
     * Text describing the reason for the input.
     */
    label: {
      type: String,
    },
    /**
     * Used as a reactive floating text when the input has focus.
     */
    useFloatingPlaceholder: {
      type: Boolean,
      default: true,
    },
    /**
     * The name of the input sent to the API endpoint.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Text used when the input is empty.
     */
    placeholder: {
      type: String,
    },
    /**
     * Text used by browsers to autofill the input.
     */
    autocomplete: {
      type: String,
    },
    /**
     * Text displayed below the input element.
     */
    hint: {
      type: String,
    },
    /**
     * Moves the label to the left-hand side of the input.
     */
    inline: {
      type: Boolean,
    },
    /**
     * @model
     */
    modelValue: {
      type: [Number, String, null],
      required: true,
    },
    /**
     * Displays input validation errors returned.
     */
    errors: {
      type: Object as PropType<null | ValidationException>,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value: Number | String) {
        this.$emit('update:modelValue', value)
      },
    },
    required(): boolean {
      return (this.$attrs.required ?? false) as boolean
    },
    disabled(): boolean {
      return (this.$attrs.disabled ?? false) as boolean
    },
    hasErrors(): boolean {
      return this.inputErrors.length > 0
    },
    inputErrors() {
      return this.errors instanceof ValidationException
        ? this.errors.get(this.name)
        : []
    },
  },
})
</script>

<template>
  <div
    :class="[
      disabled ? 'opacity-50' : '',
      inline ? 'flex-row items-center' : 'flex-col',
    ]"
    class="relative mb-3 flex flex-grow gap-2"
  >
    <template v-if="label && !useFloatingPlaceholder">
      <label class="block text-sm font-bold" :for="name">
        {{ label }}
        <span v-if="required" class="text-xs">*</span>
      </label>
    </template>

    <input
      :id="name"
      v-bind="$attrs"
      ref="input"
      v-model="value"
      :aria-errormessage="name + '-errors'"
      :aria-describedBy="name + '-hint'"
      :aria-invalid="hasErrors"
      :autocomplete="autocomplete || name"
      :class="[
        hasErrors ? 'border-red-500 !pr-12' : 'focus:border-transparent',
        disabled ? 'hover:cursor-not-allowed' : '',
        useFloatingPlaceholder ? 'placeholder-transparent' : '',
      ]"
      :name
      :placeholder
      class="form-element peer form-input text-black"
    />
    <AppIcon
      :icon="Icons.Error"
      class="pointer-events-none absolute right-4 top-4 text-red-500"
      v-if="hasErrors"
    />

    <template v-if="placeholder && useFloatingPlaceholder">
      <label :for="name" class="floating-label">
        {{ placeholder }}
        <span v-if="required" class="text-xs text-slate-500"> * </span>
      </label>
    </template>

    <div
      v-if="hasErrors"
      :id="name + '-errors'"
      :aria-live="hasErrors ? 'assertive' : 'off'"
      aria-atomic="true"
      class="errors"
    >
      <div
        v-for="error in inputErrors"
        :key="error"
        class="text-xs font-bold text-red-500"
        v-html="error"
      />
    </div>

    <div v-if="hint && !hasErrors" :id="name + '-hint'" class="text-xs">
      {{ hint }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.floating-label {
  @apply pointer-events-none absolute left-4 top-1 flex w-max items-center gap-1 text-xs text-slate-500 transition-all peer-placeholder-shown:top-4.5 peer-placeholder-shown:text-base peer-focus:top-1 peer-focus:text-xs;
}

.required {
  @apply text-slate-500;
}

.bg-black .required {
  @apply text-pure-white;
}
</style>
