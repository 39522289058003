import { useAlertStore } from '@/stores/alert'
import { useCampaignsStore } from '@/stores/campaigns'
import { usePlansStore } from '@/stores/plans'
import { useUserStore } from '@/stores/user'
import type { Campaign, Nullable, Plan, Prize } from '@/types'
import { Icons } from '@/utils/helper-objects'
import { isEmpty } from '@/utils/utils'
import { useUrlSearchParams } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies.mjs'
import {
  type NavigationGuardNext,
  type RouteLocation,
  type RouteRecordRaw,
  useRouter,
} from 'vue-router'

import HomeView from '../views/HomeView.vue'
import instantWinRoutes from './instant-win-routes'
import membersZoneRoutes from './members-zone-routes'
import termsAndConditionsRoutes from './terms-and-conditions-routes'

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      nav: {
        header: { display: false },
      },
    },
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/ProductView.vue'),
    meta: {
      nav: {
        header: { display: false },
      },
    },
  },
  {
    path: '/subscription-platinum-35',
    name: 'affiliate-subscription-platinum-35',
    component: () =>
      import('../views/Affiliates/SubscriptionPlatinum35View.vue'),
    meta: {
      nav: {
        header: { display: false },
      },
    },
  },
  {
    path: '/benefits',
    name: 'benefits',
    component: () => import('../views/BenefitsView.vue'),
    meta: {
      title: 'Membership Benefits',
      nav: {
        header: {
          display: true,
          displayMobile: true,
          text: 'Benefits',
          icon: Icons.MoneyBag,
        },
        footer: {
          display: true,
          text: 'Benefits',
          category: 'company',
        },
      },
    },
  },
  {
    path: '/prizes',
    name: 'prizes',
    component: () => import('../views/PrizesView.vue'),
    meta: {
      title: 'Prizes',
      nav: {
        header: {
          display: true,
          displayMobile: true,
          text: 'Prizes',
          icon: Icons.Diamond,
        },
        footer: {
          display: true,
          text: 'Prizes',
          category: 'company',
        },
      },
    },
  },
  {
    path: '/prize/:prizeCode/:listId?',
    name: 'prize',
    component: () => import('../views/PrizeView.vue'),
    meta: {
      title: (
        to: RouteLocation & {
          meta: { props: { prize: Prize; listId: string } }
        }
      ): string => {
        return `Prize: ${(to.meta.props.prize as Prize).title}`
      },
      nav: {
        header: { display: false },
      },
    },
    props: (route: { meta: { props: { prize: Prize; listId: string } } }) => {
      return {
        prize: route.meta.props.prize,
        listId: route.meta.props.listId,
      }
    },
  },
  {
    path: '/live-draws',
    name: 'live-draws',
    component: () => import('../views/LiveDrawsView.vue'),
    meta: {
      title: 'Live Draws',
      nav: {
        header: {
          display: true,
          displayMobile: true,
          text: 'Live Streams + Winners',
          icon: Icons.Trophy,
        },
        footer: {
          display: true,
          text: 'Live Streams + Winners',
          category: 'company',
        },
      },
    },
  },
  {
    path: '/live-draws/:prizeCode',
    name: 'live-draw',
    component: () => import('../views/LiveDrawView.vue'),
    meta: {
      title: (
        to: RouteLocation & {
          meta: { props: { prize: Prize } }
        }
      ): string => {
        return `Live Draw: ${(to.meta.props?.prize as Prize)?.title}`
      },
      nav: {
        header: { display: false },
      },
    },
    props: (route: { meta: { props: { prize: Prize } } }) => {
      return {
        prize: route.meta.props.prize,
      }
    },
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: () => import('../views/ThankYouView.vue'),
    meta: {
      title: 'Thank You',
    },
  },
  {
    path: '/good-causes',
    name: 'good-causes',
    component: () => import('../views/SponsorshipView.vue'),
    meta: {
      title: 'Good Causes',
      nav: {
        header: {
          display: true,
          displayMobile: true,
          text: 'Good Causes',
          icon: Icons.Diversity,
        },
        footer: {
          display: true,
          text: 'Good Causes',
          category: 'company',
        },
      },
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/faq/FaqView.vue'),
    meta: {
      title: 'Frequently Asked Questions',
      nav: {
        footer: {
          display: true,
          text: 'FAQ',
          category: 'support',
        },
      },
    },
  },
  {
    path: '/faq/:slug',
    name: 'faq-details',
    component: () => import('../views/faq/FaqDetails.vue'),
    meta: {
      title: 'Frequently Asked Questions',
      nav: {
        footer: {
          display: false,
        },
      },
    },
  },
  {
    path: '/campaign/:campaignCode/:listId?',
    name: 'campaign',
    component: () => import('../views/CampaignView.vue'),
    meta: {
      title: (
        to: RouteLocation & {
          meta: { props: { campaign: Campaign } }
        }
      ): string => {
        return `Campaign: ${(to.meta.props.campaign as Campaign).name}`
      },
    },
    props: (route: {
      meta: { props: { campaign: Campaign; listId?: string } }
    }) => {
      return {
        campaign: route.meta.props.campaign,
        listId: route.meta.props.listId,
      }
    },
    async beforeEnter(
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) {
      // check for defined campaigns & redirect
      try {
        const campaignCode = to.params.campaignCode

        if (!campaignCode) {
          return next({ name: 'not-found' })
        }

        const campaignStore = useCampaignsStore()
        const queryParams = useUrlSearchParams()

        const campaign = await campaignStore.fetchCampaign(
          campaignCode as string
        )

        let listId

        if (!isEmpty(queryParams._list)) {
          listId = queryParams._list as string
        } else if (!isEmpty(to.params.listId)) {
          listId = to.params.listId as string
        }

        if (campaign) {
          to.meta.props = { campaign, listId }
        } else {
          throw new Error(`Unknown campaign code: ${campaignCode}`)
        }

        next()
      } catch (e) {
        console.error(e)
        // redirect to NotFoundView
        return next({ name: 'not-found' })
      }
    },
  },

  {
    path: '/membership-levels-20',
    name: 'membership-levels-20',
    component: () => import('../views/Discounts/Memberships20View.vue'),
    meta: {
      title: 'Membership Levels 20% Off',
      nav: {
        header: { display: false },
      },
    },
  },
  {
    path: '/product-vip-50',
    name: 'product-vip-50',
    component: () => import('../views/Discounts/MembershipVip50View.vue'),
    meta: {
      title: '50% Off Vip Membership',
      nav: {
        header: { display: false },
      },
    },
  },
  {
    path: '/memberships',
    name: 'memberships',
    component: () => import('../views/MembershipsView.vue'),
    meta: {
      title: 'Memberships',
    },
  },
  {
    path: '/secure-checkout',
    name: 'checkout',
    component: () => import('../views/Checkout/CheckoutView.vue'),
    meta: {
      title: 'Checkout',
    },
    props: (route) => {
      return {
        plan: route.meta.props,
      }
    },
    async beforeEnter(
      to: RouteLocation,
      from: RouteLocation,
      next: NavigationGuardNext
    ) {
      try {
        const plansStore = usePlansStore()

        let plan: Nullable<Plan> = plansStore.getPlanByTier('standard')

        if (!isEmpty(to.query.plan)) {
          plan = plansStore.getPlan(
            to.query.plan as string,
            Number(to.query.affiliate as string)
          )
        }

        if (!plan) {
          console.error('no plan found')
          return next({ name: 'not-found' })
        }

        to.meta.props = plan

        next()
      } catch (e) {
        console.error(e)
        // redirect to NotFoundView
        return next({ name: 'not-found' })
      }
    },
  },
  {
    path: '/payment-recovery',
    name: 'payment-recovery',
    component: () => import('../views/PaymentRecoveryView.vue'),
    meta: {
      title: 'Payment Recovery',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/PasswordResetView.vue'),
    meta: {
      title: 'Password Reset',
    },
  },
  {
    path: '/request-password-reset',
    name: 'request-password-reset',
    component: () => import('../views/PasswordResetRequestView.vue'),
    meta: {
      title: 'Password Reset',
    },
  },
  {
    path: '/request-password-reset',
    name: 'request-password-reset-immediate',
    component: () => import('../views/PasswordResetRequestImmediateView.vue'),
    meta: {
      title: 'Password Reset',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      title: 'Logout',
    },
    beforeEnter: async () => {
      const alertStore = useAlertStore()

      try {
        const userStore = useUserStore()
        const router = useRouter()
        const success = await userStore.logOut()

        if (success) {
          await router.push({ name: 'home' })
        } else {
          alertStore.showErrorAlert()
        }
      } catch (e: any) {
        alertStore.showErrorAlert({ message: e.message })
      }
    },
  },
  ...membersZoneRoutes,
  ...instantWinRoutes,
  ...termsAndConditionsRoutes,
  {
    path: '/impersonate',
    name: 'impersonate',
    beforeEnter: async (to: RouteLocation) => {
      const router = useRouter()
      const alertStore = useAlertStore()
      const cookies = useCookies(['authToken', 'impersonated'], {
        autoUpdateDependencies: true,
      })

      if (!to.query.token) {
        alertStore.showErrorAlert({ message: 'Invalid token' })

        await router.push({ name: 'home' })
      }

      cookies.set('authToken', to.query.token, { path: '/' })
      cookies.set('impersonated', true, { path: '/' })

      await router.push({ name: 'members-zone' })
    },
  },
  {
    // https://router.vuejs.org/guide/essentials/dynamic-matching#Catch-all-404-Not-found-Route
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/NotFoundView.vue'),
    // // preserve current path and remove the first char to avoid the target URL starting with `//`
    // params: { pathMatch: route.path.substring(1).split('/') },
    // // preserve existing query and hash if any
    // query: route.query,
    // hash: route.hash,
  },
] as Readonly<RouteRecordRaw[]>
