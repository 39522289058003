<template>
  <AppButton
    :route="{
      name: 'memberships',
    }"
    class="memberships-button button-primary mx-auto w-full"
    :title="$t('button.see.free_for_7_days')"
  >
    {{ $t('button.see.free_for_7_days') }}
  </AppButton>
</template>
