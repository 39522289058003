<script lang="ts">
import WinnerCard from '@/components/cards/WinnerCard.vue'
import { useWinnersStore } from '@/stores/winners'
import type { Winner } from '@/types'
import { defineComponent } from 'vue'

/**
 * Winners Section.
 */
export default defineComponent({
  components: {
    WinnerCard,
  },
  setup() {
    const winnersStore = useWinnersStore()

    return {
      winnersStore,
    }
  },
  data() {
    return {
      winners: [] as Array<Winner>,
    }
  },
  async mounted() {
    await this.winnersStore.fetchWinners()

    this.winners = this.winnersStore.highlightedWinners
  },
})
</script>

<template>
  <AppSection class="dark-section">
    <h2 class="heading-2">
      <slot name="heading" />
    </h2>
    <p><slot name="sub-heading" /></p>

    <AppSlider slider-id="winners-slider" class="full-bleed-mobile">
      <WinnerCard v-for="(winner, key) in winners" :key :winner />
    </AppSlider>

    <AppUpgradeModal
      v-if="$userStore.showUpgradePlan"
      :user-plan="$userStore.highestPlan"
      :upgrade-plan="
        $plansStore.getPlanByTier('vip', $userStore.highestPlan?.period)
      "
    />
    <AppMembershipButton v-if="$userStore.isLoggedOut" />
  </AppSection>
</template>

<style scoped>
:deep(.heading) {
  @apply capitalize;
}
</style>
