import { i18n } from '@/i18n'
import fetcher from '@/services/fetcher'
import type {
  InstantWinMainPrize,
  MenuItem,
  Plan,
  Prize,
  ProductOverview,
} from '@/types'
import { isToday, mapToDateTime } from '@/utils/date-time-utils'
import { Icons, PrizeCategories } from '@/utils/helper-objects'
import { isPlan } from '@/utils/type-guard-utils'
import { toApiUrl } from '@/utils/url-generation-utils'
import { useGtm } from '@gtm-support/vue-gtm'
import * as Sentry from '@sentry/vue'
import { DateTime, Interval } from 'luxon'
import { defineStore } from 'pinia'

import { useCampaignsStore } from './campaigns'
import { usePlansStore } from './plans'
import { useWinnersStore } from './winners'

type PrizesStoreState = {
  prizes: Map<string, Prize>
  fetchPromise: Promise<Array<Prize>> | null
}

export type PrizeDetails = {
  faq: Prize['faq']
  description: Prize['description']
}

export const usePrizesStore = defineStore('prizes', {
  state: (): PrizesStoreState => ({
    prizes: new Map<string, Prize>(),
    fetchPromise: null,
  }),

  actions: {
    /**
     * Loads prizes and sorts by drawn datetime.
     *
     * @returns {Promise<Array<Prize>>}
     */
    async fetchPrizes(withWinners: boolean = true): Promise<Array<Prize>> {
      // return the fetch prizes promise to prevent multiple calls
      if (this.fetchPromise) {
        return this.fetchPromise
      }

      // return all prizes if they've already been fetched
      // if (this.prizes.size > 0) {
      //   return this.prizesArray
      // }

      const winnersStore = useWinnersStore()

      const intervalPlusSixMonths = Interval.fromDateTimes(
        DateTime.now().minus({ month: 2 }),
        DateTime.now().plus({ month: 6 })
      )

      // Generate a regex to match all prizes in the past 2 months and within the next 6 months
      const prizesRegex =
        '(' +
        intervalPlusSixMonths
          .splitBy({ months: 1 })
          .map((interval: Interval) => {
            return interval.start?.toFormat('yyyyMM')
          })
          .join('|') +
        ')*\\.json'

      // Fetch all prizes in the prizes folder
      this.fetchPromise = new Promise((resolve, reject) => {
        const prizes = import.meta.glob('../data/prizes/*.json', {
          import: 'default',
        })
        const fetchPromises = []

        for (const path in prizes) {
          if (!path.match(prizesRegex)) {
            continue
          }

          fetchPromises.push(
            prizes[path]().then((prize: any) => {
              const typedPrize = mapToDateTime<Prize>({ ...prize })

              if (
                typedPrize.value >= this.BIG_PRIZE_VALUE ||
                isToday(typedPrize.drawn_at)
              ) {
                typedPrize.categories.push(PrizeCategories.Featured)
              }

              this.prizes.set(typedPrize.code, typedPrize)

              if (withWinners) {
                return winnersStore.fetchWinnerByPrizeCode(prize.code)
              }
            })
          )
        }

        Promise.all(fetchPromises)
          .then(() => {
            resolve(this.prizesArray)
          })
          .catch((e: any) => {
            reject(e)
          })
          .finally(() => {
            this.fetchPromise = null
          })
      })

      return this.fetchPromise ?? this.prizesArray
    },
    async fetchPrize(prizeCode: string): Promise<Prize | null> {
      if (this.prizes.has(prizeCode)) {
        return this.prizes.get(prizeCode) ?? null
      }

      try {
        await fetcher.get<Prize>(`/data/prizes/${prizeCode}.json`, false, {
          key: 'code',
          map: this.prizes,
        })

        const prize = this.prizes.get(prizeCode)

        if (!prize) {
          return null
        }

        if (prize.value >= this.BIG_PRIZE_VALUE || isToday(prize.drawn_at)) {
          prize.categories.push(PrizeCategories.Featured)
        }

        return this.prizes.get(prizeCode) ?? null
      } catch (error: any) {
        Sentry.captureException(error, {
          data: { prizeCode },
        })

        return null
      }
    },

    /**
     * Returns all prizes within a specified category.
     *
     * @param {string} category
     * @returns {Array<Prize>}
     */
    prizesByCategory(category: string): Array<Prize> {
      return this.prizesArray.filter((prize: Prize) =>
        prize.categories.includes(category)
      )
    },

    /**
     * Checks if a prize is currently being drawn.
     * Compares the current datetime with drawn datetime + 30minutes.
     *
     * @param {Prize} prize
     * @returns {boolean}
     */
    isDrawLive(prize: Prize): boolean {
      const drawPeriod = Interval.fromDateTimes(
        prize.drawn_at.minus({ minute: 5 }),
        prize.drawn_at.plus({ minute: 15 })
      )

      return drawPeriod.contains(DateTime.now())
    },

    /**
     * Creates an array of MenuItems based on an array of Prizes.
     *
     * @param {Array<Prize>} prizes
     * @returns {Array<MenuItem>}
     */
    generateCategories(
      prizes: Array<Prize | InstantWinMainPrize>
    ): Array<MenuItem> {
      const categories = new Map<string, MenuItem>()

      categories.set(PrizeCategories.Featured, {
        icon: Icons.Diamond,
        text: PrizeCategories.Featured,
        code: PrizeCategories.Featured,
        class: 'text-sm',
      })

      categories.set(PrizeCategories.AllPrizes, {
        icon: Icons.Prize,
        text: PrizeCategories.AllPrizes,
        code: PrizeCategories.AllPrizes,
        class: 'text-sm',
      })

      categories.set(PrizeCategories.InstantWins, {
        icon: Icons.Lightening,
        text: PrizeCategories.InstantWins,
        code: PrizeCategories.InstantWins,
        class: 'text-sm',
      })

      for (const prize of prizes) {
        for (const prizeCategory of prize.categories) {
          if (categories.has(prizeCategory)) {
            continue
          }

          categories.set(prizeCategory, {
            icon: prizeCategory,
            text: prizeCategory,
            code: prizeCategory,
            class: 'text-sm',
          })
        }
      }

      return Array.from<MenuItem>(categories.values())
    },

    generateDetails(
      prize: Pick<
        Prize,
        'faq' | 'description' | 'categories' | 'value' | 'title'
      >
    ): PrizeDetails {
      const faq = prize.faq
      let description = prize.description

      if (!description && prize.categories.includes(PrizeCategories.Cash)) {
        description = i18n.global.t(
          'page.prizes.generic_details.cash.description',
          {
            value: i18n.global.n(prize.value, 'currency'),
          }
        )
      }

      if (
        !description &&
        prize.categories.includes(PrizeCategories.DreamCars)
      ) {
        description = i18n.global.t(
          'page.prizes.generic_details.car.description',
          {
            car: prize.title,
          }
        )
      }

      if (
        !description &&
        prize.categories.includes(PrizeCategories.Experiences)
      ) {
        description = i18n.global.t(
          'page.prizes.generic_details.experience.description',
          {
            experience: prize.title,
          }
        )
      }

      if (!description && prize.categories.includes(PrizeCategories.GetAways)) {
        description = i18n.global.t(
          'page.prizes.generic_details.get_away.description',
          {
            get_away: prize.title,
          }
        )
      }

      // if (!description && prize.categories.includes(PrizeCategories.Technology)) {
      //   description =
      //     description ??
      //     i18n.global.t('page.prizes.generic_details.technology.description', {
      //       technology: prize.title,
      //     })
      // }

      return {
        faq,
        description,
      }
    },

    async submitPrize(listId: string, formData: any) {
      const { data, error } = await fetcher.post(
        toApiUrl('/subscribe-lead/email-phone/'),
        {
          list_id: listId,
          source: 'ORGANIC',
          ...formData,
        },
        true
      )

      if (error.value) {
        throw error.value
      }

      const gtm = useGtm()
      gtm?.trackEvent({
        event: 'lead',
      })

      return data.value?.success
    },

    productPlans(product: ProductOverview): Array<Plan> {
      const plansStore = usePlansStore()

      return product.plans
        .map((planType: string) => plansStore.getPlanByTier(planType))
        .filter((p): p is Plan => isPlan(p))
    },
  },

  getters: {
    prizesArray({ prizes }): Array<Prize> {
      return Array.from(prizes.values()).sort(
        (prize1: Prize, prize2: Prize) => {
          return prize1.drawn_at.toMillis() - prize2.drawn_at.toMillis()
        }
      )
    },
    futurePrizes(): Array<Prize> {
      return this.prizesArray.filter(
        (prize: Prize) => prize.drawn_at.diffNow().milliseconds >= 0
      )
    },
    pastPrizes(): Array<Prize> {
      return this.prizesArray
        .filter((prize: Prize) => prize.drawn_at.diffNow().milliseconds < 0)
        .reverse()
    },
    nextPrize(): null | Prize {
      return (
        this.prizesArray.filter(
          (prize: Prize) => prize.drawn_at.diffNow().milliseconds >= 0
        )?.[0] ?? null
      )
    },
    productOverviews(): Array<ProductOverview> {
      const campaignsStore = useCampaignsStore()
      const featuredCampaignImage = campaignsStore.featuredCampaign?.main_image

      return [
        {
          __type: 'Prize',
          code: 'monthly-draws',
          title: '30+ prize draws monthly',
          description:
            'Automatically enter to win luxury cars, holidays, and more!',
          image: {
            src:
              featuredCampaignImage?.mobile ??
              '/assets/images/prizes/mercedes-c63/mercedes-c63-1_1.jpg',
            alt: featuredCampaignImage?.alt ?? 'Mercedes C63 AMG',
          },
          plans: [],
        },
        {
          __type: 'Benefit',
          code: 'tasty-savings',
          title: 'Tasty savings are waiting',
          description: '2-for-1 meals and discounts with Tastecard.',
          image: {
            src: '/assets/images/benefits/pizza-express.jpg',
            alt: 'Pizza Express Logo',
          },
          plans: [],
        },
        {
          __type: 'Benefit',
          code: 'exclusive-treats',
          title: 'Exclusive treats',
          description:
            'Choose Greggs, Costa, Krispy Kreme, Deliveroo, Tesco, and more!',
          image: {
            src: '/assets/images/benefits/greggs-baguette.png',
            alt: 'Greggs Baguette',
          },
          plans: ['platinum', 'vip'],
        },
        {
          __type: 'Benefit',
          code: 'vip-perks',
          title: 'VIP movie perks',
          description: 'Get 2 Vue cinema tickets every month.',
          image: {
            src: '/assets/images/benefits/vue.png',
            alt: 'Vue Cinema Logo',
          },
          plans: ['vip'],
        },
      ]
    },
  },
})
