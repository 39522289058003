<script lang="ts">
import type { SwitchOptions, TTheme } from '@/types'
import { Themes } from '@/utils/helper-objects'
import { type PropType, defineComponent } from 'vue'

/**
 * A switch element which toggles between different options.
 */
export default defineComponent({
  props: {
    /**
     * Name of the switch element.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Model prop to v-bind to.
     */
    modelValue: {
      type: String,
      required: true,
    },
    /**
     * Possible options.
     */
    options: {
      type: Array<SwitchOptions>,
      required: true,
    },
    /**
     * The theme to colorise the component.
     */
    theme: {
      type: String as PropType<TTheme>,
      default: Themes.Light,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value: string) {
        this.$emit('update:modelValue', value)
      },
    },
  },
})
</script>

<template>
  <div class="switch-options rounded-100 bg-slate-300 p-1" :class="theme">
    <label
      v-for="(option, key) in options"
      :key
      v-tooltip="{
        content: option.tooltip,
        shown: true,
        flip: false,
        popperClass: 'tertiary',
      }"
      v-bind:class="{
        'selected font-bold': option.value === value,
      }"
      :for="`${name}-${key}`"
      class="relative inline-flex w-full items-center justify-center px-4 py-1 hover:cursor-pointer"
    >
      <input
        :id="`${name}-${key}`"
        v-model="value"
        :name="`${name}-${key}`"
        :value="option.value"
        type="radio"
      />

      {{ option.label }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
input[type='radio'] {
  appearance: none;
  display: none;
}

.dark-section .switch-options,
.dark.switch-options {
  @apply relative flex bg-slate-600 text-center text-pure-white;

  &::before {
    @apply bg-pure-white;
  }

  .selected {
    @apply text-black;
  }
}

.switch-options {
  @apply relative flex text-center;

  &:has(.selected:nth-child(1))::before {
    @apply origin-left translate-x-0;
  }

  &:has(.selected:nth-child(2))::before {
    @apply origin-right translate-x-full;
  }

  &::before {
    content: '';
    width: calc(50% - theme('padding.1'));
    height: calc(100% - theme('padding.2'));
    transition: 0.2s linear transform;

    @apply absolute left-1 top-1 block rounded-100 bg-pure-white;
  }
}
</style>
