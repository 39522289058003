import { useInstantWinsStore } from '@/stores/instant-wins'
import { usePlansStore } from '@/stores/plans'

export const fetchPlans = async function () {
  try {
    const plansStore = usePlansStore()
    const instantWinsStore = useInstantWinsStore()

    await Promise.all([
      plansStore.fetchAllPlans(),
      instantWinsStore.fetchInstantWins(),
    ])

    return true
  } catch (e) {
    console.error(e)
    // redirect to NotFoundView
    return 'not-found'
  }
}
