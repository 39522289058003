<script lang="ts">
import { useCountdown } from '@/plugins/useCountdown'
import { type PrizeDetails, usePrizesStore } from '@/stores/prizes'
import type { Prize } from '@/types'
import { isToday, isTomorrow } from '@/utils/date-time-utils'
import { DateTime, Interval } from 'luxon'
import { type PropType, defineComponent } from 'vue'

/**
 * A card to display a prize.
 *
 */
export default defineComponent({
  props: {
    prize: {
      type: Object as PropType<Prize>,
      required: true,
    },
  },
  setup() {
    return {
      prizesStore: usePrizesStore(),
    }
  },
  data() {
    return {
      countdown: useCountdown(this.endCallback),
    }
  },
  computed: {
    details(): PrizeDetails {
      return this.prizesStore.generateDetails(this.prize)
    },
    header(): Partial<{ text: string; class: string }> {
      if (isToday(this.prize.drawn_at)) {
        return {
          text: 'Ends Today',
          class: 'bg-pastel-blue',
        }
      }

      if (isTomorrow(this.prize.drawn_at)) {
        return {
          text: 'Ends Tomorrow',
          class: 'bg-slate-800',
        }
      }

      return {
        class: 'bg-primary',
      }
    },
    to() {
      if (this.prize.campaign_code) {
        return {
          name: 'campaign',
          params: { campaignCode: this.prize.campaign_code },
        }
      }

      if (
        Interval.fromDateTimes(
          this.prize.close_at,
          DateTime.now().endOf('day')
        ).contains(DateTime.now())
      ) {
        return { name: 'live-draw', params: { prizeCode: this.prize.code } }
      }

      return {
        name: 'prize',
        params: { prizeCode: this.prize.code },
      }
    },
  },
  async mounted() {
    this.countdown.start(this.prize.drawn_at)
  },
  beforeUnmount() {
    this.countdown.end()
  },
})
</script>

<template>
  <RouterLink :to="to" class="flex flex-col">
    <div>
      <img
        :src="prize.card_image.src"
        :alt="prize.card_image.alt"
        class="rounded-t-lg object-cover"
      />
    </div>

    <time
      v-if="countdown.showAsDate"
      :datetime="prize.drawn_at.toString()"
      class="flex justify-center bg-indigo-400 text-center font-semibold text-white"
    >
      {{ $d(prize.drawn_at.toJSDate(), 'long') }}
    </time>

    <time
      :datetime="prize.drawn_at.toString()"
      v-else
      class="flex justify-center gap-1 bg-indigo-400 text-center text-white"
    >
      <div class="mr-1 font-semibold">Ends in:</div>
      <div
        v-if="Number(countdown.remainingDateTimeFormatted.days) > 0"
        class="font-semibold"
      >
        {{ countdown.remainingDateTimeFormatted.days }}d
      </div>

      <div class="font-semibold">
        {{ countdown.remainingDateTimeFormatted.hours }}h
      </div>

      <div class="font-semibold">
        {{ countdown.remainingDateTimeFormatted.minutes }}m
      </div>

      <div class="font-semibold">
        {{ countdown.remainingDateTimeFormatted.seconds }}s
      </div>
    </time>

    <div
      class="flex w-full flex-col rounded-b-lg border border-t-0 border-slate-300 bg-pure-white p-5"
    >
      <div class="whitespace-nowrap font-bold">
        {{ prize.title }}
      </div>
      <p class="line-clamp-2" :title="details.description">
        {{ details.description }}
      </p>

      <AppButton
        v-if="$userStore.isLoggedOut"
        :route="to"
        :title="`Enter the ${prize.title} for free`"
        class="button-primary mt-4"
      >
        Enter for free
      </AppButton>
    </div>
  </RouterLink>
</template>
