<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="16"
      cy="17.3335"
      r="9.33333"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M7.95297 5.51516C7.04867 5.75747 6.22409 6.23354 5.5621 6.89553C4.90011 7.55752 4.42403 8.3821 4.18173 9.2864"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M24.047 5.51516C24.9513 5.75747 25.7759 6.23354 26.4379 6.89553C27.0999 7.55752 27.576 8.3821 27.8183 9.2864"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M16 12.0001V17.0835C16 17.2215 16.1119 17.3335 16.25 17.3335H20"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
