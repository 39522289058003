<script lang="ts">
import type { TIcon } from '@/types'
import { Icons } from '@/utils/helper-objects'
import { type PropType, defineComponent } from 'vue'

/**
 * A generic button.
 */
export default defineComponent({
  inheritAttrs: false,
  props: {
    /**
     * When true will switch out the button content for a spinner icon.
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * If set, the button will become an anchor tag, otherwise a button.
     *
     * TODO Type with VueRouter route names
     */
    route: {
      type: Object as PropType<{
        name: String
        params: String
      }>,
    },
    /**
     * If set, the button will become an anchor tag, otherwise a button.
     */
    href: {
      type: String,
    },
    /**
     * On hover text.
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * An icon to display next to the button content.
     */
    icon: {
      type: String as PropType<TIcon> | null,
      default: Icons.ArrowRight,
    },
    /**
     * Sets the size of the button icon.
     */
    iconSize: {
      type: String,
      default: 'sm',
    },
    tooltip: {
      type: Object,
      required: false,
    },
  },
  computed: {
    disabled(): boolean {
      return this.$attrs.disabled === 'true'
    },
  },
  methods: {
    disabledNoFollow(event: MouseEvent) {
      if (this.disabled) {
        event.preventDefault()
      }
    },
  },
})
</script>

<template>
  <a v-if="href" :href v-bind="$attrs" class="button" v-tooltip="tooltip">
    <div v-if="isLoading" class="spinner" />

    <template v-if="!isLoading">
      <!-- @slot Content of the button. -->
      <slot>
        <!-- @slot Content to display if a user is logged in. -->
        <slot v-if="$userStore.isLoggedIn" name="loggedIn" />

        <!-- @slot Content to display if a user is logged out. -->
        <slot v-if="$userStore.isLoggedOut" name="loggedOut" />
      </slot>
    </template>
  </a>

  <!-- @vue-skip  -->
  <RouterLink
    v-if="route"
    :to="route"
    :title
    v-bind="$attrs"
    class="button"
    role="button"
    tabindex="0"
    @click.capture="disabledNoFollow"
    v-tooltip="tooltip"
  >
    <div v-if="isLoading" class="spinner" />

    <template v-if="!isLoading">
      <!-- @slot Content of the button. -->
      <slot>
        <!-- @slot Content to display if a user is logged in. -->
        <slot v-if="$userStore.isLoggedIn" name="loggedIn" />

        <!-- @slot Content to display if a user is logged out. -->
        <slot v-if="$userStore.isLoggedOut" name="loggedOut" />
      </slot>
    </template>

    <template v-if="!isLoading">
      <AppIcon v-if="icon" class="hidden sm:flex" :icon :size="iconSize" />
    </template>
  </RouterLink>

  <button
    v-if="!route && !href"
    :title
    :type="($attrs.type as any) ?? 'button'"
    class="button"
    v-bind="$attrs"
    tabindex="0"
    v-tooltip="tooltip"
  >
    <div v-if="isLoading" class="spinner" />

    <!-- use v-show so button keeps width and absolute spinner -->

    <template v-if="!isLoading">
      <!-- @slot Content of the button. -->
      <slot>
        <!-- @slot Text to display if a user is logged in. -->
        <slot v-if="$userStore.isLoggedIn" name="loggedIn" />

        <!-- @slot Text to display if a user is logged out. -->
        <slot v-if="$userStore.isLoggedOut" name="loggedOut" />
      </slot>
    </template>

    <template v-if="!isLoading">
      <AppIcon v-if="icon" class="hidden sm:flex" :icon :size="iconSize" />
    </template>
  </button>
</template>
