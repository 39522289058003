import { usePageProgressStore } from '@/stores/page-progress'

export function startPageProgress() {
  const pageProgress = usePageProgressStore()

  pageProgress.isLoading = true
}

export function endPageProgress() {
  const pageProgress = usePageProgressStore()

  pageProgress.isLoading = false
}
