import { capitalCase } from 'change-case'

/**
 * Possible list of theme colours.
 */
export const Themes = Object.freeze({
  Transparent: 'transparent',
  Light: 'light',
  Dark: 'dark',
})

/**
 * Possible list of membership periods.
 */
export const MembershipPeriods = Object.freeze({
  Monthly: 'monthly',
  Yearly: 'yearly',
})

/**
 * Possible list of entry types.
 */
export const EntryTypes = Object.freeze({
  Online: 'online',
  Postal: 'postal',
})

/**
 * Possible list of genders.
 */
export const Genders = Object.freeze({
  Male: 'male',
  Female: 'female',
})

/**
 * Possible countries GLP works in.
 */
export const Countries = Object.freeze({
  UnitedKingdom: 'GB',
  Ireland: 'IE',
  IsleOfMan: 'IM',
  Jersey: 'JE',
})

/**
 * A flipped object of `Countries` with keys being country codes.
 */
export const CountryOptions = Object.freeze(
  Object.fromEntries(
    Object.entries(Countries).map(([country, code]) => [
      code,
      capitalCase(country),
    ])
  )
)

/**
 * Possible prize categories.
 */
export const PrizeCategories = Object.freeze({
  AllPrizes: 'All prizes',
  Cash: 'Cash',
  DreamCars: 'Dream cars',
  Experiences: 'Experiences',
  Featured: 'Featured',
  GetAways: 'Get aways',
  InstantWins: 'Instant wins',
  LuxuryItem: 'Luxury Item',
  Technology: 'Technology',
})

/**
 * Possible benefit categories.
 */
export const BenefitsCategories = Object.freeze({
  Discounts: 'Discounts',
  FoodAndDrink: 'Food & Drink',
  Entertainment: 'Entertainment',
})

/**
 * Possible FAQ categories.
 */
export const FaqCategories = Object.freeze({
  HowItWorks: 'how-it-works',
  WhoAreWe: 'who-are-we',
  WinnersAndPrizes: 'winners-and-prizes',
  EntriesAndTickets: 'entries-and-tickets',
})

/**
 * Possible FAQ routes slugs '/faq/:slug'.
 */
export const FaqSlugs = Object.freeze({
  GettingStarted: 'getting-started',
  PrizeDraws: 'prize-draws',
  MembershipBenefits: 'membership-benefits',
  PaymentBilling: 'payment-billing',
  UsingYourMembership: 'using-your-membership',
  PrizeDrawsWinners: 'prize-draws-winners',
  TroubleshootingSupport: 'troubleshooting-support',
})

/**
 * Possible icons.
 */
export const Icons = Object.freeze({
  AccordionArrow: 'AccordionArrow',
  ArrowDown: 'ArrowDown',
  ArrowDownThin: 'IconArrowDownThin',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  Calendar: 'Calendar',
  Car: 'DreamCars',
  Cash: 'Cash',
  Check: 'Check',
  Clock: 'Clock',
  Diamond: 'Diamond',
  Diversity: 'Diversity',
  Edit: 'Edit',
  Envelope: 'Envelope',
  Error: 'Error',
  Experiences: 'Experiences',
  GetAways: 'GetAways',
  HandHeart: 'HandHeart',
  Home: 'Home',
  Information: 'Information',
  Lightening: 'Lightening',
  LuxuryItem: 'Luxury Item',
  Member: 'Member',
  MembersZone: 'MembersZone',
  MoneyBag: 'MoneyBag',
  MoneyBox: 'MoneyBox',
  Play: 'Play',
  Present: 'Present',
  Prize: 'Prize',
  PrizeSack: 'PrizeSack',
  Question: 'Question',
  Reload: 'Reload',
  Setting: 'Setting',
  Share: 'Share',
  Star: 'Star',
  StarTick: 'StarTick',
  Technology: 'Technology',
  Telephone: 'Telephone',
  Ticket: 'Ticket',
  Timer: 'Timer',
  Times: 'Times',
  Trophy: 'Trophy',
  TV: 'TV',
})

/**
 * Possible plan tiers (values are numbers for plan comparison).
 *
 * Note: Although some tier values are the same, the order is important for comparison.
 */
export const PlanTiers = Object.freeze({
  postal: 0,
  lite: 1,
  standard: 2,
  'standard-plus': 2,
  unlimited: 2,
  platinum: 3,
  gold: 3,
  vip: 4,
})

/**
 * Possible benefit codes.
 */
export const BenefitCodes = Object.freeze({
  Blacksheep: 'blacksheep',
  BurgerKing: 'burger-king',
  CaffeNero: 'caffe-nero',
  Chiquito: 'chiquito',
  Costa: 'costa',
  Deliveroo: 'deliveroo',
  Dominos: 'dominos',
  FrankieBennys: 'frankie-bennys',
  GourmetBurgerKitchen: 'gourmet-burger-kitchen',
  Greggs: 'greggs',
  KrispyKreme: 'krispy-kreme',
  MarksAndSpencer: 'mands',
  PizzaExpress: 'pizza-express',
  PizzaHut: 'pizza-hut',
  Rakuten: 'rakuten',
  Starbucks: 'starbucks',
  Tastecard: 'tastecard',
  Tesco: 'tesco',
  Vue: 'vue',
  Zizzi: 'zizzi',
})

/**
 * Possible Instant Win prize types.
 */
export const InstantWinPrizeType = Object.freeze({
  Cash: 'cash',
  Entry: 'entry',
  Credit: 'credit',
  Item: 'item',
  Crypto: 'crypto',
})

/**
 * Possible provider names.
 */
export const PaymentProviderName = Object.freeze({
  Chargebee: 'chargebee',
  Solidgate: 'solidgate',
})

/**
 * Possible payment providers.
 */
export const PaymentProviders = Object.freeze({
  Chargebee: 'chargebee',
  Solidgate: 'solidgate',
})

/**
 * Possible payment types.
 */
export const PaymentType = Object.freeze({
  Subscription: 'subscription',
  InstantWin: 'instant_win',
  PaymentUpdate: 'payment_update',
})
